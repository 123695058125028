// ** MUI Imports
import React, { useState, useEffect, FormEvent, ChangeEvent } from "react";
import Card from '@mui/material/Card'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import CardContent from '@mui/material/CardContent'
import { styled } from '@mui/material/styles'
import Api from '../../../service/apiBase'
import useUser from "../../../useUser";
// Styled component for the triangle shaped background image
const TriangleImg = styled('img')({
  right: 0,
  bottom: 0,
  height: 170,
  position: 'absolute'
})

// Styled component for the trophy image
const TrophyImg = styled('img')({
  right: 0,
  bottom: 12,
  height: 100,
  position: 'absolute'
})

const TrophyEncaminhador = () => {
  // ** Hook

  const [formData, setFormData] = useState([]);
  const [valor, setValor] = useState([]);
  const [valorTotal, setValorTotal] = useState([]);
  const [loading, setLoading] = useState(true);
  const { user } = useUser();

  useEffect(() => {
    Api.getAllSaldoEncaminhador(user.id)
      .then(data => {
        setValor(data?.valor_disponivel.toFixed(2).replace('.', ','))
        setValorTotal(data?.valor_total.toFixed(2).replace('.', ','))
        setLoading(false);
      })
      .catch(error => {
        console.error('Erro ao buscar:', error);
      });
  }, []);

  return (
    <Card sx={{ position: 'relative' }}>
      <CardContent>
        <Typography variant='h6'>Saldo Disponível</Typography>
        <Typography variant='body2' sx={{ letterSpacing: '0.25px' }}>
          Bem-vindo! O saldo atual disponível é de:
        </Typography>
        
        <Typography variant='h5' sx={{ my: 2, color: 'primary.main' }}>
         R$ {valor} 
        </Typography> 

        <Typography variant='body2' sx={{ letterSpacing: '0.25px' }}>
          Valor total do crédito:
        </Typography>
        
        <Typography variant='h6' sx={{ my: 2, color: '#ffac37' }}>
         R$ {valorTotal}
        </Typography> 

        <TriangleImg alt='triangle background' src={`/images/misc/triangle-light.png`} />
        <TrophyImg alt='trophy' src='/images/pages/coins-amico.svg' />
      </CardContent>
    </Card>
  )
}

export default TrophyEncaminhador
