import React, { useState, ChangeEvent, FormEvent } from "react";
import axios from 'axios';
import { UserData } from "../../../model/login";
import Form from 'react-bootstrap/Form';
import Button from '@mui/material/Button';
import auth from "../../../service/authenticateUser";
import PropTypes from "prop-types";
import './Login.css';
import { User } from "../../../model/useUser";
import { Credentials } from "../../../model/auth";
import InputMask from 'react-input-mask';

import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import InputLabel from '@mui/material/InputLabel'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import EyeOffOutline from 'mdi-material-ui/EyeOffOutline'
import EyeOutline from 'mdi-material-ui/EyeOutline'
import CardContent from '@mui/material/CardContent'
import FormControl from '@mui/material/FormControl'
import OutlinedInput from '@mui/material/OutlinedInput'
import { styled } from '@mui/material/styles'
import MuiCard from '@mui/material/Card'
import InputAdornment from '@mui/material/InputAdornment'
import MuiFormControlLabel from '@mui/material/FormControlLabel'
import TextField from '@mui/material/TextField';
import { forwardRef } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import FooterIllustrationsV1 from "../../../components/FooterIllustration";

const BlankLayoutWrapper = styled(Box)(({ theme }) => ({
    height: '100vh',
  
    // For V1 Blank layout pages
    '& .content-center': {
      display: 'flex',
      minHeight: '100vh',
      alignItems: 'center',
      justifyContent: 'center',
      padding: theme.spacing(5)
    },
  
    // For V2 Blank layout pages
    '& .content-right': {
      display: 'flex',
      minHeight: '100vh',
      overflowX: 'hidden',
      position: 'relative'
    }
}))

const FormControlLabel = styled(MuiFormControlLabel)(({ theme }) => ({
    '& .MuiFormControlLabel-label': {
      fontSize: '0.875rem',
      color: theme.palette.text.secondary
    }
  }))
  
async function loginUser(cpf, password) {
    // Remove todos os caracteres que não sejam dígitos ou pontos
    const cleanedCpf = cpf.replace(/[^\d]/g, '');
  
    const credentials = {
      cpf: cleanedCpf,
      password,
    };
  
    return await auth.authenticateUser(credentials);
}

const Card = styled(MuiCard)(({ theme }) => ({
    [theme.breakpoints.up('sm')]: { width: '28rem', zIndex: 20 }
}))

const MaskedInput = forwardRef((props, ref) => {
    const { mask, id, label, onChange, ...other } = props;
    return (
        <InputMask mask={mask} onChange={(e) => onChange(e.target.value)} {...other}>
            {(inputProps) => (
                <TextField
                    {...inputProps}
                    variant="outlined"
                    fullWidth
                    inputRef={ref}
                    id={id}
                    label={label}
                />
            )}
        </InputMask>
    );
});

const LinkStyled = styled('a')(({ theme }) => ({
    fontSize: '0.875rem',
    textDecoration: 'none',
    color: theme.palette.primary.main
}))
  

function Login ({setToken}) {

    const [loginData, setLoginData] = useState({
        cpf: '',
        password: '',
        showPassword: false
    });

    const [loading, setLoading] = useState(false)
    const [rememberMe, setRememberMe] = useState(() => {
        const rememberMeValue = localStorage.getItem('rememberMe');
        return rememberMeValue ? JSON.parse(rememberMeValue) : false;
    });

    const navigate = useNavigate();

    const [errorMessage, setErrorMessage] = useState('');

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setLoginData({ ...loginData, [name]: value });
    };

    const handleChange = (prop) => (event) => {
        setLoginData({ ...loginData, [prop]: event.target.value })
    }

    const handleSubmit = async () => {

        try {
            setLoading(true);
            const data = await loginUser(loginData.cpf, loginData.password);
            if(data.error) {
                toast.warning("Credenciais inválidas. Por favor, tente novamente.");
                setLoading(false);
                return
            }
            setToken(data);
            navigate('/Dashboard');
            document.location.reload();
            setLoading(false);
        } catch (error) {
            console.error('Erro ao conectar à API:', error);
            toast.error("Erro ao realizar login. Tente novamente mais tarde.");
            setLoading(false);
        } 
    }

    const handleRememberMeChange= (event) => {
        const { checked } = event.target;
        setRememberMe(checked);
        localStorage.setItem('rememberMe', JSON.stringify(checked));
    };

    const handleClickShowPassword = () => {
        setLoginData({ ...loginData, showPassword: !loginData.showPassword })
    }
    const handleMouseDownPassword = (event) => {
        event.preventDefault()
    }

    return (

        <BlankLayoutWrapper className='layout-wrapper'>
            <Box className='app-content' sx={{ minHeight: '100vh', overflowX: 'hidden', position: 'relative', backgroundColor: '#f4f5fb' }}>
                <Box className='content-center'>
                    <Card sx={{ zIndex: 1 }}>
                        <CardContent sx={{ padding: theme => `3rem 2.25rem 1.75rem !important` }}>
                            <Box sx={{ mb: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Typography
                                    variant="h6"
                                    noWrap
                                    component="div"
                                    sx={{
                                      display: { xs: 'flex', sm: 'flex', md: 'flex', lg: 'flex', xl: 'flex' },
                                    }}
                                >
                                    <img src='/images/pages/bsltechLogoCima.png' alt="Logo" style={{ width: "150px" }} />
                                </Typography>
                            </Box>
                            <Box sx={{ mb: 6 }}>
                                <Typography variant='h5' sx={{ fontWeight: 600, marginBottom: 1.5 }}>
                                Bem-vindo! 👋🏻
                                </Typography>
                                <Typography variant='body2'>Por favor, faça login em sua conta para acessar seu perfil.</Typography>
                            </Box>
                            <form noValidate autoComplete='off' onSubmit={e => e.preventDefault()}>
                                
                                <FormControl fullWidth sx={{ marginBottom: 4 }}>
                                    <InputLabel htmlFor='cpf'>CPF</InputLabel>
                                    <InputMask mask="999.999.999-99" value={loginData.cpf} onChange={handleChange('cpf')}>
                                        {(inputProps) => (
                                        <OutlinedInput
                                            {...inputProps}
                                            label='CPF'
                                            id='cpf'
                                            type={'text'}
                                        />
                                        )}
                                    </InputMask>
                                </FormControl>
                                
                                <FormControl fullWidth>
                                    <InputLabel htmlFor='inputPassword5'>Senha</InputLabel>
                                    <OutlinedInput
                                        label='Password'
                                        value={loginData.password}
                                        id='inputPassword5'
                                        onChange={handleChange('password')}
                                        type={loginData.showPassword ? 'text' : 'password'}
                                        endAdornment={
                                        <InputAdornment position='end'>
                                            <IconButton
                                            edge='end'
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            aria-label='toggle password visibility'
                                            >
                                            {loginData.showPassword ? <EyeOutline /> : <EyeOffOutline />}
                                            </IconButton>
                                        </InputAdornment>
                                        }
                                        onKeyPress={(e) => {
                                            if (e.key === 'Enter') {
                                                handleSubmit();
                                            }
                                        }}
                                    />
                                </FormControl>
                                <Box
                                    sx={{ mb: 2, display: 'flex', alignItems: 'center', flexWrap: 'wrap', justifyContent: 'space-between' }}
                                    >
                                    <FormControlLabel sx={{mb: 0}} control={<Checkbox checked={rememberMe} onChange={handleRememberMeChange}/>} label='Lembrar de mim' />
                                    <Link to={'/reset'} className='style-text-color'><LinkStyled>Esqueceu sua senha?</LinkStyled></Link>
                                </Box>
                                    <Button
                                        fullWidth
                                        size='large'
                                        variant='contained'
                                        sx={{ marginBottom: 4 }}
                                        onClick={() => handleSubmit()}
                                        disabled={loading}
                                    >
                                    {loading ? 'Carregando...' : 'Entrar'}
                                    </Button>
                            </form>
                        </CardContent>
                    </Card>
                    <FooterIllustrationsV1 />
                </Box>
            </Box>
        </BlankLayoutWrapper>
        
    );
}

Login.propTypes = {
    setToken: PropTypes.func.isRequired
};

export default Login;



