import React, { useState, useEffect, FormEvent, ChangeEvent } from "react";
import { Card, CardContent, Typography, CardActions, Tooltip, Button, styled } from "@mui/material";
import { toast } from 'react-toastify';
import { Link } from "react-router-dom";

import { useParams } from "react-router-dom";
import FormCreatedUnit from "../../../components/forms/units/form-created-unit";
import FormUpdateUnit from "../../../components/forms/units/form-update-unit";
import WarningComponent from "../../../components/warnig/warnig"
import LoadingComponent from "../../../components/loadingCircular/loadingComponent";
import Api from "../../../service/apiBase";
import { Container } from "react-bootstrap";

import "./Scheduling.css";


interface Partner {
  id: number;
  nome: string;
  endereco: string;
  telefone: string;
  is_ativo: string;
}

interface Unit {
  endereco: string,
  id: number,
  id_parceiro?: string,
  is_ativo?: string,
  nome: string,
  telefone: string
  info_parceiro: Partner
}

const TriangleImg = styled('img')({
  right: 0,
  bottom: 0,
  height: 170,
  position: 'absolute'
})


function Scheduling() {
  // const [partners, setPartners] = useState<Partner[]>([]);
  const [unitsData, setUnits] = useState<Unit[]>([]);
  const [loading, setLoading] = useState(true);
  // const [loadingButton, setLoadingButton] = useState(false);
  const [selectedValues, setSelectedValues] = useState<Unit | null>(null);
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    id_parceiro: ''
  });
  const { id } = useParams(); 
  const [reload, setReload] = useState(false);

  const handleReload = () => {
    setReload(!reload);
  };

  const handleUpdate = (values: Unit) => {
    setSelectedValues(values);
    setUpdateModalOpen(true);
  };

  // useEffect(() => {
  //   Api.getAllPartners()
  //     .then(data => {
  //       setPartners(data);
  //     })
  //     .catch(error => {
  //       console.error('Erro ao buscar parceiros:', error);
  //   });
  //   if(id === undefined) {
  //     setLoading(true);
  //     Api.getAllUnits()
  //     .then(data => {
  //       setUnits(data);
  //       setLoading(false);
  //     })
  //     .catch(error => {
  //       console.error('Erro ao buscar parceiros:', error);
  //     });
  //   }
  // }, []);

  useEffect(() => {
    if (id !== undefined) {
      Api.getUnitByParceiro(id)
      .then(data => {
        if(data.length === undefined ) {
          setUnits([data])
        }else {
          setUnits(data)
        }
        setLoading(false);
      })
      .catch(error => {
        console.error('Erro ao buscar parceiros:', error);
      });
    }
  }, [id, reload]);


  const handleActivateDeactivate = (data: Unit) => {
    const newStatus = data.is_ativo === '1' ? '0' : '1';
    Api.updateUnitActivateDesactivate(data.id)
      .then((response) => {
        if (response.message === 'Unidade Liberado/Bloqueado com Sucesso') {
          toast.success(`Unidade ${newStatus === '0' ? 'ativado' : 'desativado'} com sucesso`);
          setUnits(prev => prev.map(p => p.id === data.id ? { ...p, is_ativo: newStatus } : p));
        } else {
          toast.error(`Não foi possível, tente novamente mais tarde!`);
        }
      })
      .catch((e) => {
        toast.error(`Erro: ${e.request.status}, Não foi possível, tente novamente mais tarde!`);
      });
  };

  return (

    <>
      <Container className="p-0 mb-6">
          {/* <Skeleton variant="rectangular" height="10%" /> */}
          <div className="row container-units mb-4">
            <div className="col-12">
              <span>Home</span>
            </div>
            <div className="col-12">
              <div className="row">
                <div className="col-md-12">
                  <h1>Unidades</h1>
                </div>
                {/* <div className="col-md-6">
                  <Form onSubmit={handleSubmit} className="form-search">
                    <Form.Group className="d-flex flex-column">
                      <Form.Select 
                      id="selectParceiro" 
                      name="id_parceiro" 
                      value={formData.id_parceiro} 
                      onChange={handleSelectChange}
                      className="form-control-value form-control-value-select-search"
                      >
                        <option value="">Selecione um parceiro</option>
                        {partners.map(partner => (
                          <option key={partner.id} value={partner.id}>{partner.nome}</option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                    <Button type="submit" className="button-search-form ml-3" disabled={loadingButton} disableElevation>
                      {loadingButton ? 'Buscando...' : 'Buscar'}
                    </Button>
                  </Form>
                </div> */}
              </div>
            </div>
          </div>
                    
          {
            loading ?
              <LoadingComponent/>
            :
              unitsData.length > 0 ? (
                unitsData.map(data => (
                  <Card className="partner-card mb-3" key={data.id}>
                    <CardContent>
                      <Typography variant="h6" component="h2" className="partner-name">
                        {data.nome} - {data?.info_parceiro?.nome}
                      </Typography>
                      <Typography variant="body2" color="textSecondary" component="p">
                        {data.endereco} - {data.telefone}
                      </Typography>
    
                      <Tooltip title={data.is_ativo !== '0' ? 'Ativo' : 'Inativo'} placement="top">
                        <div className={`partner-status-${data.is_ativo !== '0' ? 'active' : 'inactive'}`}></div>
                      </Tooltip>

                      <TriangleImg alt='triangle background' src={`/images/misc/triangle-light.png`} />

                    </CardContent>
                    <CardActions className="card-cartion-buttons">
                      <Button
                        size="small"
                        color="primary"
                        className="button-card-action"
                        onClick={() => handleActivateDeactivate(data)}
                      >
                        {data.is_ativo !== '0' ? 'Desativar' : 'Ativar'}
                      </Button>
                      {
                        data.is_ativo !== '1' ? null :
                        <>
                          <Button
                            size="small"
                            className="button-card-action-update"
                            onClick={() => handleUpdate(data)}
                          >
                            Atualizar
                          </Button>

                          <Button
                            size="small"
                            className="button-card-action-update"
                            component={Link}
                            to={`/Usuarios/${data.id}`}
                          >
                            Usuários
                          </Button>

                          <Button
                            size="small"
                            className="button-card-action-update"
                            component={Link}
                            to={`/Servico/${data.id}`}
                          >
                            Serviços
                          </Button>
                        </>
                        
                      }
                    </CardActions>
                  </Card>
                ))
              )
            : 
              (
                <>
                  <WarningComponent title="Nenhum cadastro"/>
                </>
              )
          }
          
      </Container>
      <FormCreatedUnit 
        reloadComponent={handleReload}
      />
      <FormUpdateUnit
        reloadComponent={handleReload}
        toUpdate={selectedValues}
        id={selectedValues?.id_parceiro}
        isOpen={updateModalOpen}
        onClose={() => setUpdateModalOpen(false)}
      />
    </>
    
  );
}

export default Scheduling;
