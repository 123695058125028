import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@mui/material';
import { PaymentElement, PaymentRequestButtonElement, useStripe, useElements, CardElement, CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import Api from '../../service/apiBase';
import CloseIcon from '@mui/icons-material/Close';
import CancelIcon from '@mui/icons-material/Cancel';
import './stripe-modal.css'
import LoadingComponent from '../loadingCircular/loadingComponent';

const stripePromise = loadStripe('pk_test_51OVErXK4heB4OhYCIFsSykCsrlNfrN0JTzYKldZrHADBO9FZvudc6NuQ4p4PvrbhzbHePs1ZcSX4EaYGUnHIsg7t00fUQRKJhy');

const CheckoutForm = (clientSecret: any) => {
    const stripe = useStripe();
    const elements = useElements();
    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        
        if (!stripe || !elements) {
            console.error('Erro no pagamento');
            return;
          }
        
          const shippingDetails = {
            name: 'Nome do Destinatário',
            email: 'matheusasn2@gmail.com',
            phone: '+5511999999999',
            address: {
                city: 'Cidade',
                country: 'País',
                line1: 'Endereço Linha 1',
                line2: 'Endereço Linha 2',
                postal_code: 'CEP',
                state: 'Estado',
            },
            
        };
        
        const result = await stripe.confirmPayment({
          elements,
          confirmParams: {
            return_url: 'https://exames.bsltech.com.br/Encaminhamento/Enviar',
            payment_method_data: {
              billing_details: shippingDetails,
            },
          },
        });
        
        
    };

  return (
    <form onSubmit={handleSubmit}>
        {/* <PaymentRequestButtonElement /> */}
        <PaymentElement />
        <Button
            size="large"
            className="button-card-action-update mt-4 w-100"
            type="submit"
            disabled={!stripe}
        >
            Realizar Pagamento
        </Button>
    </form>
  );
};

interface Props {
  reloadComponent: () => void;
  id?: string
}

const StripeModal: React.FC<Props> = ({ reloadComponent, id }) =>  {
  const [open, setOpen] = useState(false);
  const [clientSecret, setClientSecret] = useState('');
  const [loading, setLoading] = useState(true);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if(open){
      Api.stripeClientSecret(id)
      .then(data => {
          
          setClientSecret(data.clientSecret)
          setTimeout(() => {
            setLoading(false);
          }, 600);
      })
      .catch(error => {
        console.error('Erro ao buscar:', error);
      });
    }
  }, [open]);


  const options = {
    clientSecret: clientSecret,
  };

  return (
    <>
      {/* <button onClick={handleOpen}>Open Modal</button> */}
        <Button
            size="small"
            className="button-card-action-update"
            onClick={() => handleOpen()}
            >
            Pagar
        </Button>
      <Dialog open={open} onClose={handleClose} className='modal-stripe-container'>
        {/* <DialogTitle></DialogTitle> */}
        <DialogActions>
          <CancelIcon onClick={handleClose} className='icon-close-stripe'></CancelIcon>
        </DialogActions>
        <DialogContent className='pt-0'>
            {!loading
            ? 
              <Elements stripe={stripePromise} options={options}>
                  <CheckoutForm clientSecret={clientSecret}/>
              </Elements>
            : 
                <LoadingComponent/>
            }
          
        </DialogContent>
        
      </Dialog>
    </>
  );
};

export default StripeModal;
