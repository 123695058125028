import React, { useState, useEffect, FormEvent, ChangeEvent } from "react";
import LoadingComponent from "../../../components/loadingCircular/loadingComponent";
import { Card, CardContent, Typography, CardActions, Tooltip, Button, styled } from "@mui/material";
import { toast } from 'react-toastify';
import { Container } from "react-bootstrap";
import FormUpdate from "../../../components/forms/UsersProviders/form-update";
import Api from "../../../service/apiBase";
import WarningComponent from "../../../components/warnig/warnig";
import { Link } from "react-router-dom";

const TriangleImg = styled('img')({
    right: 0,
    bottom: 0,
    height: 170,
    position: 'absolute'
  })
  

function ServiceProviderFoods() {
    const [reload, setReload] = useState(false);
    const [loading, setLoading] = useState(true);
    const [arrayData, setArrayData] = useState([]);
    const [updateModalOpen, setUpdateModalOpen] = useState(false);
    const [info, setInfo] = useState();

    const handleReload = () => {
        setReload(!reload);
    };

    useEffect(() => {
        Api.prestadorPorTipo(4)
        .then(data => {
          if(data.length === undefined ) {
            setArrayData([data])
          }else {
            setArrayData(data)
          }
          setLoading(false);
        })
        .catch(error => {
          console.error('Erro ao buscar:', error);
          setLoading(false);
        });
    }, [reload]);

    const formatarCPF = (str) => {
        // Remove todos os caracteres não numéricos
        const digitsOnly = str.replace(/\D/g, '');
    
        // Verifica se o CPF tem a quantidade correta de dígitos
        if (digitsOnly.length !== 11) {
            throw new Error('CPF deve conter 11 dígitos.');
        }
    
        // Formata o CPF no formato com pontos e traço
        return `${digitsOnly.slice(0, 3)}.${digitsOnly.slice(3, 6)}.${digitsOnly.slice(6, 9)}-${digitsOnly.slice(9)}`;
    }

    const handleActivateDeactivate = (data) => {
        const newStatus = data.is_liberado === "1" ? "0" : "1";
        // Api.updateClientActivateDesactivate(data.id)
        //   .then((response) => {
        //     if (response.message === 'Usuario Deletado com Sucesso') {
        //       toast.success(`${newStatus === "0" ? 'Liberado' : 'Bloqueado'} com sucesso`);
        //       setArrayData(prev => prev.map(p => p.id === data.id ? { ...p, is_liberado: newStatus } : p));
        //     } else {
        //       toast.error(`Não foi possível, tente novamente mais tarde!`);
        //     }
        //   })
        //   .catch((e) => {
        //     toast.error(`Erro: ${e.request.status}, Não foi possível, tente novamente mais tarde!`);
        //   });
    };

    const handleUpdate = (values) => {
        setInfo(values);
        
        setUpdateModalOpen(true);
    };

    return (
        <>
            <Container className="p-0 mb-6">
                <div className="row container-units mb-4">
                <div className="col-12">
                    <span>Home{' > '} Alimentício</span>
                </div>
                <div className="col-12">
                    <div className="row">
                    <div className="col-md-12">
                        <h1>Prestador de Serviço Alimentício</h1>
                    </div>
                    </div>
                </div>
                </div>

                {
                loading ?
                    <LoadingComponent/>
                :
                arrayData.length > 0 ? (
                    arrayData.map(data => (
                    <Card className="partner-card mb-3" key={data.id}>
                        <CardContent>
                        <Typography variant='h6'>{data.nome}</Typography>

                        <Typography variant="body2" color="textSecondary" component="p">
                            Email: {data.email} 
                        </Typography>

                        <Typography variant="body2" color="textSecondary" component="p">
                            CPF: {formatarCPF(data.cpf)}
                        </Typography>
        
                        {/* <Tooltip title={data.is_liberado === 1 ? 'Liberado' : 'Bloqueado'} placement="top">
                        <div className={`partner-status-${data.is_liberado === 1 ? 'active' : 'inactive'}`}></div>
                        </Tooltip> */}

                        <TriangleImg alt='triangle background' src={`/images/misc/triangle-light.png`} />

                        </CardContent>
                        <CardActions className="card-cartion-buttons">
                        {/* <Button
                            size="small"
                            color="primary"
                            className="button-card-action"
                            onClick={() => handleActivateDeactivate(data)}
                        >
                            {data.is_liberado === 1 ? 'Desativar' : 'Ativar'}
                        </Button> */}
                        {/* {
                            data.is_liberado === 0 ? null :
                            <>
                            <Button
                                size="small"
                                className="button-card-action-update"
                                onClick={() => handleUpdate(data)}
                            >
                                Atualizar
                            </Button>

                            <Button
                                size="small"
                                className="button-card-action-update"
                                component={Link}
                                to={`/Usuarios/${data.id}`}
                            >
                                Usuários
                            </Button>
                            </>
                            
                        } */}

                        <Button
                            size="small"
                            className="button-card-action-update"
                            component={Link}
                            to={`/Prestador/Servico/${data.id}`}
                          >
                            Serviços
                          </Button>
                        </CardActions>
                    </Card>
                    ))
                )
                : 
                (
                    <>
                    <WarningComponent title="Nenhuma prestador cadastrado."/>
                    </>
                )
                }
            </Container>
                
            <FormUpdate
                reloadComponent={handleReload}
                idUnidade={1}
                tipo={4}
                isOpen={updateModalOpen}
                data={info}
                onClose={() => setUpdateModalOpen(false)}
                title="Atualizar Prestador de Serviço Alimentício"
            ></FormUpdate>
        </>
        
    );
}   

export default ServiceProviderFoods;