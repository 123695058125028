import React, { useState, useEffect } from "react";
import { Card, CardContent, Typography, CardActions, Button, Grid, styled, useTheme, Dialog, DialogActions, DialogContent, DialogTitle, } from "@mui/material";
import { toast } from 'react-toastify';
import { Link, useParams } from "react-router-dom";
import WarningComponent from "../../../components/warnig/warnig"
import LoadingComponent from "../../../components/loadingCircular/loadingComponent";
import Api from "../../../service/apiBase";
import { Container } from "react-bootstrap";
import FormCreatedForwarding from "../../../components/forms/Forwarding/form-created";
import FormUpdateClient from "../../../components/forms/Client/form-update";
import useUser from "../../../useUser";
import PixComponent from "../../../components/pix/pix-component";
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import Tab from '@mui/material/Tab'
import useMediaQuery from '@mui/material/useMediaQuery'

const TriangleImg = styled('img')({
  right: 0,
  bottom: 0,
  height: 170,
  position: 'absolute'
})

const TrophyImg = styled('img')({
  right: 0,
  bottom: 12,
  height: 100,
  position: 'absolute'
})

function ForwardingAdminComponent() {
    const [arrayData, setArrayData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedValues, setSelectedValues] = useState(null);
    const [updateModalOpen, setUpdateModalOpen] = useState(false);
    const [openButton, setOpenButton] = useState(false);
    const [taxId, setTaxId] = useState('');
    const { id } = useParams(); 
    const [reload, setReload] = useState(false);
    const { user } = useUser();
    const [value, setValue] = useState('1')
    const theme = useTheme()
    const hidden = useMediaQuery(theme.breakpoints.down('md'))
    const [valor, setValor] = useState([]);

    const [open, setOpen] = useState(false);
    const [confirmFunction, setConfirmFunction] = useState(null);

    const handleOpen = (func) => {
      setConfirmFunction(() => func); // Define a função de confirmação
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
      setConfirmFunction(null); // Limpa a função de confirmação
    };

    const handleConfirm = () => {
      if (confirmFunction) {
        confirmFunction(); // Executa a função de confirmação
      }
      handleClose(); // Fecha o modal
    };

    const handleReload = () => {
      setReload(!reload);
    };
  
    useEffect(() => {
        if(id) {
          Api.getAllSendForwardingAdmin(id)
            .then(data => {
              if(data.length === undefined ) {
                setArrayData([data])
              }else {
                setArrayData(data)
              }
              setLoading(false);
            })
            .catch(error => {
              console.error('Erro ao buscar:', error);
            });
          Api.getAllSaldoEncaminhador(id)
          .then(data => {
            data?.valor_em_uso > 0 ? setOpenButton(true) : setOpenButton(false);
            setValor(data?.valor_em_uso.toFixed(2).replace('.', ','))
            setLoading(false);
          })
          .catch(error => {
            console.error('Erro ao buscar:', error);
          });
        }
    }, [reload]);

    const handleRemoveEncaminhamento = (data) => {
      setLoading(true);
      Api.deleteEncaminhamento(data.id)
        .then((response) => {
          if (response.message === 'Encaminhamento removido com sucesso') {
            toast.success(`Encaminhamento removido com sucesso`);
            handleReload();
            setLoading(false);

          } else {
            toast.error(`Não foi possível, tente novamente mais tarde!`);
            setLoading(false);
          }
        })
        .catch((e) => {
          toast.error(`Erro: ${e.request.status}, Não foi possível, tente novamente mais tarde!`);
          setLoading(false);
        });
    };

    const handleChange = (event, newValue) => {
      setValue(newValue)
    }

    const statusObjLabel = [
      { label: 'Encaminhamento Aberto' },
      { label: 'Aguardando Agendamento' },
      { label: 'Encaminhamento Agendado' },
      { label: 'Encaminhado Confirmado' },
      { label: 'Encaminhamento Finalizado' },
    ]

    // Componente para renderizar um card de encaminhamento
    const EncaminhamentoCard = ({ data, handleRemoveEncaminhamento }) => (
      <Card className="partner-card mb-3" key={data.id}>
        <CardContent>
          <Typography variant="h6" component="div" className="mb-2 d-flex justify-content-end align-items-center">
            Código: {data.id}
          </Typography>
          <Grid container spacing={2}>
            <Grid item sm={6} xs={12} className="d-flex justify-content-start align-items-start flex-column">
              <Typography variant="h6" component="div" className="mb-2">
                Detalhes do Encaminhamento:
              </Typography>
              <Typography variant="body1" color="text.secondary">
                <strong>Status:</strong> {statusObjLabel[data.status_encaminhamento-1].label}
              </Typography> 
              <Typography variant="body1" color="text.secondary">
                <strong>Unidade:</strong> {data?.infoUnidade_recebe?.nome}
              </Typography>
              <Typography variant="body1" color="text.secondary">
                <strong>Cliente:</strong> {data?.dados_cliente?.nome}
              </Typography>
            </Grid>
            <Grid item sm={6} xs={12} className="d-flex justify-content-start align-items-start flex-column">
              <Typography variant="h6" component="div" className="mb-2">
                Dados do Pagamento:
              </Typography>
              <Typography variant="body1" color="text.secondary">
                <strong>Pagamento:</strong> {data.tipo_pagamento}
              </Typography> 
              <Typography variant="body1" color="text.secondary">
                <strong>Status:</strong> {data?.status_pagamento}
              </Typography> 
              <Typography variant="body1" color="text.secondary">
                <strong>Valor:</strong> R$ {data?.valorTotal.toFixed(2)}
              </Typography>
            </Grid>
          </Grid>
          
          <TriangleImg alt='triangle background' src={`/images/misc/triangle-light.png`} />
        </CardContent>
        <CardActions className="card-cartion-buttons">
          <Button
            size="small"
            color="primary"
            className="button-card-action"
            onClick={() => handleOpen(() => handleRemoveEncaminhamento(data))}
          >
            Remover
          </Button>
          <Button
            size="small"
            className="button-card-action-update"
            component={Link}
            to={`/Encaminhamento/Receber/${data.id}`}
            // onClick={() => handleUpdate(data)}
          >
            Visualizar
          </Button>
        </CardActions>
      </Card>
    );

    // Componente para renderizar a lista de cards de encaminhamento
    const EncaminhamentoList = ({ loading, arrayData, tipoPagamento, handleRemoveEncaminhamento }) => {
      const filteredData = arrayData.filter(data => data.tipo_pagamento === tipoPagamento);

      return (
        <CardContent sx={{ textAlign: 'center' }}>
          {loading ? (
            <LoadingComponent />
          ) : filteredData.length > 0 ? (
            filteredData.map(data => <EncaminhamentoCard key={data.id} data={data} handleRemoveEncaminhamento={handleRemoveEncaminhamento} />)
          ) : (
            <WarningComponent title="Nenhum cadastro" />
          )}
        </CardContent>
      );
    };

    const handleRealizarPagamento = () => {
      console.log("caiu aqui!!")
      setLoading(true);
      Api.realizarPagamentoEncaminhador(id)
        .then((response) => {
          response.error ? toast.error(`Erro: ${response.error}`) : 
          toast.success(`Pagamento realizado com sucesso`);
          handleReload();
          setLoading(false);;
        })
        .catch((e) => {
          toast.error(`Erro: ${e.request.status}, Não foi possível, tente novamente mais tarde!`);
          setLoading(false);
        });
    };
    
    return (
      <>
        <Container className="p-0 mb-6">
          <div className="row container-units mb-4">
            <div className="col-12">
              <span>Home</span>
            </div>
            <div className="col-12">
              <div className="row">
                <div className="col-md-12">
                  <h1>Encaminhamentos</h1>
                </div>
              </div>
            </div>
          </div> 

          <TabContext value={value}>
            <TabList centered onChange={handleChange} aria-label='card navigation example'>
                <Tab value='1' label='A PAGAR CLIENTE' />
                <Tab value='2' label='A Pagar Parceiro' />
            </TabList>
            <CardContent sx={{ textAlign: 'start' }}>
              <TabPanel value='1' sx={{ p: 0 }}>
                <EncaminhamentoList
                  loading={loading}
                  arrayData={arrayData}
                  tipoPagamento="A PAGAR CLIENTE"
                  handleRemoveEncaminhamento={handleRemoveEncaminhamento}
                />
              </TabPanel>
              <TabPanel value='2' sx={{ p: 0 }}>
                {
                  !loading && 
                  <Card sx={{ position: 'relative', margin: '20px', marginBottom: '0px' }}>
                    <CardContent>
                      <Typography variant='h6'>Valor Pendente</Typography>
                      <Typography variant='body2' sx={{ letterSpacing: '0.25px' }}>
                        Valor Total do crédito pendente a ser pago
                      </Typography>
                      <Typography variant='h5' sx={{ my: 2, color: 'primary.main', marginBottom: '20px' }}>
                      R$ {valor}
                      </Typography>
                      {
                        !hidden ?
                        <>
                          <TriangleImg alt='triangle background' src={`/images/misc/triangle-light.png`} />
                          <TrophyImg alt='trophy' src='/images/pages/coins-amico.svg' /> 
                        </>
                        : null 
                      }
                      {
                        openButton &&
                        <Button 
                          fullWidth 
                          variant='contained'
                          sx={{ padding: theme => theme.spacing(1.75, 1.8), fontWeight: 600, width: '80%', '&:hover': {
                            color: '#ffffff',
                          } }}
                          onClick={ () => handleOpen(() => handleRealizarPagamento())}
                          >
                            Realizar Pagamento
                        </Button>
                      }
                    </CardContent>
                  </Card>
                }
                <EncaminhamentoList
                  loading={loading}
                  arrayData={arrayData}
                  tipoPagamento="A PAGAR PARCEIRO"
                  handleRemoveEncaminhamento={handleRemoveEncaminhamento}
                />
              </TabPanel>
            </CardContent>
          </TabContext>
                      
          <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Atenção!</DialogTitle>
            <DialogContent>
              <Typography variant="body1">Tem certeza de que deseja realizar esta ação?</Typography>
              <div className="d-flex justify-content-around align-items-center pt-3">
                <Button variant='contained' onClick={handleConfirm} autoFocus>Confirmar</Button>
                <Button variant="outlined" color="error" onClick={handleClose}>Cancelar</Button>
              </div>
            </DialogContent>
          </Dialog>  
        </Container>
        

        <FormCreatedForwarding 
          reloadComponent={handleReload}
          id={selectedValues?.id}
        />
        <FormUpdateClient
          reloadComponent={handleReload}
          toUpdate={selectedValues}
          id={selectedValues?.id}
          isOpen={updateModalOpen}
          onClose={() => setUpdateModalOpen(false)}
        />
      </>
    );
}

export default ForwardingAdminComponent;
