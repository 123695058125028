import React, { useState, useEffect, ChangeEvent, FormEvent } from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import AddIcon from '@mui/icons-material/Add';
import DialogContent from '@mui/material/DialogContent';
import Form from 'react-bootstrap/Form';
import InputMask from 'react-input-mask';
import Api from "../../../service/apiBase";
import { toast } from 'react-toastify';
import { Category } from "../../../model/partner";
import { useParams } from "react-router-dom";

// import '../forms.css'

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface FormCreatedUserProps {
  reloadComponent: () => void;
  toUpdate: any | null;
  isOpen: boolean;
  idServicoUnidade: number | undefined;
  onClose: () => void;
}

const FormUpdate: React.FC<FormCreatedUserProps> = ({ reloadComponent, toUpdate, isOpen, onClose, idServicoUnidade }) => {
  const [formData, setFormData] = useState({
    id_categoria: '',
    nome: '',
    valor: '',
    complexidade: ''
  });
  const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState<Category[]>([]);

  const { id } = useParams(); 

  useEffect(() => {
    Api.getAllCategory()
      .then(data => {
        setCategory(data);
      })
      .catch(error => {
        console.error('Erro ao buscar parceiros:', error);
    });
  }, []);

  useEffect(() => {
    if (toUpdate) {
      setFormData(toUpdate);
    }
  }, [toUpdate]);

  const handleInputChangeForm = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  
    if (!formData.nome || !formData.valor || !formData.complexidade || !formData.id_categoria) {
      toast.error("Por favor, preencha todos os campos obrigatórios.");
      return;
    }
  
    setLoading(true);
    
    Api.updateServiceUnidade(idServicoUnidade, {id_unidade: id, id_categoria: formData.id_categoria, nome: formData.nome, valor: formData.valor, complexidade: formData.complexidade})
      .then((res) => {
        if(res.error) {
          toast.error(res.error)
        }else {
          toast.success("Atualizado com sucesso");
          setFormData({
            id_categoria: '',
            nome: '',
            valor: '',
            complexidade: ''
          });
          onClose();
          reloadComponent();
        }
      })
      .catch((e) => {
        toast.error(`Erro: ${e.request.status}, Não foi possível, tente novamente mais tarde!`);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSelectChangeCategory = (e: ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    setFormData({
      ...formData,
      id_categoria: value
    });
  };

  const handleSelectChangeComplexidade = (e: ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    setFormData({
      ...formData,
      complexidade: value
    });
  };

  return (
    <React.Fragment>
      <Dialog
        fullScreen
        open={isOpen}
        onClose={onClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar className='bar-modal-dialog'>
            <IconButton
              edge="start"
              color="inherit"
              onClick={onClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1, color: '#ffffff' }} variant="h6" component="div">
              Atualizar Serviço
            </Typography>
          </Toolbar>
        </AppBar>

        <DialogContent className="container-dialog">
          <div className="containerForm">
            <Form onSubmit={handleSubmit}>

              <Form.Group className="mb-3">
                <Form.Label htmlFor="inputNome">Nome*</Form.Label>
                <Form.Control 
                  type="text" 
                  placeholder="Digite o nome" 
                  name="nome" 
                  id="inputNome"
                  value={formData.nome} 
                  onChange={handleInputChangeForm} 
                  className="form-control-value"
                />
              </Form.Group>

              <Form.Group className="mb-3 d-flex flex-column">
                <Form.Label htmlFor="selectParceiro">Categoria*</Form.Label>
                <Form.Select 
                  id="selectParceiro" 
                  name="id_parceiro" 
                  value={formData.id_categoria} 
                  onChange={handleSelectChangeCategory}
                  className="form-control-value form-control-value-select"
                >
                  <option value="">Selecione uma categoria</option>
                  {category.map(data => {
                    return data.is_ativo === "1" ? (
                      <option key={data.id} value={data.id}>{data.nome}</option>
                    ) : null;
                  })}
                </Form.Select>
              </Form.Group>

              <Form.Group className="mb-3 d-flex flex-column">
                <Form.Label htmlFor="selectParceiro">Complexidade*</Form.Label>
                <Form.Select 
                  id="selectParceiro" 
                  name="id_parceiro" 
                  value={formData.complexidade} 
                  onChange={handleSelectChangeComplexidade}
                  className="form-control-value form-control-value-select"
                >
                  <option value="">Selecione uma complexidade</option>
                  <option value="Baixa">Baixa</option>
                  <option value="Média">Média</option>
                  <option value="Alta">Alta</option>
                </Form.Select>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label htmlFor="inputValor">Valor*</Form.Label>
                <Form.Control 
                  type="number" 
                  placeholder="Digite um valor R$" 
                  name="valor" 
                  id="inputValor"
                  value={formData.valor} 
                  onChange={handleInputChangeForm} 
                  className="form-control-value"
                />
              </Form.Group>

              


              <Button type="submit" className="button-submit-form" disabled={loading} disableElevation>
                {loading ? 'Cadastrando...' : 'Cadastrar'}
              </Button>
            </Form>
          </div>
        </DialogContent>

      </Dialog>
    </React.Fragment>
  );
}

export default FormUpdate;
