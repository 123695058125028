import React, { useState, useEffect } from 'react';
import { Button, Divider } from '@mui/material';
import Api from '../../service/apiBase';
import QRCode from 'qrcode.react';
import LoadingComponent from '../loadingCircular/loadingComponent';
import { toast } from 'react-toastify';
import { Container } from 'react-bootstrap';
import './pix-component.css';
import axios from 'axios';


const PixCreatedComponent = ({ idEncaminhamento, taxId }) => {
    const [dataPix, setDataPix] = useState([]);
    const [loading, setLoading] = useState(true);
    const [copied, setCopied] = useState(false);
    const [dataLoaded, setDataLoaded] = useState(false);

    const handleCopy = () => {
        navigator.clipboard.writeText(dataPix[0].copiaECola);
        setCopied(true);
        toast.success("Chave PIX copiada para área de transferência");
        setTimeout(() => {
            setCopied(false);
        }, 2000);
    };

    useEffect(() => {
        if (taxId) {
            Api.getPixTxId(taxId)
                .then(data => {
                    if (data.length === undefined) {
                        setDataPix([data])
                    } else {
                        setDataPix(data)
                    }
                    setLoading(false);
                    setDataLoaded(true);
                })
                .catch(error => {
                    console.error('Erro ao buscar:', error);
                    setLoading(false); // Define o estado de loading como falso mesmo em caso de erro
                });
        }else {
            Api.gerarPixId(idEncaminhamento)
                .then(data => {
                    if (data.length === undefined) {
                        setDataPix([data])
                    } else {
                        setDataPix(data)
                    }
                    setLoading(false);
                    setDataLoaded(true);
                })
                .catch(error => {
                    console.error('Erro ao buscar:', error);
                    setLoading(false); // Define o estado de loading como falso mesmo em caso de erro
                });
        }
    }, [idEncaminhamento]);

    return (
        <>
            <div className='modal-stripe-container'>
                <Container className='pt-0'>
                    {loading ? (
                        <LoadingComponent />
                    ) : (
                        <div className='d-flex flex-column align-items-center'>
                            <div className='mb-3'>
                                {dataPix.map((data, index) => (
                                    <QRCode key={index} value={data.copiaECola} size={256} />
                                ))}
                            </div>
                            <div className='container-pix-info'>
                                {dataPix[0].copiaECola}
                            </div>
                            <Button
                                className='w-100 mt-3'
                                variant='contained'
                                disabled={copied}
                                onClick={handleCopy}
                            >Copiar Chave PIX</Button>

                            <Divider variant="middle"
                                sx={{
                                    m: 0,
                                    width: '100%',
                                    lineHeight: 'normal',
                                    textTransform: 'uppercase',
                                    '&:before, &:after': { top: 7, transform: 'none' },
                                    '& .MuiDivider-wrapper': { px: 2.5, fontSize: '0.75rem', letterSpacing: '0px' },
                                    marginBottom: '10px',
                                    marginTop: '20px',
                                    borderWidth: '1px',
                                }}
                            />
                        </div>
                    )}
                </Container>
            </div>
        </>
    );
};

export default PixCreatedComponent;
