import React, { useState, useEffect, FormEvent, ChangeEvent } from "react";
import { Card, CardContent, Typography, CardActions, Tooltip, Button, styled } from "@mui/material";
import { toast } from 'react-toastify';
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import WarningComponent from "../../../components/warnig/warnig"
import LoadingComponent from "../../../components/loadingCircular/loadingComponent";
import Api from "../../../service/apiBase";
import { Container } from "react-bootstrap";
import { Service } from "../../../model/partner";
import FormCreatedCategory from "../../../components/forms/Category/form-created";
import FormUpdateUser from "../../../components/forms/Users/form-update-user";
import FormUpdateCategory from "../../../components/forms/Category/form-update";

const TriangleImg = styled('img')({
  right: 0,
  bottom: 0,
  height: 170,
  position: 'absolute'
})

function CategoryComponent() {
    const [arrayData, setArrayData] = useState<Service[]>([]);
    const [loading, setLoading] = useState(true);
    const [selectedValues, setSelectedValues] = useState<Service | null>(null);
    const [updateModalOpen, setUpdateModalOpen] = useState(false);
    const { id } = useParams(); 
    const [reload, setReload] = useState(false);
  
    const handleReload = () => {
      setReload(!reload);
    };
  
    const handleUpdate = (values: Service) => {
        setSelectedValues(values);
        setUpdateModalOpen(true);
    };
  
    useEffect(() => {
        Api.getAllCategory()
        .then(data => {
            
          if(data.length === undefined ) {
            setArrayData([data])
          }else {
            setArrayData(data)
          }
          setLoading(false);
        })
        .catch(error => {
          console.error('Erro ao buscar:', error);
        });
    }, [reload]);
  
  
    const handleActivateDeactivate = (data: Service) => {
      const newStatus = data.is_ativo === '1' ? '0' : '1';
      Api.updateCategoryActivateDesactivate(data.id)
        .then((response) => {
          if (response.message === 'Categoria Liberado/Bloqueado com Sucesso') {
            toast.success(`${newStatus === '0' ? 'Liberado' : 'Bloqueado'} com sucesso`);
            setArrayData(prev => prev.map(p => p.id === data.id ? { ...p, is_ativo: newStatus } : p));
          } else {
            toast.error(`Não foi possível, tente novamente mais tarde!`);
          }
        })
        .catch((e) => {
          toast.error(`Erro: ${e.request.status}, Não foi possível, tente novamente mais tarde!`);
        });
    };

    const formatarCPF = (str: string) => {
        // Remove todos os caracteres não numéricos
        const digitsOnly = str.replace(/\D/g, '');
    
        // Verifica se o CPF tem a quantidade correta de dígitos
        if (digitsOnly.length !== 11) {
            throw new Error('CPF deve conter 11 dígitos.');
        }
    
        // Formata o CPF no formato com pontos e traço
        return `${digitsOnly.slice(0, 3)}.${digitsOnly.slice(3, 6)}.${digitsOnly.slice(6, 9)}-${digitsOnly.slice(9)}`;
    }
  
    return (
      <>
        <Container className="p-0 mb-6">
            <div className="row container-units mb-4">
              <div className="col-12">
                <span>Home</span>
              </div>
              <div className="col-12">
                <div className="row">
                  <div className="col-md-12">
                    <h1>Categoria</h1>
                  </div>
                </div>
              </div>
            </div> 
            {
              loading ?
                <LoadingComponent/>
              :
                arrayData.length > 0 ? (
                  arrayData.map(data => (
                    <Card className="partner-card mb-3" key={data.id}>
                      <CardContent>
                        <Typography variant="h6" component="h2" className="partner-name">
                          {data.nome}
                        </Typography>
      
                        <Tooltip title={data.is_ativo === '1' ? 'Liberado' : 'Bloqueado'} placement="top">
                          <div className={`partner-status-${data.is_ativo === '1' ? 'active' : 'inactive'}`}></div>
                        </Tooltip>

                        <TriangleImg alt='triangle background' src={`/images/misc/triangle-light.png`} />

                      </CardContent>
                      <CardActions className="card-cartion-buttons">
                        <Button
                          size="small"
                          color="primary"
                          className="button-card-action"
                          onClick={() => handleActivateDeactivate(data)}
                        >
                          {data.is_ativo === '1' ? 'Desativar' : 'Ativar'}
                        </Button>
                        {
                          data.is_ativo === "0" ? null :
                          <>
                            <Button
                              size="small"
                              className="button-card-action-update"
                              onClick={() => handleUpdate(data)}
                            >
                              Atualizar
                            </Button>
  
                            {/* <Button
                              size="small"
                              className="button-card-action-update"
                              component={Link}
                              to={`/Usuarios/${data.id}`}
                            >
                              Usuários
                            </Button> */}
                          </>
                          
                        }
                      </CardActions>
                    </Card>
                  ))
                )
              : 
                (
                  <>
                    <WarningComponent title="Nenhum cadastro"/>
                  </>
                )
            }
            
        </Container>
        <FormCreatedCategory 
          reloadComponent={handleReload}
          idCategoria={selectedValues?.id_categoria}
        />
        <FormUpdateCategory
          reloadComponent={handleReload}
          toUpdate={selectedValues}
          idServicoUnidade={selectedValues?.id}
          isOpen={updateModalOpen}
          onClose={() => setUpdateModalOpen(false)}
        />
      </>
    );
}

export default CategoryComponent;
