// ** MUI Imports
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'

const FooterContent = () => {

    // ** Hook
    const theme = useTheme()

    // ** Vars
    const contentWidth  = 'boxed'

    return (
        <Box
            component='footer'
            className='layout-footer'
            sx={{
                zIndex: 10,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}
        >
            <Box
            className='footer-content-container'
            sx={{
                
                borderTopLeftRadius: 14,
                borderTopRightRadius: 14,
                padding: theme.spacing(2, 2),
                position: 'absolute',
                bottom: 0,
                ...(contentWidth === 'boxed' && { '@media (min-width:1440px)': { maxWidth: 1440 } })
            }}
            >
                <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography sx={{ mr: 2 }}>
                        {`© ${new Date().getFullYear()}, BSLTECH `}
                    </Typography>
                </Box>
            </Box>
        </Box>
    )
}

export default FooterContent
