import React, { useState, useEffect, FormEvent, ChangeEvent } from "react";
import { Card, CardContent, Typography, CardActions, Tooltip, Button } from "@mui/material";
import { toast } from 'react-toastify';
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import WarningComponent from "../../../components/warnig/warnig"
import LoadingComponent from "../../../components/loadingCircular/loadingComponent";
import Api from "../../../service/apiBase";
import { Container } from "react-bootstrap";
import { Client } from "../../../model/partner";
import FormCreatedClient from "../../../components/forms/Client/form-created";
import FormUpdateClient from "../../../components/forms/Client/form-update";
import { styled } from '@mui/material/styles'

const TriangleImg = styled('img')({
  right: 0,
  bottom: 0,
  height: 170,
  position: 'absolute'
})

function ClienteComponent() {
    const [arrayData, setArrayData] = useState<Client[]>([]);
    const [loading, setLoading] = useState(true);
    const [selectedValues, setSelectedValues] = useState<Client | null>(null);
    const [updateModalOpen, setUpdateModalOpen] = useState(false);
    const { id } = useParams(); 
    const [reload, setReload] = useState(false);
  
    const handleReload = () => {
      setReload(!reload);
    };
  
    const handleUpdate = (values: Client) => {
        setSelectedValues(values);
        setUpdateModalOpen(true);
    };
  
    useEffect(() => {
        Api.getAllClient()
        .then(data => {
          if(data.length === undefined ) {
            setArrayData([data])
          }else {
            setArrayData(data)
          }
          setLoading(false);
        })
        .catch(error => {
          console.error('Erro ao buscar:', error);
        });
    }, [reload]);
  
  
    const handleActivateDeactivate = (data: Client) => {
      const newStatus = data.deleted === "1" ? "0" : "1";
      Api.updateClientActivateDesactivate(data.id)
        .then((response) => {
          if (response.message === 'Usuario Deletado com Sucesso') {
            toast.success(`${newStatus === "0" ? 'Liberado' : 'Bloqueado'} com sucesso`);
            setArrayData(prev => prev.map(p => p.id === data.id ? { ...p, deleted: newStatus } : p));
          } else {
            toast.error(`Não foi possível, tente novamente mais tarde!`);
          }
        })
        .catch((e) => {
          toast.error(`Erro: ${e.request.status}, Não foi possível, tente novamente mais tarde!`);
        });
    };

    const formatarCPF = (str: string) => {
        // Remove todos os caracteres não numéricos
        const digitsOnly = str.replace(/\D/g, '');
    
        // Verifica se o CPF tem a quantidade correta de dígitos
        if (digitsOnly.length !== 11) {
            throw new Error('CPF deve conter 11 dígitos.');
        }
    
        // Formata o CPF no formato com pontos e traço
        return `${digitsOnly.slice(0, 3)}.${digitsOnly.slice(3, 6)}.${digitsOnly.slice(6, 9)}-${digitsOnly.slice(9)}`;
    }

    const formatPhoneNumber = (phoneNumber: string) => {
        // Remove todos os caracteres não numéricos
        const digitsOnly = phoneNumber.replace(/\D/g, '');
      
        // Verifica se o número de telefone tem a quantidade correta de dígitos
        if (digitsOnly.length < 10 || digitsOnly.length > 11) {
          throw new Error('Número de telefone inválido.');
        }
      
        // Formata o número de telefone no formato com código de área
        if (digitsOnly.length === 10) {
          return `(${digitsOnly.slice(0, 2)}) ${digitsOnly.slice(2, 6)}-${digitsOnly.slice(6)}`;
        } else {
          return `(${digitsOnly.slice(0, 2)}) ${digitsOnly.slice(2, 3)} ${digitsOnly.slice(3, 7)}-${digitsOnly.slice(7)}`;
        }
      };
      
  
    return (
      <>
        <Container className="p-0 mb-6">
            <div className="row container-units mb-4">
              <div className="col-12">
                <span>Home</span>
              </div>
              <div className="col-12">
                <div className="row">
                  <div className="col-md-12">
                    <h1>Clientes</h1>
                  </div>
                </div>
              </div>
            </div> 
            {
              loading ?
                <LoadingComponent/>
              :
                arrayData.length > 0 ? (
                  arrayData.map(data => (
                    <Card className="partner-card mb-3" key={data.id}>
                      <CardContent>
                        {/* <Typography variant="h6" component="h2" className="partner-name">
                          {data.nome}
                        </Typography> */}
                        <Typography variant='h6'>{data.nome}</Typography>
                        <Typography variant="body2" color="textSecondary" component="p">
                          Email: {data.email} 
                        </Typography>

                        <Typography variant="body2" color="textSecondary" component="p">
                          CPF: {formatarCPF(data.cpf)}
                        </Typography>

                        <Typography variant="body2" color="textSecondary" component="p">
                          Telefone: {formatPhoneNumber(data.telefone)}
                        </Typography>
      
                        <Tooltip title={data.deleted === "0" ? 'Liberado' : 'Bloqueado'} placement="top">
                          <div className={`partner-status-${data.deleted === "0" ? 'active' : 'inactive'}`}></div>
                        </Tooltip>

                        <TriangleImg alt='triangle background' src={`/images/misc/triangle-light.png`} />

                      </CardContent>
                      <CardActions className="card-cartion-buttons">
                        <Button
                          size="small"
                          color="primary"
                          className="button-card-action"
                          onClick={() => handleActivateDeactivate(data)}
                        >
                          {data.deleted === "1" ? 'Habilitar' : 'Desabilitar'}
                        </Button>
                        {
                          data.deleted === "1" ? null :
                          <>
                            <Button
                              size="small"
                              className="button-card-action-update"
                              onClick={() => handleUpdate(data)}
                            >
                              Atualizar
                            </Button>
  
                            {/* <Button
                              size="small"
                              className="button-card-action-update"
                              component={Link}
                              to={`/Usuarios/${data.id}`}
                            >
                              Usuários
                            </Button> */}
                          </>
                          
                        }
                      </CardActions>
                    </Card>
                  ))
                )
              : 
                (
                  <>
                    <WarningComponent title="Nenhum cadastro"/>
                  </>
                )
            }
            
        </Container>
        <FormCreatedClient 
          reloadComponent={handleReload}
          id={selectedValues?.id}
        />
        <FormUpdateClient
          reloadComponent={handleReload}
          toUpdate={selectedValues}
          id={selectedValues?.id}
          isOpen={updateModalOpen}
          onClose={() => setUpdateModalOpen(false)}
        />
      </>
    );
}

export default ClienteComponent;
