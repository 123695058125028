import React, { useState, useEffect } from "react";
import { Card, CardContent, Typography, CardActions, Button, Grid } from "@mui/material";
import { toast } from 'react-toastify';
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import WarningComponent from "../../../components/warnig/warnig"
import LoadingComponent from "../../../components/loadingCircular/loadingComponent";
import Api from "../../../service/apiBase";
import { Container } from "react-bootstrap";
import FormCreatedForwarding from "../../../components/forms/Forwarding/form-created";
import FormUpdateClient from "../../../components/forms/Client/form-update";
import useUser from "../../../useUser";
import StripeModal from "../../../components/stripe/stripe-modal";
import PixComponent from "../../../components/pix/pix-component";
import Divider from '@mui/material/Divider';
import AssignmentIcon from '@mui/icons-material/Assignment';
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import Tab from '@mui/material/Tab'
import TabContext from '@mui/lab/TabContext'
import PixCreatedComponent from "../../../components/pix/pix-created-component";
import StripeComponent from "../../../components/stripe/stripe-component";
import CardForm from "../../../components/stripe/stripe-component-teste";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { styled } from '@mui/material/styles'

const Img = styled('img')(({ theme }) => ({
  marginBottom: theme.spacing(10),
  [theme.breakpoints.down('lg')]: {
    height: 300,
    marginTop: theme.spacing(1)
  },
  [theme.breakpoints.down('md')]: {
    height: 200
  },
  [theme.breakpoints.up('lg')]: {
    marginTop: theme.spacing(1)
  }
}))

function ForwardingPaymentComponent() {
    const [arrayData, setArrayData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [payment, setPayment] = useState(false);
    const [selectedValues, setSelectedValues] = useState(null);
    const [updateModalOpen, setUpdateModalOpen] = useState(false);
    const [updateModalOpenPix, setUpdateModalOpenPix] = useState(false);
    const [taxId, setTaxId] = useState('');
    const { id } = useParams(); 
    const [reload, setReload] = useState(false);
    const [service, setService] = useState([]);
    const [value, setValue] = useState('1')

    const { user } = useUser();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const stripePromise = loadStripe('pk_test_51OVErXK4heB4OhYCIFsSykCsrlNfrN0JTzYKldZrHADBO9FZvudc6NuQ4p4PvrbhzbHePs1ZcSX4EaYGUnHIsg7t00fUQRKJhy');

    const handleReload = () => {
      setReload(!reload);
    };
  
    const handleUpdate = (values) => {
        setSelectedValues(values);
        setUpdateModalOpen(true);
    };

    const openModalPix = (taxId) => {
      setUpdateModalOpenPix(true);
      setTaxId(taxId)
    };

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }
  
    useEffect(() => {
        Api.getSendForwarding(id)
        .then(data => {
          if(data.length === undefined ) {
            setArrayData([data?.encaminhamento])
          }else {
            setArrayData(data?.encaminhamento)
          }
          
            const groupedServices = {};
            data?.encaminhamento?.servicos.forEach((servico) => {
                const categoriaNome = servico?.infoServico?.infoCategoria?.nome;
                if (!groupedServices[categoriaNome]) {
                    groupedServices[categoriaNome] = [];
                }
                groupedServices[categoriaNome].push(servico);
            });
            setService(groupedServices);
          setLoading(false);
        })
        .catch(error => {
          console.error('Erro ao buscar:', error);
        });
    }, [reload]);
  
  
    const handleActivateDeactivate = (data) => {
      const newStatus = data.deleted === 1 ? 0 : 1;
      Api.updateClientActivateDesactivate(data.id)
        .then((response) => {
          if (response.message === 'Usuario Deletado com Sucesso') {
            toast.success(`${newStatus === 0 ? 'Liberado' : 'Bloqueado'} com sucesso`);
            setArrayData(prev => prev.map(p => p.id === data.id ? { ...p, deleted: newStatus } : p));
          } else {
            toast.error(`Não foi possível, tente novamente mais tarde!`);
          }
        })
        .catch((e) => {
          toast.error(`Erro: ${e.request.status}, Não foi possível, tente novamente mais tarde!`);
        });
    };

    const handleRemoveEncaminhamento = (data) => {
      setLoading(true);
      Api.deleteEncaminhamento(data.id)
        .then((response) => {
          if (response.message === 'Encaminhamento removido com sucesso') {
            toast.success(`Encaminhamento removido com sucesso`);
            handleReload();
            setLoading(false);

          } else {
            toast.error(`Não foi possível, tente novamente mais tarde!`);
            setLoading(false);
          }
        })
        .catch((e) => {
          toast.error(`Erro: ${e.request.status}, Não foi possível, tente novamente mais tarde!`);
          setLoading(false);
        });
    };

    const formatarCPF = (str) => {
        // Remove todos os caracteres não numéricos
        const digitsOnly = str.replace(/\D/g, '');
    
        // Verifica se o CPF tem a quantidade correta de dígitos
        if (digitsOnly.length !== 11) {
            throw new Error('CPF deve conter 11 dígitos.');
        }
    
        // Formata o CPF no formato com pontos e traço
        return `${digitsOnly.slice(0, 3)}.${digitsOnly.slice(3, 6)}.${digitsOnly.slice(6, 9)}-${digitsOnly.slice(9)}`;
    }

    const formatarDataHoraBrasileiro = (dataHoraString) => {
      const dataHora = new Date(dataHoraString);
      const dia = dataHora.getDate().toString().padStart(2, '0');
      const mes = (dataHora.getMonth() + 1).toString().padStart(2, '0');
      const ano = dataHora.getFullYear();
      const hora = dataHora.getHours().toString().padStart(2, '0');
      const minutos = dataHora.getMinutes().toString().padStart(2, '0');
    
      return `${dia}/${mes}/${ano} ${hora}:${minutos}`;
    }

    const formatPhoneNumber = (phoneNumber) => {
        // Remove todos os caracteres não numéricos
        const digitsOnly = phoneNumber.replace(/\D/g, '');
      
        // Verifica se o número de telefone tem a quantidade correta de dígitos
        if (digitsOnly.length < 10 || digitsOnly.length > 11) {
          throw new Error('Número de telefone inválido.');
        }
      
        // Formata o número de telefone no formato com código de área
        if (digitsOnly.length === 10) {
          return `(${digitsOnly.slice(0, 2)}) ${digitsOnly.slice(2, 6)}-${digitsOnly.slice(6)}`;
        } else {
          return `(${digitsOnly.slice(0, 2)}) ${digitsOnly.slice(2, 3)} ${digitsOnly.slice(3, 7)}-${digitsOnly.slice(7)}`;
        }
    };

    const openModal = () => {
      setIsModalOpen(true);
    };
  
    const closeModal = () => {
      setIsModalOpen(false);
    };

    const handleReloadStripe = () => {
      // setReload(!reload);
    };

    const handlePayment = () => {
        setPayment(true);
    }
      
  
    return (
      <>
        <Container className="p-0 mb-6">
            <div className="row container-units mb-4">
              <div className="col-12">
                <span>Home {' > '} Encaminhamento Enviados</span>
              </div>
              <div className="col-12">
                <div className="row">
                  <div className="col-md-12">
                    <h1>Detalhe do Encaminhamento</h1>
                  </div>
                </div>
              </div>
            </div> 
            {
              loading ?
                <LoadingComponent/>
              :
                arrayData.length > 0 ? (
                  arrayData.map(data => (
                    <Card className="partner-card mb-3" key={data.id}>
                      <CardContent>
                        <Typography variant="h6" component="div" className="mb-2 d-flex justify-content-end align-items-center">
                            Código: {data.id}
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item sm={12} xs={12}>
                                <div className="d-flex justify-content-center flex-column align-items-center">
                                    <Typography variant="h6" component="div" className="mb-2 typography-encaminhamento">
                                        Encaminhamento
                                    </Typography>
                                    <Divider variant="middle" 
                                        sx={{
                                            m: 0,
                                            width: '100%',
                                            lineHeight: 'normal',
                                            textTransform: 'uppercase',
                                            '&:before, &:after': { top: 7, transform: 'none' },
                                            '& .MuiDivider-wrapper': { px: 2.5, fontSize: '0.75rem', letterSpacing: '0px' },
                                            marginBottom: '10px',
                                            marginTop: '10px',
                                            borderWidth: '1px',
                                          }}
                                    />
                                </div>
                                <Container className="pl-3 pr-3">
                                    <Typography variant="h6" component="div">
                                        Cliente:
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" className="ml-3">
                                       Nome: {data?.info_cliente?.nome}
                                    </Typography>
                                    
                                    
                                    <Typography variant="body2" color="text.secondary" className="ml-3">
                                      Telefone: {formatPhoneNumber(data?.info_cliente?.telefone)}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" className="ml-3">
                                       CPF: {formatarCPF(data?.info_cliente?.cpf)}
                                    </Typography>
                                    <Typography variant="body2"color="text.secondary" className="ml-3">
                                      Email: {data?.info_cliente?.email} 
                                    </Typography>

                                    <Typography variant="h6" component="div" className="mt-2">
                                        Origem:
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" className="ml-3">
                                       Nome: {data?.info_encaminhador?.nome}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" className="ml-3">
                                       CPF: {formatarCPF(data?.info_encaminhador?.cpf)}
                                    </Typography>
                                    <Typography variant="body2"color="text.secondary" className="ml-3">
                                      Email: {data?.info_encaminhador?.email} 
                                    </Typography>

                                    <Typography variant="h6" component="div" className="mt-2">
                                        Destino:
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" className="ml-3">
                                       Nome: {data?.info_unidade_recebe?.nome}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" className="ml-3">
                                       Endereço: {data?.info_unidade_recebe?.endereco}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" className="ml-3">
                                      Telefone: {formatPhoneNumber(data?.info_unidade_recebe?.telefone)}
                                    </Typography>
                                    
                                    <Typography variant="h6" component="div" className="mt-2">
                                        Serviços solicitados:
                                    </Typography>

                                    {Object.keys(service).map((categoriaNome) => (
                                        <div key={categoriaNome}>
                                            <Typography variant="body1" color="text.primary" className="ml-3 mb-1">
                                            <AssignmentIcon />  {categoriaNome}:
                                            </Typography>
                                            {service[categoriaNome].map((servico) => (
                                            <Typography key={servico.id} variant="body2" color="text.secondary" className="ml-4 mb-1">
                                                {servico.infoServico.nome} - R$ {Number(servico.infoServico.valor).toFixed(2).replace('.', ',')}
                                            </Typography>
                                            ))}
                                        </div>
                                    ))}
                                    <Typography variant="body1" color="text.secondary" className="mt-3 d-flex justify-content-end align-items-center">
                                        <strong className="mr-1">Valor:</strong> R$ {data?.valorTotalEncaminhamento?.toFixed(2).replace('.', ',')}
                                    </Typography>
                                </Container>
                                <Divider variant="middle" 
                                        sx={{
                                            m: 0,
                                            width: '100%',
                                            lineHeight: 'normal',
                                            textTransform: 'uppercase',
                                            '&:before, &:after': { top: 7, transform: 'none' },
                                            '& .MuiDivider-wrapper': { px: 2.5, fontSize: '0.75rem', letterSpacing: '0px' },
                                            marginBottom: '10px',
                                            marginTop: '10px',
                                            borderWidth: '1px',
                                          }}
                                    />
                            </Grid>
                        </Grid>
                      </CardContent>

                      <TabContext value={value}>
                            <TabList centered onChange={handleChange} aria-label='card navigation example'>
                                <Tab value='1' label='Cartão' />
                                <Tab value='2' label='Pix' />
                            </TabList>
                            <CardContent sx={{ textAlign: 'center' }}>
                                <TabPanel value='1' sx={{ p: 0 }}>
                                    <StripeComponent
                                    id={id}
                                    ></StripeComponent>
                                </TabPanel>
                                <TabPanel value='2' sx={{ p: 0 }}>
                                    <PixCreatedComponent
                                        idEncaminhamento={id}
                                        taxId={data?.txId}
                                    ></PixCreatedComponent>
                                </TabPanel>
                            </CardContent>
                          </TabContext>

                    {
                        payment ?
                         null
                        : 
                          (
                            
                            data.status_pagamento === "PAGAMENTO REALIZADO" || data.tipo_pagamento === "A PAGAR CLIENTE" || data.tipo_pagamento === "A PAGAR PARCEIRO" ? 
                            
                            <section className="d-flex justify-content-center align-items-center flex-column">
                              <Typography variant='h5'  sx={{ mb: 1, fontSize: '20px !important', fontFamily: 'Montserrat' }}>
                                Concluído
                              </Typography> 
                              <Img height='300' alt='error-illustration' src='/images/pages/Completed-pana.svg' />
                            </section>

                            : 
                            
                              <CardContent>
                                  <Button variant='contained'
                                      type="submit"
                                      className='w-100'
                                      disabled={payment}
                                      onClick={() => handlePayment()}
                                  >
                                      Realizar Pagamento</Button>
                              </CardContent>
                            
                          )
                    }
                     


                      <CardActions className="card-cartion-buttons mt-2">
                        
                        {/* <Button
                          size="small"
                          color="primary"
                          className="button-card-action"
                          onClick={() => handleRemoveEncaminhamento(data)}
                        >
                          Cancelar
                        </Button> */}
                        
                      </CardActions>
                    </Card>
                  ))
                )
              : 
                (
                  <>
                    <WarningComponent title="Nenhum cadastro"/>
                  </>
                )
            }
            
        </Container>

        <PixComponent
          isOpen={updateModalOpenPix}
          onClose={() => setUpdateModalOpenPix(false)}
          taxId={taxId}
        />
        {/* <FormCreatedForwarding 
          reloadComponent={handleReload}
          id={selectedValues?.id}
        />
        <FormUpdateClient
          reloadComponent={handleReload}
          toUpdate={selectedValues}
          id={selectedValues?.id}
          isOpen={updateModalOpen}
          onClose={() => setUpdateModalOpen(false)}
        /> */}
      </>
    );
}

export default ForwardingPaymentComponent;
