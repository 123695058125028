import React, { useState } from 'react';
import './App.css';
import Header from './pages/layout/header/Header';
import Footer from './pages/layout/footer/Footer';
import Login from './pages/public/login/Login';

import "react-bootstrap/dist/react-bootstrap.min.js";
import "react-bootstrap-typeahead/dist/react-bootstrap-typeahead.min.js";
import 'react-toastify/dist/ReactToastify.css';
import "react-notifications-component/dist/theme.css";
import useToken from './useToken';
import useUser from './useUser';
import ResponsiveDrawer from './components/ResponsiveDrawer';
import { ToastContainer } from 'react-toastify';
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import themeConfig from './configs/themeConfig';
import ThemeComponent from './theme/ThemeComponent';
import PageNotFound from './pages/restricted/error/404';
import RegisterPage from './pages/public/register/RegisterPage';
import PixComponent from './pages/public/pix/pixComponent';
import ResetPassword from './pages/public/reset/resetPassword';

const settings = {
  themeColor: 'primary',
  mode: themeConfig.mode,
  contentWidth: themeConfig.contentWidth
}

function App() {
  const { token, setToken } = useToken();
  
  if (!token) {
    return (
      <ThemeComponent settings={settings}>
        <ToastContainer/>
        <Router>
          <Routes>
            
            <Route path="/login" element={<Login setToken={setToken}/>} />
            <Route path="/qrcode/:url/*" element={<PixComponent />} />
            <Route path="/register" element={<RegisterPage/>} />
            <Route path="/reset" element={<ResetPassword/>} />
            <Route path="*" element={<Navigate to="/login" />} />
          </Routes>
        </Router>
        {/* <Login setToken={setToken}/> */}
      </ThemeComponent>
    )
  }
  return (
    <>
      <ThemeComponent settings={settings}>
        <ToastContainer/>
        <Router>
          <ResponsiveDrawer></ResponsiveDrawer>
        </Router>
      </ThemeComponent>
    </>
  );
}

export default App;
