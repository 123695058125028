import axios, { AxiosInstance } from "axios";
// import { AccessToken } from "../model/apiBase";

// const tokenData: string | null = localStorage.getItem("token");
// const accessTokenData: AccessToken | null = tokenData ? JSON.parse(tokenData) : null;

interface AccessToken {
  token: string;
}

const getAccessToken = (): AccessToken | null => {
  const tokenData: string | null = localStorage.getItem("token");
  return tokenData ? JSON.parse(tokenData) : null;
};

class Api {
  // axios: AxiosInstance;
  
  // constructor(accessToken: AccessToken) {
  //   this.axios = axios.create({
  //     baseURL: "https://api-bslencaminhamentos.bsltech.com.br/api/",
  //     timeout: 30000,
  //     headers: {
  //       "Authorization": `Bearer ${accessToken}`
  //     },
  //     withCredentials: false,
  //   });

  //   this.axios.interceptors.response.use(
  //     (response) => {
  //       return response;
  //     },
  //     async (error) => {
  //       console.log(error.response.status, accessToken)
  //       if (error.response.status === 401 && accessToken) {
  //         await this.refreshToken();
  //       }
  //       return Promise.reject(error);
  //     }
  //   );
  // }

  // async refreshToken() {
  //   // localStorage.removeItem('token');
  //   // document.location.reload();
  // }

  axios: AxiosInstance;
  
  constructor() {
    this.axios = axios.create({
      baseURL: "https://api-bslencaminhamentos.bsltech.com.br/api/",
      timeout: 30000,
      headers: {
        "Authorization": `Bearer ${getAccessToken()?.token}`
      },
      withCredentials: false,
    });

    this.axios.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        if (error.response.status === 401 && getAccessToken()?.token) {
          await this.refreshToken();
        }
        return Promise.reject(error);
      }
    );
  }

  

  // updateHeaders() {
  //   this.accessToken = this.getAccessToken();
  //   this.axios.defaults.headers.common["Authorization"] = `Bearer ${this.accessToken?.token}`;
  // }

  async refreshToken() {
    // localStorage.removeItem('token');
    document.location.reload();
  }

  /**
   * 
   * Unidades
   * 
  */
  async crietedUnit(json: any) {
    let response = await this.axios.post("/unidade/create", json);
    return response.data
  }

  async getAllUnits() {
    let response = await this.axios.get("/unidade");
    return response.data
  }

  async getUnit(id_unit: string) {
    let response = await this.axios.get(`/unidade/${id_unit}`);
    return response.data
  }

  async getUnitByParceiro(id_unit: string) {
    let response = await this.axios.get(`/unidade/getUnitByParceiro/${id_unit}`);
    return response.data
  }

  async updateUnitActivateDesactivate(id_unit: number) {
    let response = await this.axios.post(`/unidade/${id_unit}/activateDesactivate`);
    return response.data
  }

  async updateUnit(id_parceiro: any, json?: any) {
    delete json?.id;
    delete json?.is_ativo;
    delete json?.info_parceiro
    let response = await this.axios.put(`/unidade/${id_parceiro}/update`, json);
    return response.data
  }


  /**
   * 
   * Parceiros
   * 
  */
  async getAllPartners() {
    let response = await this.axios.get("/parceiros");
    return response.data
  }

  async createPartner(json: any) {
    let response = await this.axios.post("/parceiros/create", json);
    return response.data
  }

  async updatePartnerActivateDesactivate(id_parceiro: any) {
    let response = await this.axios.post(`/parceiros/${id_parceiro}/activateDesactivate`);
    return response.data
  }

  async updatePartner(id_parceiro: any, json?: any) {
    delete json?.id;
    delete json?.is_ativo;
    let response = await this.axios.put(`/parceiros/${id_parceiro}/update`, json);
    return response.data
  }

  /**
   * 
   * Usuários
   * 
  */

  async getAllUsers() {
    let response = await this.axios.get("/usuario");
    return response.data
  }

  async getUnitByUsers(id: any) {
    let response = await this.axios.get(`/usuario/unidade/${id}`);
    return response.data
  }

  async updateUsersActivateDesactivate(id: any) {
    let response = await this.axios.post(`/usuario/${id}/activateDesactivate`);
    return response.data
  }

  async registerUser(json: any) {
    let response = await this.axios.post("/auth/register", json);
    return response.data
  }

  async updateUser(id: any, json?: any) {
    delete json?.id;
    delete json?.is_ativo;
    let response = await this.axios.put(`/usuario/${id}/edit`, json);
    return response.data
  }

  async resetPassword(json: any) {
    let response = await this.axios.post("/usuario/mudarSenha", json);
    return response.data
  }

  async getEncaminhadores() {
    let response = await this.axios.get(`/encaminhadores/pegarTodos`);
    return response.data
  }

  /**
   * 
   * Serviços
   * 
  */
  
  async updateServiceActivateDesactivate(id: any) {
    let response = await this.axios.post(`/servicos/${id}/activateDesactivateServico`);
    return response.data
  }

  async updateServiceActivateDesactivatePrestador(id: any) {
    let response = await this.axios.post(`/prestador/servicos/${id}/ativarDesativar`);
    return response.data
  }

  async getServicosByUnidade(id: string) {
    let response = await this.axios.get(`/servicos/getServicosByUnidade/${id}`);
    return response.data
  }

  async getServicosByPrestador(id: string) {
    let response = await this.axios.get(`/prestador/${id}/servicos`);
    return response.data
  }

  async getServicosByUnidadeCategory(id: string, idUnidade?: string) {
    let response = await this.axios.get(`/servicos/unidade/${idUnidade}/getServicosByCategoria/${id}`);
    return response.data
  }

  async createService(json: any) {
    let response = await this.axios.post("/servicos/create", json);
    return response.data
  }

  async updateServiceUnidade(id: any, json?: any) {
    
    let response = await this.axios.put(`/servicos/${id}/update`, json);
    return response.data
  }



  async addServiceEncaminhamento(id: any, json?: any) {
    let response = await this.axios.post(`/encaminhamento/${id}/adicionarServico`, json);
    return response.data
  }

  async addServicePrestadorEncaminhamento(id: any, idServico: any, json?: any) {
    let response = await this.axios.post(`/encaminhamento/${id}/servicoPrestador/${idServico}`, json);
    return response.data
  }

  async getServicosByEncaminhamento(id: any) {
    let response = await this.axios.get(`/encaminhamento/${id}/servico`);
    return response.data
  }

  async getServicosPrestadorByEncaminhamento(id: any) {
    let response = await this.axios.get(`/encaminhamento/${id}/prestador`);
    return response.data
  }
  
  async deleteServicosByEncaminhamento(id: any, idService: any) {
    let response = await this.axios.delete(`/encaminhamento/${id}/deleteServico/${idService}`);
    return response.data
  }

  /**
   * 
   * Categoria
   * 
  */

  async getAllCategory() {
    let response = await this.axios.get("/servicos/getCategorias");
    return response.data
  }

  async updateCategoryActivateDesactivate(id: any) {
    let response = await this.axios.post(`/servicos/${id}/activateDesactivateTipo`);
    return response.data
  }

  async createCategory(json: any) {
    let response = await this.axios.post("/servicos/createCategorias", json);
    return response.data
  }

  async updateCategory(id: any, json?: any) {
    
    let response = await this.axios.put(`/servicos/${id}/updateCategoria`, json);
    return response.data
  }

  /**
   * 
   * Clientes
   * 
  */

  async getAllClient() {
    let response = await this.axios.get("/cliente/all");
    return response.data
  }

  async getAllClientActived() {
    let response = await this.axios.get("/cliente");
    return response.data
  }

  async updateClientActivateDesactivate(id: any) {
    let response = await this.axios.delete(`/cliente/${id}/remove`);
    return response.data
  }

  async createClient(json: any) {
    let response = await this.axios.post("/cliente/create", json);
    return response.data
  }

  async updateClient(id: any, json?: any) {
    
    let response = await this.axios.put(`/cliente/${id}/update`, json);
    return response.data
  }

  /**
   * 
   * Encaminhamento Send
   * 
  */

  async getAllEncaminhamento() {
    let response = await this.axios.get("/encaminhamento");
    return response.data
  }

  async getAllValorEncaminhamento() {
    let response = await this.axios.get("/valor/valorTotalEncaminhamentos");
    return response.data
  }

  async getAllSendForwarding(id?: number) {
    let response = await this.axios.get(`/encaminhamento/unfinished/${id}`);
    return response.data
  }

  async getAllSendForwardingAdmin(id?: number) {
    let response = await this.axios.get(`/encaminhamento/encaminhador/${id}`);
    return response.data
  }

  async getAllSaldoEncaminhador(id?: number) {
    let response = await this.axios.get(`/usuario/${id}/verificarSaldoDisponivel`);
    return response.data
  }

  async getSendForwarding(id?: number) {
    let response = await this.axios.get(`/encaminhamento/${id}`);
    return response.data
  }

  async getAllReceivedForwarding(id?: any) {
    let response = await this.axios.get(`/encaminhamento/unfinished/${id}`);
    return response.data
  }

  async getAllEncaminhamentosFinalizados(id?: any) {
    let response = await this.axios.get(`/encaminhamento/finished/${id}`);
    return response.data
  }

  async realizarPagamentoEncaminhador(id?: any) {
    let response = await this.axios.post(`/usuario/${id}/pagarEncaminhamentosPendentes`);
    return response.data
  }

  async createEncaminhamento(json: any) {
    let response = await this.axios.post("/encaminhamento", json);
    return response.data
  }

  async deleteEncaminhamento(id: any) {
    let response = await this.axios.delete(`/encaminhamento/delete/${id}`);
    return response.data
  }

  async updateCreateEncaminhamento(id: any, json: any) {
    let response = await this.axios.put(`/encaminhamento/edit/${id}`, json);
    return response.data
  }

  async updateEncaminhamentoRealizado(id: any, idUser: any) {
    let response = await this.axios.post(`/encaminhamento/${id}/confirmar/${idUser}`);
    return response.data
  }

  async getSearchClient(search?: string) {
    let params = {
      nome: search
    };
  
    let response = await this.axios.get(`/busca/byName`, { params });
    return response.data;
  }

  /**
   * 
   * Pagamentos
   * 
  */

  async stripeClientSecret(id: any) {
    let response = await this.axios.post(`/pagamento/encaminhamento/cartao/${id}`);
    return response.data
  }

  async getPixTxId(id?: string) {
    let response = await this.axios.get(`/pagamento/verificarStatus/${id}`);
    return response.data
  }

  async gerarPixId(id?: string) {
    let response = await this.axios.post(`/pagamento/encaminhamento/${id}`);
    return response.data
  }

   /**
   * 
   * Prestador de serviço
   * 
  */

  async prestadorPorTipo(id: any) {
    let response = await this.axios.get(`/prestador/tipo/${id}`);
    return response.data
  }

  /**
   * 
   * password
   * 
  */


  async resetPasswordEmail(json?: any) {
    let response = await this.axios.post("/usuario/resetSenha", json);
    return response.data
  }

  // async updateClientActivateDesactivate(id: any) {
  //   let response = await this.axios.delete(`/cliente/${id}/remove`);
  //   return response.data
  // }
}

const api = new Api();
export default api;