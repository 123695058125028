// ** MUI Imports
import React, { useState, useEffect, FormEvent, ChangeEvent } from "react";
import { Link, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Button from '@mui/material/Button'
import { useTheme } from '@mui/material/styles'
import CardHeader from '@mui/material/CardHeader'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import CardContent from '@mui/material/CardContent'
import Api from '../../../service/apiBase';
// ** Icons Imports
import DotsVertical from 'mdi-material-ui/DotsVertical'


// ** Custom Components Imports
// import ReactApexcharts from 'src/@core/components/react-apexcharts'

const WeeklyOverviewEncaminhador = () => {
  // ** Hook
  const theme = useTheme()

  // const options = {
  //   chart: {
  //     parentHeightOffset: 0,
  //     toolbar: { show: false }
  //   },
  //   plotOptions: {
  //     bar: {
  //       borderRadius: 9,
  //       distributed: true,
  //       columnWidth: '40%',
  //       endingShape: 'rounded',
  //       startingShape: 'rounded'
  //     }
  //   },
  //   stroke: {
  //     width: 2,
  //     colors: [theme.palette.background.paper]
  //   },
  //   legend: { show: false },
  //   grid: {
  //     strokeDashArray: 7,
  //     padding: {
  //       top: -1,
  //       right: 0,
  //       left: -12,
  //       bottom: 5
  //     }
  //   },
  //   dataLabels: { enabled: false },
  //   colors: [
  //     theme.palette.background.default,
  //     theme.palette.background.default,
  //     theme.palette.background.default,
  //     theme.palette.primary.main,
  //     theme.palette.background.default,
  //     theme.palette.background.default
  //   ],
  //   states: {
  //     hover: {
  //       filter: { type: 'none' }
  //     },
  //     active: {
  //       filter: { type: 'none' }
  //     }
  //   },
  //   xaxis: {
  //     categories: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
  //     tickPlacement: 'on',
  //     labels: { show: false },
  //     axisTicks: { show: false },
  //     axisBorder: { show: false }
  //   },
  //   yaxis: {
  //     show: true,
  //     tickAmount: 4,
  //     labels: {
  //       offsetX: -17,
  //       formatter: value => `${value > 999 ? `${(value / 1000).toFixed(0)}` : value}k`
  //     }
  //   }
  // }

  const [unitsData, setUnits] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    Api.getAllUsers()
      .then(data => {
        if(data.length === undefined ) {
          setUnits([data])
        }else {
          setUnits(data)
        }
        setLoading(false);
      })
      .catch(error => {
        console.error('Erro ao buscar:', error);
      });
  }, []);

  return (
    <Card>
     
      <CardContent sx={{ '& .apexcharts-xcrosshairs.apexcharts-active': { opacity: 0 } }}>
        <Box sx={{ mb: 7, display: 'flex', alignItems: 'center' }}>
          <Typography variant='body2'>Gostaria de cadastrar um novo Encaminhamento?</Typography>
        </Box>
        <Button 
        fullWidth 
        variant='contained'
        sx={{ padding: theme => theme.spacing(1.75, 1.8), fontWeight: 600, '&:hover': {
          color: '#ffffff',
        } }}
        component={Link}
        to="/Encaminhamento/Enviar"
        >
          Novo encaminhamento
        </Button>
      </CardContent>
    </Card>
  )
}

export default WeeklyOverviewEncaminhador
