import React, { useState, useEffect } from "react";
import { Card, CardContent, Typography, CardActions, Button, Grid, Dialog, DialogActions, DialogContent, DialogTitle, } from "@mui/material";
import { toast } from 'react-toastify';
import { useParams } from "react-router-dom";
import WarningComponent from "../../../components/warnig/warnig"
import LoadingComponent from "../../../components/loadingCircular/loadingComponent";
import Api from "../../../service/apiBase";
import { Container } from "react-bootstrap";
import useUser from "../../../useUser";
import Divider from '@mui/material/Divider';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { styled } from '@mui/material/styles'

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDateTimePicker } from '@mui/x-date-pickers/StaticDateTimePicker';
import { format } from 'date-fns';

import StripeComponent from "../../../components/stripe/stripe-component";
import PixCreatedComponent from "../../../components/pix/pix-created-component";
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import Tab from '@mui/material/Tab'
import TabPanel from '@mui/lab/TabPanel'

import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import { ptBR } from '@mui/x-date-pickers/locales';

dayjs.locale('pt-br');

const Img = styled('img')(({ theme }) => ({
  marginBottom: theme.spacing(10),
  [theme.breakpoints.down('lg')]: {
    height: 300,
    marginTop: theme.spacing(1)
  },
  [theme.breakpoints.down('md')]: {
    height: 200
  },
  [theme.breakpoints.up('lg')]: {
    marginTop: theme.spacing(1)
  }
}))

function ForwardingScheduleComponent() {
    const [arrayData, setArrayData] = useState([]);
    const [loading, setLoading] = useState(true);
    const { id } = useParams(); 
    const [reload, setReload] = useState(false);
    const [service, setService] = useState([]);
    const [selectedDate, setSelectedDate] = useState(null);
    const { user } = useUser();

    const [open, setOpen] = useState(false);
    const [confirmFunction, setConfirmFunction] = useState(null);
    const [value, setValue] = useState('1')

    const handleOpen = (func) => {
      setConfirmFunction(() => func); // Define a função de confirmação
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
      setConfirmFunction(null); // Limpa a função de confirmação
    };

    const handleConfirm = () => {
      if (confirmFunction) {
        confirmFunction(); // Executa a função de confirmação
      }
      handleClose(); // Fecha o modal
    };
  

    const handleDate = (newDate) => {
        setSelectedDate(newDate);
        // setSelectedDate(new Date("2024-03-20 00:07:40"));
    };
  
    useEffect(() => {
        Api.getSendForwarding(id)
        .then(data => {
          if(data.length === undefined ) {
            setArrayData([data?.encaminhamento])
          }else {
            setArrayData(data?.encaminhamento)
          }
            
            const groupedServices = {};
            data?.encaminhamento?.servicos.forEach((servico) => {
                const categoriaNome = servico?.infoServico?.infoCategoria?.nome;
                if (!groupedServices[categoriaNome]) {
                    groupedServices[categoriaNome] = [];
                }
                groupedServices[categoriaNome].push(servico);
            });
            if(data?.encaminhamento?.data_hora_agendamento !== null) {
              setSelectedDate(data?.encaminhamento?.data_hora_agendamento) 
            }
            setService(groupedServices);
            setLoading(false);
            setReload(false);
        })
        .catch(error => {
          console.error('Erro ao buscar:', error);
        });
    }, [reload]);

    const formatarCPF = (str) => {
        const digitsOnly = str.replace(/\D/g, '');
    
        if (digitsOnly.length !== 11) {
            throw new Error('CPF deve conter 11 dígitos.');
        }
  
        return `${digitsOnly.slice(0, 3)}.${digitsOnly.slice(3, 6)}.${digitsOnly.slice(6, 9)}-${digitsOnly.slice(9)}`;
    }

    const formatarDataHoraBrasileiro = (dataHoraString) => {
      const dataHora = new Date(dataHoraString);
      const dia = dataHora.getDate().toString().padStart(2, '0');
      const mes = (dataHora.getMonth() + 1).toString().padStart(2, '0');
      const ano = dataHora.getFullYear();
      const hora = dataHora.getHours().toString().padStart(2, '0');
      const minutos = dataHora.getMinutes().toString().padStart(2, '0');
    
      return `${dia}/${mes}/${ano} ${hora}:${minutos}`;
    }

    const formatarData = (dataHoraString) => {
      const dataHora = new Date(dataHoraString);
      const dia = dataHora.getDate().toString().padStart(2, '0');
      const mes = (dataHora.getMonth() + 1).toString().padStart(2, '0');
      const ano = dataHora.getFullYear();

      return `${dia}/${mes}/${ano}`;
    }

    const formatarHora = (dataHoraString) => {
      const dataHora = new Date(dataHoraString);
      const hora = dataHora.getHours().toString().padStart(2, '0');
      const minutos = dataHora.getMinutes().toString().padStart(2, '0');
    
      return `${hora}:${minutos}`;
    }

    const formatPhoneNumber = (phoneNumber) => {
        // Remove todos os caracteres não numéricos
        const digitsOnly = phoneNumber.replace(/\D/g, '');
      
        // Verifica se o número de telefone tem a quantidade correta de dígitos
        if (digitsOnly.length < 10 || digitsOnly.length > 11) {
          throw new Error('Número de telefone inválido.');
        }
      
        // Formata o número de telefone no formato com código de área
        if (digitsOnly.length === 10) {
          return `(${digitsOnly.slice(0, 2)}) ${digitsOnly.slice(2, 6)}-${digitsOnly.slice(6)}`;
        } else {
          return `(${digitsOnly.slice(0, 2)}) ${digitsOnly.slice(2, 3)} ${digitsOnly.slice(3, 7)}-${digitsOnly.slice(7)}`;
        }
    };

    const handleSchedule = () => {

      let dataFormatada = '';
      let horaFormatada = '';
      let dataHora = '';

      if (selectedDate !== null) {
        const dateObj = new Date(selectedDate);
        dataFormatada = format(dateObj, 'yyyy-MM-dd');
        horaFormatada = format(dateObj, 'HH:mm:ss');

        dataHora = `${dataFormatada} ${horaFormatada}`;
      }
      setLoading(true);
      Api.updateCreateEncaminhamento(id, {data_hora_agendamento: dataHora, status_encaminhamento: 3})
      .then((res) => {
        if(res.error) {
          toast.error(res.error)
        }else {
          toast.success("Agendado com sucesso sucesso!");
        }
        
        setReload(true);
      })
      .catch((e) => {
        toast.error(`Erro: ${e.request.status}, Não foi possível, tente novamente mais tarde!`);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });

    }

    const formatarNumeroTelefone = (telefone) => {
      return '+55' + telefone.replace(/\D/g, '');
  }

    const gerarLinkWhatsapp = (localConsulta, infoCliente) => {
      const baseUrl = 'https://exames.bsltech.com.br/qrcode/';
      const params = `Encaminhamento/Receber/${id}`;
      const url = `${baseUrl}${params}`;
      const mensagem = `Olá! Sua consulta está agendada para o dia ${formatarData(selectedDate)} às ${formatarHora(selectedDate)}.\n Endereço: ${localConsulta}.\n Por favor, apresente este QR Code no momento do atendimento.`;
      const numero = formatarNumeroTelefone(infoCliente?.telefone);
      return `https://wa.me/${numero}?text=${encodeURIComponent(mensagem + '\n' + '\n' + url)}`;
    }

    const gerarLinkWhatsappDireto = (localConsulta, infoCliente) => {
      const baseUrl = 'https://exames.bsltech.com.br/qrcode/';
      const params = `Encaminhamento/Receber/${id}`;
      const url = `${baseUrl}${params}`;
      const mensagem = `Olá! Uma consulta foi agendada para você. Por favor, dirija-se ao seguinte endereço: ${localConsulta}. Não se esqueça de apresentar este QR Code no momento do atendimento. Obrigado!`;
      const numero = formatarNumeroTelefone(infoCliente?.telefone);
      return `https://wa.me/${numero}?text=${encodeURIComponent(mensagem + '\n' + '\n' + url)}`;
    }
    
    const handleWhatsapp = (endereco, infoCliente, status) => {
      if(status === 1) {
        window.open(gerarLinkWhatsapp(endereco, infoCliente));
      }else {
        window.open(gerarLinkWhatsappDireto(endereco, infoCliente));
      }
    }

    const handleRealizado = () => {
      setLoading(true);
      Api.updateCreateEncaminhamento(id, { status_encaminhamento: 5})
      .then((res) => {
        if(res.error) {
          toast.error(res.error)
        }else {
          toast.success("Exame finalizado com sucesso!");
        }
        setReload(true);
      })
      .catch((e) => {
        toast.error(`Erro: ${e.request.status}, Não foi possível, tente novamente mais tarde!`);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
    }

    const handlePago = () => {
      setLoading(true);
      Api.updateCreateEncaminhamento(id, {status_pagamento: "PAGO", status_encaminhamento: 4})
      .then((res) => {
        if(res.error) {
          toast.error(res.error)
        }else {
          toast.success("Pagamento realizado e encaminhamento confirmado!");
        }
        setReload(true);
      })
      .catch((e) => {
        toast.error(`Erro: ${e.request.status}, Não foi possível, tente novamente mais tarde!`);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
    }

    const handlePagoParceiro = () => {
      setLoading(true);
      Api.updateCreateEncaminhamento(id, {status_pagamento: "PAGO"})
        .then((res) => {
          if(res.error) {
            toast.error(res.error)
          }else {
            toast.success("Pagamento realizado!");
          }
          setReload(true);
        })
        .catch((e) => {
          toast.error(`Erro: ${e.request.status}, Não foi possível, tente novamente mais tarde!`);
          setLoading(false);
        })
        .finally(() => {
          setLoading(false);
        });
    }

    const handlePagoConfirmar = () => {
      setLoading(true);
      Api.updateCreateEncaminhamento(id, {status_encaminhamento: 4})
      .then((res) => {
        if(res.error) {
          toast.error(res.error)
        }else {
          toast.success("Encaminhamento confirmado!");
        }
        setReload(true);
      })
      .catch((e) => {
        toast.error(`Erro: ${e.request.status}, Não foi possível, tente novamente mais tarde!`);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
    }

    const handleChange = (event, newValue) => {
      setValue(newValue)
    }
    
    return (
      <>
        <Container className="p-0 mb-6">
            <div className="row container-units mb-4">
              <div className="col-12">
                <span>Home {' > '} Encaminhamento Recebidos</span>
              </div>
              <div className="col-12">
                <div className="row">
                  <div className="col-md-12">
                    <h1>Detalhe do Encaminhamento</h1>
                  </div>
                </div>
              </div>
            </div> 
            {
              loading ?
                <LoadingComponent/>
              :
                arrayData.length > 0 ? (
                  arrayData.map(data => (
                    <Card className="partner-card mb-3" key={data.id}>
                      <CardContent>
                        <Typography variant="h6" component="div" className="mb-2 d-flex justify-content-end align-items-center">
                            Código: {data.id}
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item sm={12} xs={12}>
                                <div className="d-flex justify-content-center flex-column align-items-center">
                                    <Typography variant="h6" component="div" className="mb-2 typography-encaminhamento">
                                        Encaminhamento
                                    </Typography>
                                    <Divider variant="middle" 
                                        sx={{
                                            m: 0,
                                            width: '100%',
                                            lineHeight: 'normal',
                                            textTransform: 'uppercase',
                                            '&:before, &:after': { top: 7, transform: 'none' },
                                            '& .MuiDivider-wrapper': { px: 2.5, fontSize: '0.75rem', letterSpacing: '0px' },
                                            marginBottom: '10px',
                                            marginTop: '10px',
                                            borderWidth: '1px',
                                          }}
                                    />
                                </div>
                                <Container className="pl-3 pr-3">
                                    <Typography variant="h6" component="div">
                                        Cliente:
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" className="ml-3">
                                       Nome: {data?.info_cliente?.nome}
                                    </Typography>
                                  
                                    <Typography variant="body2" color="text.secondary" className="ml-3">
                                      Telefone: {formatPhoneNumber(data?.info_cliente?.telefone)}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" className="ml-3">
                                       CPF: {formatarCPF(data?.info_cliente?.cpf)}
                                    </Typography>
                                    <Typography variant="body2"color="text.secondary" className="ml-3">
                                      Email: {data?.info_cliente?.email} 
                                    </Typography>

                                    <Typography variant="h6" component="div" className="mt-2">
                                        Origem:
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" className="ml-3">
                                       Nome: {data?.info_encaminhador?.nome}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" className="ml-3">
                                       CPF: {formatarCPF(data?.info_encaminhador?.cpf)}
                                    </Typography>
                                    <Typography variant="body2"color="text.secondary" className="ml-3">
                                      Email: {data?.info_encaminhador?.email} 
                                    </Typography>

                                    <Typography variant="h6" component="div" className="mt-2">
                                        Destino:
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" className="ml-3">
                                       Nome: {data?.info_unidade_recebe?.nome}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" className="ml-3">
                                       Endereço: {data?.info_unidade_recebe?.endereco}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" className="ml-3">
                                      Telefone: {formatPhoneNumber(data?.info_unidade_recebe?.telefone)}
                                    </Typography>
                                    
                                    <Typography variant="h6" component="div" className="mt-2">
                                        Serviços solicitados:
                                    </Typography>

                                    {Object.keys(service).map((categoriaNome) => (
                                        <div key={categoriaNome}>
                                            <Typography variant="body1" color="text.primary" className="ml-3 mb-1">
                                            <AssignmentIcon />  {categoriaNome}:
                                            </Typography>
                                            {service[categoriaNome].map((servico) => (
                                            <Typography key={servico.id} variant="body2" color="text.secondary" className="ml-4 mb-1">
                                                {servico.infoServico.nome} - R$ {Number(servico.infoServico.valor).toFixed(2).replace('.', ',')}
                                            </Typography>
                                            ))}
                                        </div>
                                    ))}
                                    <Typography variant="body1" color="text.secondary" className="mt-3 d-flex justify-content-end align-items-center">
                                        <strong className="mr-1">Valor:</strong> R$ {data?.valorTotalEncaminhamento?.toFixed(2).replace('.', ',')}
                                    </Typography>
                                </Container>
                                <Divider variant="middle" 
                                        sx={{
                                            m: 0,
                                            width: '100%',
                                            lineHeight: 'normal',
                                            textTransform: 'uppercase',
                                            '&:before, &:after': { top: 7, transform: 'none' },
                                            '& .MuiDivider-wrapper': { px: 2.5, fontSize: '0.75rem', letterSpacing: '0px' },
                                            marginBottom: '10px',
                                            marginTop: '10px',
                                            borderWidth: '1px',
                                          }}
                                    />
                            </Grid>
                        </Grid>
                      </CardContent>

                      <TabContext value={value}>
                            <TabList centered onChange={handleChange} aria-label='card navigation example'>
                                <Tab value='1' label='Cartão' />
                                <Tab value='2' label='Pix' />
                            </TabList>
                            <CardContent sx={{ textAlign: 'center' }}>
                                <TabPanel value='1' sx={{ p: 0 }}>
                                    <StripeComponent
                                    id={id}
                                    ></StripeComponent>
                                </TabPanel>
                                <TabPanel value='2' sx={{ p: 0 }}>
                                    <PixCreatedComponent
                                        idEncaminhamento={id}
                                        taxId={data?.txId}
                                    ></PixCreatedComponent>
                                </TabPanel>
                            </CardContent>
                          </TabContext>

                      {
                        (data.status_encaminhamento === 2 || data.status_encaminhamento === "2") || data.status_encaminhamento === 3 || data.status_encaminhamento === "3" ?
                        <CardContent>
                            <section className="d-flex justify-content-center align-items-center flex-column">
                                <Typography variant='h5'  sx={{ mb: 4, fontSize: '20px !important', fontFamily: 'Montserrat' }}>
                                  Agendar Exame
                                </Typography> 
                                {formatarDataHoraBrasileiro(selectedDate)}
                                <LocalizationProvider 
                                dateAdapter={AdapterDayjs} 
                                locale='pt-br'
                                
                                >
                                    <StaticDateTimePicker
                                        className='mt-2'
                                        defaultValue={dayjs(selectedDate)}
                                        onChange={(newDate) => handleDate(newDate)}
                                        ampm={false}
                                        loading={loading}
                                        localeText={ptBR.components.MuiLocalizationProvider.defaultProps.localeText}
                                        disablePast={true}
                                        hideTabs={true}
                                        actionBar={true}
                                    />
                                </LocalizationProvider>

                                
                            </section>
                            {
                              (data.status_encaminhamento === 3 || data.status_encaminhamento === "3") && data.data_hora_agendamento !== null 
                              ? 
                                <Button variant='contained'
                                  type="submit"
                                  className='w-100'
                                  onClick={() => handleOpen(handleSchedule)}
                              >
                                  Atualizar Agendamento</Button> 
                                : null
                            }
                            {
                              (data.status_encaminhamento === 2 || data.status_encaminhamento === "2") 
                              ? 

                                <Button variant='contained'
                                    type="submit"
                                    className='w-100'
                                    onClick={() => handleSchedule(data?.info_cliente?.id)}
                                >
                                    Realizar Agendamento</Button> 
                              : null
                            }
                          
                        </CardContent>
                        : null
                      }                

                    
                      <CardContent>
                        {
                          (data.status_encaminhamento === 3 || data.status_encaminhamento === "3")  ? 
                             <>
                              <section className="d-flex justify-content-start align-items-start flex-column">
                                <Divider
                                  textAlign='left'
                                  sx={{
                                    m: 0,
                                    width: '100%',
                                    lineHeight: 'normal',
                                    textTransform: 'uppercase',
                                    '&:before, &:after': { top: 7, transform: 'none' },
                                    '& .MuiDivider-wrapper': { px: 2.5, fontSize: '0.75rem', letterSpacing: '0.21px' }
                                  }}
                                >
                                </Divider>
                                  <Typography variant='h6' sx={{ marginBottom: 2, marginTop: 4 }}>
                                    Agendado
                                  </Typography>
                                  <Typography variant='body1' sx={{ marginBottom: 4 }}>
                                    Deseja enviar uma mensagem para o WhatsApp do cliente?
                                  </Typography>
                                <Button variant='contained'
                                  className="w-100"
                                  onClick={() => handleWhatsapp(data?.info_unidade_recebe?.endereco, data?.info_cliente, data?.is_agendado)}
                                > Enviar Mensagem</Button>
                              </section>

                              {
                                data.tipo_pagamento === "A PAGAR CLIENTE" ?
                                <section className="d-flex justify-content-start align-items-start flex-column">
                                    <Divider
                                        textAlign='left'
                                        sx={{
                                          m: 0,
                                          width: '100%',
                                          lineHeight: 'normal',
                                          textTransform: 'uppercase',
                                          '&:before, &:after': { top: 7, transform: 'none' },
                                          '& .MuiDivider-wrapper': { px: 2.5, fontSize: '0.75rem', letterSpacing: '0.21px' }
                                        }}
                                      >
                                      </Divider>
                                        <Typography variant='h6' sx={{ marginBottom: 2, marginTop: 4 }}>
                                          Marcar como Pago / Confirmar Agendamento
                                        </Typography>
                                        <Typography variant='body1' sx={{ marginBottom: 4 }}>
                                        Esta opção permite tanto marcar um pedido como 'Pago' quanto confirmar um agendamento. Certifique-se de que todas as informações estão corretas antes de prosseguir.
                                        </Typography>
      
                                        <Button variant='contained'
                                            type="submit"
                                            className='w-100'
                                            onClick={() =>  handleOpen(handlePago)}
                                        > Confirmar </Button>
                                </section> 
                                : 
                                <>
                                  
                                  {
                                    data.status_encaminhamento === "3" || data.status_encaminhamento === 3 ? 
                                    <section className="d-flex justify-content-start align-items-start flex-column">
                                      <Divider
                                          textAlign='left'
                                          sx={{
                                            m: 0,
                                            width: '100%',
                                            lineHeight: 'normal',
                                            textTransform: 'uppercase',
                                            '&:before, &:after': { top: 7, transform: 'none' },
                                            '& .MuiDivider-wrapper': { px: 2.5, fontSize: '0.75rem', letterSpacing: '0.21px' }
                                          }}
                                        >
                                        </Divider>
                                          <Typography variant='h6' sx={{ marginBottom: 2, marginTop: 4 }}>
                                            Confirmar Agendamento
                                          </Typography>
                                          <Typography variant='body1' sx={{ marginBottom: 4 }}>
                                          Certifique-se de que todas as informações estão corretas antes de prosseguir.
                                          </Typography>
        
                                          <Button variant='contained'
                                              type="submit"
                                              className='w-100'
                                              onClick={() => handleOpen(handlePagoConfirmar)}
                                          > Confirmar </Button>
                                    </section> 
                                    : 
                                    null
                                  }
                                </>
                              }
                             
                             </>                       
                            
                          : 
                          null
                        }

                        {data.status_pagamento !== "PAGO" && data.tipo_pagamento === "A PAGAR PARCEIRO"  ? 
                          <section className="d-flex justify-content-start align-items-start flex-column">
                            <Divider
                                textAlign='left'
                                sx={{
                                  m: 0,
                                  width: '100%',
                                  lineHeight: 'normal',
                                  textTransform: 'uppercase',
                                  '&:before, &:after': { top: 7, transform: 'none' },
                                  '& .MuiDivider-wrapper': { px: 2.5, fontSize: '0.75rem', letterSpacing: '0.21px' }
                                }}
                              >
                              </Divider>
                                <Typography variant='h6' sx={{ marginBottom: 2, marginTop: 4 }}>
                                  Marcar como Pago
                                </Typography>
                                <Typography variant='body1' sx={{ marginBottom: 4 }}>
                                Esta opção permite tanto marcar um agendamento como 'Pago'. Certifique-se de que todas as informações estão corretas antes de prosseguir.
                                </Typography>

                                <Button variant='contained'
                                    type="submit"
                                    className='w-100'
                                    onClick={() => handleOpen(handlePagoParceiro)}
                                > Confirmar </Button>
                          </section> : null
                        }

                        {
                          (data.status_encaminhamento === 4 || data.status_encaminhamento === "4") ? 
                            <section className="d-flex justify-content-start align-items-start flex-column">
                              <Divider
                                  textAlign='left'
                                  sx={{
                                    m: 0,
                                    width: '100%',
                                    lineHeight: 'normal',
                                    textTransform: 'uppercase',
                                    '&:before, &:after': { top: 7, transform: 'none' },
                                    '& .MuiDivider-wrapper': { px: 2.5, fontSize: '0.75rem', letterSpacing: '0.21px' }
                                  }}
                                >
                                </Divider>
                                  <Typography variant='h6' sx={{ marginBottom: 2, marginTop: 4 }}>
                                    Marcar Exame como Realizada
                                  </Typography>
                                  <Typography variant='body1' sx={{ marginBottom: 4 }}>
                                    Essa opção permite marcar uma consulta como realizada. Ao selecionar esta opção, o sistema atualiza o status da consulta, indicando que ela foi concluída com sucesso. Certifique-se de que a consulta foi realizada antes de marcar como concluída.
                                  </Typography>
                              <Button variant='contained'
                                  type="submit"
                                  className='w-100'
                                  onClick={() => handleOpen(handleRealizado)}
                              > Realizado </Button>
                            </section> 
                            : null
                        }
                        {
                          (data.status_encaminhamento === 5 || data.status_encaminhamento === "5") && data.status_pagamento === "PAGO" ? 
                            <section className="d-flex justify-content-center align-items-center flex-column">
                              <Typography variant='h5'  sx={{ mb: 1, fontSize: '20px !important', fontFamily: 'Montserrat' }}>
                                Concluído
                              </Typography> 
                              <Img height='300' alt='error-illustration' src='/images/pages/Completed-pana.svg' />
                            </section> : null
                        }
                          
                      </CardContent>

                      <CardActions className="card-cartion-buttons mt-2">
                        
                        {/* <Button
                          size="small"
                          color="primary"
                          className="button-card-action"
                          onClick={() => handleRemoveEncaminhamento(data)}
                        >
                          Cancelar
                        </Button> */}
                        
                      </CardActions>
                    </Card>
                  ))
                )
              : 
                (
                  <>
                    <WarningComponent title="Nenhum cadastro"/>
                  </>
                )
            }

          <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Atenção!</DialogTitle>
            <DialogContent>
              <Typography variant="body1">Tem certeza de que deseja realizar esta ação?</Typography>
              <div className="d-flex justify-content-around align-items-center pt-3">
                <Button variant='contained' onClick={handleConfirm} autoFocus>Confirmar</Button>
                <Button variant="outlined" color="error" onClick={handleClose}>Cancelar</Button>
              </div>
            </DialogContent>
          </Dialog>
            
        </Container>

      </>
    );
}

export default ForwardingScheduleComponent;
