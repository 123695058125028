import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

const BoxWrapper = styled(Box)(({ theme }) => ({
    [theme.breakpoints.down('md')]: {
      width: '90vw'
    }
}))

const Img = styled('img')(({ theme }) => ({
    marginBottom: theme.spacing(10),
    [theme.breakpoints.down('lg')]: {
      height: 450,
      marginTop: theme.spacing(10)
    },
    [theme.breakpoints.down('md')]: {
      height: 400
    },
    [theme.breakpoints.up('lg')]: {
      marginTop: theme.spacing(10)
    }
  }))

const PaymentSuccess = () => {

  const navigate = useNavigate();
  const [countdown, setCountdown] = useState(8);


  useEffect(() => {
    const interval = setInterval(() => {
        setCountdown((prevCount) => {
            if (prevCount === 1) {
                clearInterval(interval);
                navigate('/Encaminhamento/Enviar');
            }
            return prevCount - 1;
        });
    }, 800);

    return () => clearInterval(interval);
}, [navigate]);

    return (
      <Box className='content-center'>
        <Box sx={{ p: 5, display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
          <BoxWrapper>
            <Typography variant='h1' sx={{fontFamily: 'Montserrat'}}>Parabéns 🎉</Typography>
            <Typography variant='h5' sx={{ mb: 1, fontSize: '1.5rem !important', fontFamily: 'Montserrat' }}>
              Seu pagamento foi registrado com sucesso
            </Typography>
            <Typography variant='body2'>Em breve, você será redirecionado para a página de encaminhamentos</Typography>
            <Typography variant='body2'>Redirecionando em {countdown} segundos...</Typography>
          </BoxWrapper>
          <Img height='487' alt='error-illustration' src='/images/pages/Wallet-bro.svg' />
        </Box>
      </Box>
    )
}

export default PaymentSuccess