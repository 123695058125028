import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { Button } from '@mui/material';
import { PaymentElement, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import './stripe-modal.css'
import LoadingComponent from '../loadingCircular/loadingComponent';
import { Container } from 'react-bootstrap';
import Api from '../../service/apiBase';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
const stripePromise = loadStripe('pk_test_51OVErXK4heB4OhYCIFsSykCsrlNfrN0JTzYKldZrHADBO9FZvudc6NuQ4p4PvrbhzbHePs1ZcSX4EaYGUnHIsg7t00fUQRKJhy');

const CheckoutForm = ({ clientSecret }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [paymentMethod, setPaymentMethod] = useState('credit_card');

    const handleChange = (event) => {
        
        setPaymentMethod(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!stripe || !elements) {
            console.error('Erro no pagamento');
            return;
        }

        const shippingDetails = {
            name: 'Nome do Destinatário',
            email: 'matheusasn2@gmail.com',
            phone: '+5511999999999',
            address: {
                city: 'Cidade',
                country: 'País',
                line1: 'Endereço Linha 1',
                line2: 'Endereço Linha 2',
                postal_code: 'CEP',
                state: 'Estado',
            },

        };

        await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: 'https://exames.bsltech.com.br/pagamento/realizado',
                payment_method_data: {
                    billing_details: shippingDetails,
                    type: paymentMethod,
                },
            },
        });


    };

    return (
        <form onSubmit={handleSubmit}>

        <FormControl fullWidth className='mb-3'>
            <InputLabel id="demo-simple-select-label">Selecione o Método de Pagamento:</InputLabel>
            <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={paymentMethod}
            label="Selecione o Método de Pagamento:"
            onChange={handleChange}
            >
                <MenuItem value={'credit_card'}>Cartão de Crédito</MenuItem>
                <MenuItem value={'sepa_debit'}>Cartão de Débito</MenuItem>
            </Select>
        </FormControl>

            <PaymentElement />

            {
                !stripe
                    ? <LoadingComponent />
                    : 
                    <Button variant='contained'
                        type="submit"
                        className='mt-4 w-100'
                        disabled={!stripe}
                    >Realizar Pagamento</Button>
            }
            
            {/* <Button
                size="large"
                className="button-card-action-update mt-4 w-100"
                type="submit"
                disabled={!stripe}
            >
                Realizar Pagamento
            </Button> */}
        </form>
    );
};

const StripeComponent = ({ id }) => {
    const [open, setOpen] = useState(false);
    const [clientSecret, setClientSecret] = useState('');
    const [loading, setLoading] = useState(true);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        Api.stripeClientSecret(id)
        .then(data => {
            setClientSecret(data.clientSecret)
            setTimeout(() => {
                setLoading(false);
            }, 600);
        })
        .catch(error => {
            console.error('Erro ao buscar:', error);
        });
    },[]);

    const options = {
        clientSecret: clientSecret,
    };

    return (
        <>
            <Container className='modal-stripe-container'>
                {!loading
                    ?
                    <Elements stripe={stripePromise} options={options}>
                        <CheckoutForm clientSecret={clientSecret} />
                    </Elements>
                    :
                    <LoadingComponent />
                }
            </Container>
        </>
    );
};

export default StripeComponent;
