import React, { useState, useEffect, ChangeEvent, FormEvent } from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import AddIcon from '@mui/icons-material/Add';
import DialogContent from '@mui/material/DialogContent';
import Form from 'react-bootstrap/Form';
import InputMask from 'react-input-mask';
import Api from "../../../service/apiBase";
import { toast } from 'react-toastify';
import { Category } from "../../../model/partner";
import { useParams } from "react-router-dom";

// import '../forms.css'

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface FormCreatedUserProps {
  reloadComponent: () => void;
  toUpdate: any | null;
  isOpen: boolean;
  idServicoUnidade: number | undefined;
  onClose: () => void;
}

const FormUpdate: React.FC<FormCreatedUserProps> = ({ reloadComponent, toUpdate, isOpen, onClose, idServicoUnidade }) => {
  const [formData, setFormData] = useState({
    id_categoria: '',
    nome: '',
  });
  const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState<Category[]>([]);

  const { id } = useParams(); 

  useEffect(() => {
    Api.getAllCategory()
      .then(data => {
        setCategory(data);
      })
      .catch(error => {
        console.error('Erro ao buscar parceiros:', error);
    });
  }, []);

  useEffect(() => {
    if (toUpdate) {
      setFormData(toUpdate);
    }
  }, [toUpdate]);

  const handleInputChangeForm = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  
    if (!formData.nome) {
      toast.error("Por favor, preencha todos os campos obrigatórios.");
      return;
    }
  
    setLoading(true);
    
    Api.updateCategory(idServicoUnidade, {nome: formData.nome})
      .then((res) => {
        if(res.error) {
          toast.error(res.error)
        }else {
          toast.success("Atualizado com sucesso");
          setFormData({
            id_categoria: '',
            nome: '',
          });
          onClose();
          reloadComponent();
        }
      })
      .catch((e) => {
        toast.error(`Erro: ${e.request.status}, Não foi possível, tente novamente mais tarde!`);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <React.Fragment>
      <Dialog
        fullScreen
        open={isOpen}
        onClose={onClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar className='bar-modal-dialog'>
            <IconButton
              edge="start"
              color="inherit"
              onClick={onClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1, color: '#ffffff' }} variant="h6" component="div">
              Atualizar Categoria
            </Typography>
          </Toolbar>
        </AppBar>

        <DialogContent className="container-dialog">
          <div className="containerForm">
            <Form onSubmit={handleSubmit}>

              <Form.Group className="mb-3">
                <Form.Label htmlFor="inputNome">Nome*</Form.Label>
                <Form.Control 
                  type="text" 
                  placeholder="Digite o nome" 
                  name="nome" 
                  id="inputNome"
                  value={formData.nome} 
                  onChange={handleInputChangeForm} 
                  className="form-control-value"
                />
              </Form.Group>


              <Button type="submit" className="button-submit-form" disabled={loading} disableElevation>
                {loading ? 'Atualizando...' : 'Atualizar'}
              </Button>
            </Form>
          </div>
        </DialogContent>

      </Dialog>
    </React.Fragment>
  );
}

export default FormUpdate;
