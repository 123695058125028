import React, {useEffect, useState, FormEvent, ChangeEvent} from 'react';
import Form from 'react-bootstrap/Form';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import AddIcon from '@mui/icons-material/Add';
import Api from '../../../service/apiBase';
import '../form.css'
import { Category } from '../../../model/partner';
import CancelIcon from '@mui/icons-material/Cancel';
import { toast } from 'react-toastify';

interface ServiceComponentProps {
  id: string | undefined;
  idUnidade: string | undefined;
  isOpen: boolean;
  onClose: () => void;
  reloadComponent: () => void;
}

export interface Service {
  id: number;
  id_categoria: string;
  id_unidade: string;
  nome: string;
  valor: string;
  complexidade: string;
  is_ativo: '0' | '1';
}

const FormNewServicePrestador: React.FC<ServiceComponentProps> = ({ id, idUnidade, isOpen, onClose, reloadComponent }) =>  {
  const [loading, setLoading] = useState(false);
  
  const [displayPrestador, setDisplayPrestador] = useState(false);
  const [display, setDisplay] = useState(false);
  
  const [service, setService] = useState<Service[]>([]);
  const [prestador, setPrestador] = useState<any[]>([]);
  const [formData, setFormData] = useState({
    id_categoria: '',
    id_prestador: '',
    id_servico: ''
  });

  useEffect(() => {
    if(formData.id_categoria !== '') {
      setDisplayPrestador(false);
      setService([]);
      setPrestador([]);
      setFormData({
        ...formData,
        id_prestador: ''
      });
      Promise.all([
        Api.prestadorPorTipo(formData.id_categoria)
      ])
        .then(([prestadorData]) => {
          if (!Array.isArray(prestadorData)) {
            prestadorData = [prestadorData];
          }
          setDisplayPrestador(true);
          setPrestador(prestadorData);
         
        })
        .catch(error => {
          console.error('Erro ao buscar dados:', error);
          setDisplayPrestador(false);
        });
    }
  }, [formData.id_categoria]);

  useEffect(() => {
    if(formData.id_prestador !== '') {
      setDisplay(false);
      setService([]);
      setFormData({
        ...formData,
        id_servico: ''
      });
      Promise.all([
        Api.getServicosByPrestador(formData.id_prestador)
      ])
        .then(([serviceData]) => {
          if (!Array.isArray(serviceData)) {
            serviceData = [serviceData];
          }
          setDisplay(true);
          setService(serviceData);
         
        })
        .catch(error => {
          console.error('Erro ao buscar dados:', error);
          setDisplay(false);
        });
    }
  }, [formData.id_prestador]);

  // aqui onde vou enviar tudo
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!formData.id_prestador || !formData.id_categoria || !formData.id_servico) {
      toast.error("Por favor, preencha todos os campos obrigatórios.");
      return;
    }

    Api.addServicePrestadorEncaminhamento(id, formData.id_servico)
      .then((res) => {
        if(res.error) {
          toast.error(res.error)
        }else {
          // toast.success("cadastrado realizado com sucesso");
          setFormData({
            id_categoria: '',
            id_prestador: '',
            id_servico: ''
          });
          onClose();
          reloadComponent();
        }
      })
      .catch((e) => {
        toast.error(`Erro: ${e.request.status}, Não foi possível, tente novamente mais tarde!`);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const handleSelectChangeCategory = (e: ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    setFormData({
      ...formData,
      id_categoria: value
    });
  };

  const handleSelectChangePrestador = (e: ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    setFormData({
      ...formData,
      id_prestador: value
    });
  };
  

  const handleSelectChangeService = (e: ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    setFormData({
      ...formData,
      id_servico: value
    });
  };
  
  return (
    <React.Fragment>
      
      <Dialog
        open={isOpen}
        onClose={onClose}
      >
        <DialogActions>
          <CancelIcon onClick={onClose} className='icon-close-stripe'></CancelIcon>
        </DialogActions>
        <DialogTitle sx={{ fontSize: '20px', fontWeight: '700' }} className='pt-0'>Adicione um serviço de cada vez:</DialogTitle>
        <DialogContent className="container-dialog pb-0">
        <div className="containerForm pt-0">
          <Form onSubmit={handleSubmit}>
            <section className='pb-5 mb-5'>
              <Form.Group className="mb-3 d-flex flex-column">
                <Form.Label htmlFor="selectParceiro">Categoria*</Form.Label>
                  <Form.Select 
                    id="selectParceiro" 
                    name="id_parceiro" 
                    value={formData.id_categoria} 
                    onChange={handleSelectChangeCategory}
                    disabled={false}
                    className="form-control-value form-control-value-select"
                  >
                    <option value="">Selecione uma tipo de serviço</option>
                    <option value={'4'}>Prestador de Serviço Alimentício</option>
                    <option value={'5'}>Prestador de Serviço de Transporte</option>
                  </Form.Select>
              </Form.Group>

              {
                displayPrestador && formData.id_categoria !== ''
                ?
                  <Form.Group className="mb-3 d-flex flex-column">
                    <Form.Label htmlFor="selectParceiro">Prestador*</Form.Label>
                      <Form.Select 
                        id="selectParceiro" 
                        name="id_parceiro" 
                        value={formData.id_prestador} 
                        onChange={handleSelectChangePrestador}
                        disabled={false}
                        className="form-control-value form-control-value-select"
                      >
                        <option value="">Selecione um prestador</option>
                        {prestador.map(data => {
                          return data.is_liberado === '1' ? (
                            <option key={data.id} value={data.id}>{data.nome}</option>
                          ) : null;
                        })}
                      </Form.Select>
                    </Form.Group>
                :
                  null
              }

              {
                display && formData.id_prestador !== ''
                ?
                  <Form.Group className="mb-3 d-flex flex-column">
                    <Form.Label htmlFor="selectParceiro">Serviço*</Form.Label>
                      <Form.Select 
                        id="selectParceiro" 
                        name="id_parceiro" 
                        value={formData.id_servico} 
                        onChange={handleSelectChangeService}
                        disabled={false}
                        className="form-control-value form-control-value-select"
                      >
                        <option value="">Selecione um prestador</option>
                        {service.map(data => {
                          return data.is_ativo === '1' ? (
                            <option key={data.id} value={data.id}>{data.nome}</option>
                          ) : null;
                        })}
                      </Form.Select>
                    </Form.Group>
                :
                  null
              }
            </section>

              

            {
              formData.id_categoria !== '' && formData.id_prestador !== '' && formData.id_servico !== '' ?
                <Button type="submit" className="button-submit-form-service" disabled={loading} disableElevation>
                  {loading ? 'Adicionando...' : 'Adicionar serviço'}
                </Button>
              : null
            }
          </Form>
        </div>
        </DialogContent>
        <DialogActions>
          {/* <Button type="submit">Subscribe</Button> */}
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default FormNewServicePrestador;