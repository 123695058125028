import React, { useState, useEffect, FormEvent, ChangeEvent } from "react";
import { Card, CardContent, Typography, CardActions, Tooltip, Button, Grid, styled } from "@mui/material";
import { toast } from 'react-toastify';
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import WarningComponent from "../../../components/warnig/warnig"
import LoadingComponent from "../../../components/loadingCircular/loadingComponent";
import Api from "../../../service/apiBase";
import { Container } from "react-bootstrap";
import { Client } from "../../../model/partner";
import FormCreatedForwarding from "../../../components/forms/Forwarding/form-created";
import FormUpdateClient from "../../../components/forms/Client/form-update";
import useUser from "../../../useUser";
import StripeModal from "../../../components/stripe/stripe-modal";
import PixComponent from "../../../components/pix/pix-component";
import FormCreatedClient from "../../../components/forms/Client/form-created";

const TriangleImg = styled('img')({
  right: 0,
  bottom: 0,
  height: 170,
  position: 'absolute'
})

function ForwardingComponent() {
    const [arrayData, setArrayData] = useState<any[]>([]);
    const [loading, setLoading] = useState(true);
    const [selectedValues, setSelectedValues] = useState<any | null>(null);
    const [updateModalOpen, setUpdateModalOpen] = useState(false);
    const [updateModalOpenPix, setUpdateModalOpenPix] = useState(false);
    const [taxId, setTaxId] = useState('');
    const { id } = useParams(); 
    const [reload, setReload] = useState(false);
    const { user } = useUser();
    const [isModalOpen, setIsModalOpen] = useState(false);

    
  
    const handleReload = () => {
      setReload(!reload);
    };
  
    const handleUpdate = (values: any) => {
        setSelectedValues(values);
        setUpdateModalOpen(true);
    };

    const openModalPix = (taxId: string) => {
      setUpdateModalOpenPix(true);
      setTaxId(taxId)
    };
  
    useEffect(() => {
        Api.getAllSendForwarding(user?.id)
        .then(data => {
          if(data.length === undefined ) {
            setArrayData([data])
          }else {
            setArrayData(data)
          }
          setLoading(false);
        })
        .catch(error => {
          console.error('Erro ao buscar:', error);
        });
    }, [reload]);
  
  
    const handleActivateDeactivate = (data: any) => {
      const newStatus = data.deleted === 1 ? 0 : 1;
      Api.updateClientActivateDesactivate(data.id)
        .then((response) => {
          if (response.message === 'Usuario Deletado com Sucesso') {
            toast.success(`${newStatus === 0 ? 'Liberado' : 'Bloqueado'} com sucesso`);
            setArrayData(prev => prev.map(p => p.id === data.id ? { ...p, deleted: newStatus } : p));
          } else {
            toast.error(`Não foi possível, tente novamente mais tarde!`);
          }
        })
        .catch((e) => {
          toast.error(`Erro: ${e.request.status}, Não foi possível, tente novamente mais tarde!`);
        });
    };

    const handleRemoveEncaminhamento = (data: any) => {
      setLoading(true);
      Api.deleteEncaminhamento(data.id)
        .then((response) => {
          if (response.message === 'Encaminhamento removido com sucesso') {
            toast.success(`Encaminhamento removido com sucesso`);
            handleReload();
            setLoading(false);

          } else {
            toast.error(`Não foi possível, tente novamente mais tarde!`);
            setLoading(false);
          }
        })
        .catch((e) => {
          toast.error(`Erro: ${e.request.status}, Não foi possível, tente novamente mais tarde!`);
          setLoading(false);
        });
    };

    const formatarCPF = (str: string) => {
        // Remove todos os caracteres não numéricos
        const digitsOnly = str.replace(/\D/g, '');
    
        // Verifica se o CPF tem a quantidade correta de dígitos
        if (digitsOnly.length !== 11) {
            throw new Error('CPF deve conter 11 dígitos.');
        }
    
        // Formata o CPF no formato com pontos e traço
        return `${digitsOnly.slice(0, 3)}.${digitsOnly.slice(3, 6)}.${digitsOnly.slice(6, 9)}-${digitsOnly.slice(9)}`;
    }

    const formatarDataHoraBrasileiro = (dataHoraString: string) => {
      const dataHora = new Date(dataHoraString);
      const dia = dataHora.getDate().toString().padStart(2, '0');
      const mes = (dataHora.getMonth() + 1).toString().padStart(2, '0');
      const ano = dataHora.getFullYear();
      const hora = dataHora.getHours().toString().padStart(2, '0');
      const minutos = dataHora.getMinutes().toString().padStart(2, '0');
    
      return `${dia}/${mes}/${ano} ${hora}:${minutos}`;
    }

    const formatPhoneNumber = (phoneNumber: string) => {
        // Remove todos os caracteres não numéricos
        const digitsOnly = phoneNumber.replace(/\D/g, '');
      
        // Verifica se o número de telefone tem a quantidade correta de dígitos
        if (digitsOnly.length < 10 || digitsOnly.length > 11) {
          throw new Error('Número de telefone inválido.');
        }
      
        // Formata o número de telefone no formato com código de área
        if (digitsOnly.length === 10) {
          return `(${digitsOnly.slice(0, 2)}) ${digitsOnly.slice(2, 6)}-${digitsOnly.slice(6)}`;
        } else {
          return `(${digitsOnly.slice(0, 2)}) ${digitsOnly.slice(2, 3)} ${digitsOnly.slice(3, 7)}-${digitsOnly.slice(7)}`;
        }
    };

    const openModal = () => {
      setIsModalOpen(true);
    };
  
    const closeModal = () => {
      setIsModalOpen(false);
    };

    const handleReloadStripe = () => {
      // setReload(!reload);
    };

    const statusObjLabel = [
      { label: 'Encaminhamento Aberto' },
      { label: 'Aguardando Agendamento' },
      { label: 'Encaminhamento Agendado' },
      { label: 'Encaminhado Confirmado' },
      { label: 'Encaminhamento Finalizado' },
    ]
      
  
    return (
      <>
        <Container className="p-0 mb-6">
            <div className="row container-units mb-4">
              <div className="col-12">
                <span>Home</span>
              </div>
              <div className="col-12">
                <div className="row">
                  <div className="col-md-12">
                    <h1>Encaminhamento enviados</h1>
                  </div>
                </div>
              </div>
            </div> 
            {
              loading ?
                <LoadingComponent/>
              :
                arrayData.length > 0 ? (
                  arrayData.map(data => (
                    <Card className="partner-card mb-3" key={data.id}>
                      <CardContent>
                          <Typography variant="h6" component="div" className="mb-2 d-flex justify-content-end align-items-center">
                            Código: {data.id}
                          </Typography>
                          <Grid container spacing={2}>
                          <Grid item sm={6} xs={12}>
                            <Typography variant="h6" component="div" className="mb-2">
                              Detalhes do Encaminhamento:
                            </Typography>
                            <Typography variant="body1" color="text.secondary">
                              <strong>Status:</strong> {statusObjLabel[data.status_encaminhamento-1].label}
                            </Typography> 
                            <Typography variant="body1" color="text.secondary">
                              <strong>Unidade:</strong> {data?.infoUnidade_recebe?.nome}
                            </Typography>
                            <Typography variant="body1" color="text.secondary">
                              <strong>Cliente:</strong> {data?.dados_cliente?.nome}
                            </Typography>
                          </Grid>
                          <Grid item sm={6} xs={12}>
                            <Typography variant="h6" component="div" className="mb-2">
                              Dados do Pagamento:
                            </Typography>
                            
                            {
                              data.tipo_pagamento === 'A PAGAR CLIENTE' ?
                              <Typography variant="body1" color="text.secondary">
                                <strong>Pagamento:</strong> Cobrar do cliente
                              </Typography> : data.tipo_pagamento === 'PAGO' ?
                               <Typography variant="body1" color="text.secondary">
                                <strong>Pagamento:</strong> Cobrar do Parceiro
                              </Typography> : <Typography variant="body1" color="text.secondary">
                                <strong>Pagamento:</strong> {data?.status_pagamento}
                              </Typography>
                            }
                            <Typography variant="body1" color="text.secondary">
                              <strong>Valor:</strong> R$ {data?.valorTotal.toFixed(2)}
                            </Typography>
                          </Grid>
                        </Grid>
                        
                        <TriangleImg alt='triangle background' src={`/images/misc/triangle-light.png`} />
                      </CardContent>
                      <CardActions className="card-cartion-buttons">
                        {/* <Button
                          size="small"
                          color="primary"
                          className="button-card-action"
                          onClick={() => handleActivateDeactivate(data)}
                        >
                          {data.deleted === 1 ? 'Habilitar' : 'Desabilitar'}
                        </Button>
                        {
                          data.deleted === 1 ? null :
                          <>
                            
  
                            <Button
                              size="small"
                              className="button-card-action-update"
                              component={Link}
                              to={`/Usuarios/${data.id}`}
                            >
                              Usuários
                            </Button>
                          </>
                          
                        } */}

                        <Button
                          size="small"
                          color="primary"
                          className="button-card-action"
                          onClick={() => handleRemoveEncaminhamento(data)}
                        >
                          Remover
                        </Button>

                        <Button
                          size="small"
                          className="button-card-action-update"
                          component={Link}
                          to={`/Encaminhamento/Enviar/${data.id}`}
                          // onClick={() => handleUpdate(data)}
                        >
                          Visualizar
                        </Button>

                        {/* {
                        data?.tipo_pagamento !== null ?
                          data.tipo_pagamento === 'PIX' 
                            ?
                              <>
                                <Button
                                    size="small"
                                    className="button-card-action-update"
                                    onClick={() => openModalPix(data.txId)}
                                    >
                                    Pagar
                                </Button>
                              </>
                            :
                              <StripeModal 
                                reloadComponent={handleReloadStripe}
                                id={data.id}
                              /> 
                          : null
                        }
                         */}
                      </CardActions>
                    </Card>
                  ))
                )
              : 
                (
                  <>
                    <WarningComponent title="Nenhum cadastro"/>
                  </>
                )
            }
            
        </Container>

        <PixComponent
          isOpen={updateModalOpenPix}
          onClose={() => setUpdateModalOpenPix(false)}
          taxId={taxId}
        />
        <FormCreatedForwarding 
          reloadComponent={handleReload}
          id={selectedValues?.id}
        />
        <FormUpdateClient
          reloadComponent={handleReload}
          toUpdate={selectedValues}
          id={selectedValues?.id}
          isOpen={updateModalOpen}
          onClose={() => setUpdateModalOpen(false)}
        />
      </>
    );
}

export default ForwardingComponent;

// Coloca só cliente e coisas da unidade
// Atualizar o encaminhamento para atualizar as informacoes do encaminhamento
