import { useState } from 'react'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import Alert from '@mui/material/Alert'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import InputLabel from '@mui/material/InputLabel'
import AlertTitle from '@mui/material/AlertTitle'
import IconButton from '@mui/material/IconButton'
import CardContent from '@mui/material/CardContent'
import FormControl from '@mui/material/FormControl'
import Button from '@mui/material/Button'
import useUser, { updateUser } from '../../../useUser'
import Close from 'mdi-material-ui/Close'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputMask from 'react-input-mask';
import { toast } from 'react-toastify';
import Api from '../../../service/apiBase'

const TabAccount = () => {
  const [openAlert, setOpenAlert] = useState(false)
  const { user } = useUser()
  const [userData, setUserData] = useState({
    nome: user.nome,
    email: user.email,
    tipo: user.tipo,
    cpf: user.cpf,
    is_liberado: user.is_liberado
  })
  const [loading, setLoading] = useState(false);

  const handleChange = (key, value) => {
    setUserData({
      ...userData,
      [key]: value
    })
  }

  const removeFormatarCPF = (str) => {
    const digitsOnly = str.replace(/\D/g, '');

    return digitsOnly;
  }

  const handleSaveChanges = () => {
    if (!userData.nome || !userData.email || !userData.tipo || !userData.is_liberado || !userData.cpf ) {
      toast.error("Por favor, preencha todos os campos obrigatórios.");
      return;
    }

    setLoading(true);
    Api.updateUser(user.id, {cpf: removeFormatarCPF(userData.cpf), email: userData.email, nome: userData.nome, tipo: userData.tipo })
        .then((res) => {
          if(res.error) {
            toast.error(res.error)
          }else {
            toast.success("Atualizado com sucesso");
            try {
              localStorage.removeItem('token');
              document.location.reload();
            } catch (err) {
                console.log("Could not handle logout", err)
            }
          }
        })
      .catch((e) => {
        toast.error(`Erro: ${e.request.status}, Não foi possível, tente novamente mais tarde!`);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const handleReset = () => {
    setUserData({
      nome: user.nome,
      email: user.email,
      tipo: user.tipo,
      is_liberado: user.is_liberado
    })
  }

  return (
    <CardContent>
      <form>
        <Grid container spacing={7}>
          <Grid item xs={12} sm={12} sx={{ marginTop: 4.8 }}>
            <TextField fullWidth label='Nome de Usuário' value={userData.nome} onChange={(e) => handleChange('nome', e.target.value)} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField fullWidth type='email' label='Email' value={userData.email} onChange={(e) => handleChange('email', e.target.value)} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel>Função</InputLabel>
              <Select label='Função' value={userData.tipo} onChange={(e) => handleChange('tipo', e.target.value)} disabled={userData?.tipo !== '1'}>
                <MenuItem value='1'>Administrador</MenuItem>
                <MenuItem value='2'>Parceiro</MenuItem>
                <MenuItem value='3'>Atendente</MenuItem>
                <MenuItem value='4'>Prestador de Serviço Alimentício</MenuItem>
                <MenuItem value='5'>Prestador de Serviço de Transporte</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel>Status</InputLabel>
              <Select label='Status' value={userData.is_liberado === '1' ? 'active' : 'inactive'} onChange={(e) => handleChange('is_liberado', e.target.value === 'active' ? '1' : '0')}>
                <MenuItem value='active'>Ativo</MenuItem>
                <MenuItem value='inactive'>Inativo</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth sx={{ marginBottom: 4 }}>
                <InputLabel htmlFor='cpf'>CPF</InputLabel>
                <InputMask mask="999.999.999-99" value={userData.cpf} defaultValue={user.cpf} onChange={(e) => handleChange('cpf', e.target.value)}>
                    {(inputProps) => (
                    <OutlinedInput
                        {...inputProps}
                        label='CPF'
                        id='cpf'
                        type={'text'}
                    />
                    )}
                </InputMask>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField fullWidth label='Empresa' placeholder='BSL' defaultValue='BSL' disabled={true}/>
          </Grid>

          {openAlert ? (
            <Grid item xs={12} sx={{ mb: 3 }}>
              <Alert
                severity='warning'
                sx={{ '& a': { fontWeight: 400 } }}
                action={
                  <IconButton size='small' color='inherit' aria-label='close' onClick={() => setOpenAlert(false)}>
                    <Close fontSize='inherit' />
                  </IconButton>
                }
              >
                <AlertTitle>Seu email não foi confirmado. Por favor, verifique sua caixa de entrada.</AlertTitle>
                <Link href='/' onClick={e => e.preventDefault()}>
                  Reenviar Confirmação
                </Link>
              </Alert>
            </Grid>
          ) : null}

          <Grid item xs={12}>
            <Button variant='contained' sx={{ marginRight: 3.5, marginTop: 3.5 }} onClick={handleSaveChanges}>
              {loading ? 'Salvando...' : 'Salvar Alterações'}
            </Button>
            <Button type='reset' variant='outlined' color='secondary' onClick={handleReset}  sx={{ marginTop: 3.5 }}>
              Resetar
            </Button>
          </Grid>
        </Grid>
      </form>
    </CardContent>
  )
}

export default TabAccount
