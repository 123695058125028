import { useState } from "react";
import { User } from "./model/useUser"

export default function useUser() {
  const getUser = (): User | null => {
    const userString = localStorage.getItem("token");
    if (!userString) {
        return null;
    }
    try {
        const user = JSON.parse(userString);
        return user?.user;
    } catch (error) {
        console.error("Erro ao fazer o parse do JSON:", error);
        return null;
    }
  };

  const [user, setUser] = useState<User | null>(getUser());

  const saveUser = (userData: any) => {
    localStorage.setItem("user", JSON.stringify(userData));
    setUser(userData);
  };

  return {
    setUser: saveUser,
    user,
  };
}
