import React from 'react';
import { Warning } from '@mui/icons-material';
import { Typography } from '@mui/material';
import './warnig.css';

interface WarningComponentProps {
  title: string;
}

const WarningComponent: React.FC<WarningComponentProps> = ({ title }) => {
  return (
    <div>
      <Typography variant="body1" className="no-message">
        <div className='content-no-message'>
            <Warning />
            {title}
        </div>
      </Typography>
    </div>
  );
};

export default WarningComponent;
