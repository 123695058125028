import React, { useState, useEffect, ChangeEvent, FormEvent } from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import DialogContent from '@mui/material/DialogContent';
import Form from 'react-bootstrap/Form';
import InputMask from 'react-input-mask';
import { toast } from 'react-toastify';

import './form-created-unit.css'
import { Partner } from "../../../model/partner";
import Api from "../../../service/apiBase";

const Transition = React.forwardRef(function Transition(
  props: React.ComponentProps<typeof Slide>,
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface FormCreatedPartnersProps {
  reloadComponent: () => void;
  toUpdate: Partner | null;
  isOpen: boolean;
  onClose: () => void;
  id?: string;
}

const FormUpdateUnit: React.FC<FormCreatedPartnersProps> = ({ reloadComponent, toUpdate, isOpen, onClose, id }) => {
  const [formData, setFormData] = useState<Partner>({
    nome: '',
    endereco: '',
    telefone: '',
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (toUpdate) {
      setFormData(toUpdate);
    }
  }, [toUpdate]);

  const handleInputChangeForm = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({
        ...formData,
        [name]: value
    });
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!formData.nome || !formData.endereco || !formData.telefone) {
      toast.error("Por favor, preencha todos os campos obrigatórios.");
      return;
    }

    setLoading(true);
    const apiCall = Api.updateUnit(id, formData);

    apiCall
      .then(() => {
        toast.success(toUpdate ? "Parceiro atualizado" : "Parceiro cadastrado");
        setFormData({
          nome: '',
          endereco: '',
          telefone: '',
        });
        onClose();
        reloadComponent();
      })
      .catch((e) => {
        toast.error(`Erro: ${e.request.status}, Não foi possível, tente novamente mais tarde!`);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Dialog
      fullScreen
      open={isOpen}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar className='bar-modal-dialog'>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1, color: '#ffffff' }} variant="h6" component="div">
            Atualizar Unidade
          </Typography>
        </Toolbar>
      </AppBar>

      <DialogContent className="container-dialog">
        <div className="containerForm">
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="inputNome">Nome</Form.Label>
              <Form.Control
                type="text"
                placeholder="Digite o nome"
                name="nome"
                id="inputNome"
                value={formData.nome}
                onChange={handleInputChangeForm}
                className="form-control-value"
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label htmlFor="inputEndereco">Endereço</Form.Label>
              <Form.Control
                type="text"
                placeholder="Digite o endereço"
                name="endereco"
                id="inputEndereco"
                value={formData.endereco}
                onChange={handleInputChangeForm}
                className="form-control-value"
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label htmlFor="inputTelefone">Telefone</Form.Label>
              <Form.Control
                type="text"
                as={InputMask}
                mask="(99) 99999-9999"
                id="inputTelefone"
                name="telefone"
                value={formData.telefone}
                onChange={handleInputChangeForm}
                className="form-control-value"
              />
            </Form.Group>

            <Button type="submit" className="button-submit-form" disabled={loading} disableElevation>
              {loading ? (toUpdate ? 'Atualizando...' : 'Cadastrando...') : (toUpdate ? 'Atualizar' : 'Cadastrar')}
            </Button>
          </Form>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default FormUpdateUnit;



