import React, { ReactNode, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import GroupAdd from '@mui/icons-material/GroupAdd';
import Typography from '@mui/material/Typography';
import useUser from '../useUser';
import Dropdown from 'react-bootstrap/Dropdown';
import Avatar from '@mui/material/Avatar';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SettingsIcon from '@mui/icons-material/Settings';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import EmergencyIcon from '@mui/icons-material/EmergencyRecording';
import BuildIcon from '@mui/icons-material/Build';

import Badge from '@mui/material/Badge';
import PageNotFound from '../pages/restricted/error/404';
import { Collapse, ListItemText, ListItemButton, ListItemIcon } from '@mui/material';


import Scheduling from '../pages/restricted/scheduling/Scheduling';
import { BrowserRouter as Router, Route, Routes, Link, NavLink, useLocation, Navigate } from "react-router-dom";
import Dashboard from '../pages/restricted/dashboard/Dashboard';
import Footer from '../pages/layout/footer/Footer';
import logo from '../util/logos/bsl-main-BW-Inv.png'
import './ResponsiveDrawer.css'
import Partners from '../pages/restricted/partners/Partners';
import Users from '../pages/restricted/users/Users';
import Service from '../pages/restricted/service/Service';
import Encaminhadores from '../pages/restricted/encaminhadores/Encaminhadores';
import CategoryComponent from '../pages/restricted/category/Category';
import { Category } from '@mui/icons-material';
import ClienteComponent from '../pages/restricted/client/Client';
import { Person } from '@mui/icons-material';
import PeopleIcon from '@mui/icons-material/People';

import ForwardingSendComponent from '../pages/restricted/forwarding/Forwarding';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import NearMeOutlinedIcon from '@mui/icons-material/NearMeOutlined';
import { GetAppOutlined } from '@mui/icons-material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ForwardingReceivedComponent from '../pages/restricted/forwarding/ForwardingReceived';
import { styled, useTheme } from '@mui/material/styles'
import UserDropdown from '../pages/layout/header/UserDropdown';
import AccountSettings from '../pages/restricted/account-settings/AccountSettings';
import ServiceProciderComponent from '../pages/restricted/service-provider/service-provider';
import ServiceProviderFoods from '../pages/restricted/service-provider/service-provider-foods';
import ServiceProviderTransport from '../pages/restricted/service-provider/service-provider-transport';
import ForwardingPaymentComponent from '../pages/restricted/forwarding/ForwardingPayment';
import PaymentSuccess from '../pages/restricted/paymentSucess/paymentSucess';
import ForwardingScheduleComponent from '../pages/restricted/forwarding/ForwardingSchedule';
import PrestadorServiceComponent from '../pages/restricted/service/prestadorService';
import UsuariosComponent from '../pages/restricted/usuarios/Usuarios';
import ForwardingFinalizadosComponent from '../pages/restricted/forwarding/ForwardingFinalizados';
import UsersUnidades from '../pages/restricted/users/UsersUnidades';
import ForwardingAdminComponent from '../pages/restricted/forwarding/ForwardingAdmin';

const drawerWidth = 260;

interface CustomNavLinkProps {
  to: string;
  icon: ReactNode;
  text: string;
  collapse?: ReactNode;
  handleDrawerToggle?: any
}

interface Props {
  window?: () => Window;
}

const TypographyHeaderText = styled(Typography)(({ theme }) => ({
  fontSize: '0.75rem',
  lineHeight: 'normal',
  letterSpacing: '0.21px',
  textTransform: 'uppercase',
  color: theme.palette.text.disabled,
  fontWeight: theme.typography.fontWeightMedium
}))

function CustomNavLink({ to, icon, text, collapse, handleDrawerToggle }: CustomNavLinkProps) {
  const { pathname } = useLocation();
  const isActive = pathname === to;

  return (
    <NavLink
      to={to}
      className={isActive ? 'active-link' : 'style-options'}
      style={{
        textDecoration: 'none',
        color: 'rgba(58, 53, 65, 0.87)',
        height: '10px',
      }}
      onClick={handleDrawerToggle}
    >
      {collapse
      ?
        <ListItemButton sx={{ pl: 8 }}>
          <ListItemIcon>
            {icon}
          </ListItemIcon>
          <ListItemText primary={text} />
        </ListItemButton>
      :
        <ListItemButton>
          <ListItemIcon>
            {icon}
          </ListItemIcon>
          <ListItemText primary={text} />
        </ListItemButton>
    }
    </NavLink>
  );
}

export default function ResponsiveDrawer(props: Props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isClosing, setIsClosing] = React.useState(false);
  const [open, setOpen] = React.useState(true);

  const { pathname } = useLocation();
  const isActive = pathname === '/Encaminhamento/Enviar' || pathname === '/Encaminhamento/Receber';
  const [openNav, setOpenNav] = useState(isActive ? true : false);
  const { user } = useUser();

  const handleClickNav = () => {
    setOpenNav(!openNav);
  };

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  const handleLogout = () => {
    try {
        localStorage.removeItem('token');
        document.location.reload();
    } catch (err) {
        console.log("Could not handle logout", err)
    }
  }

  const menuItemsOne = [
    { to: '/Dashboard', icon: <DashboardIcon />, text: 'Dashboard', allowedTypes: ["1","2", "3", "4", "5", "6"]},
    { to: '/Usuarios', icon: <PeopleIcon />, text: 'Usuários', allowedTypes: ["1", "6"] },
    { to: '/Encaminhadores', icon: <GroupAdd />, text: 'Encaminhadores', allowedTypes: ["1", "6"] },
    { to: '/Cliente', icon: <Person />, text: 'Clientes', allowedTypes: ["1", "6"] },
    { to: '/Parceiros', icon: <EmergencyIcon />, text: 'Clinicas', allowedTypes: ["1", "6"] },
    { to: '/PrestadorServico', icon: <BuildIcon />, text: 'Prestador de Serviço', allowedTypes: ["1", "6"] },
    { to: '/Categoria', icon: <Category />, text: 'Tipo de Exame', allowedTypes: ["1", "6"] }
  ];

  const menuItemsTwo = [
    { to: '/Encaminhamento/Enviar', icon: <NearMeOutlinedIcon />, text: 'Novo', allowedTypes: ["1", "2", "6"] },
    { to: '/Encaminhamento/Receber', icon: <GetAppOutlined />, text: 'Recebidos', allowedTypes: ["1", "3", "4", "5", "6"] },
    { to: '/Encaminhamento/Finalizados', icon: <CheckCircleOutlineIcon />, text: 'Concluídos', allowedTypes: ["1","2", "3", "4"," 5", "6"] },
  ];

  const filteredMenuItemsOne = menuItemsOne.filter(item => item.allowedTypes.includes(user?.tipo));
  const filteredMenuItemsTwo = menuItemsTwo.filter(item => item.allowedTypes.includes(user?.tipo));


  const drawer = (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100vh', background: '#f4f5fb' }}>
      <Toolbar className='toolbar-responsible-bar'>
        <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{
                mr: 2,
                display: { xs: 'flex', sm: 'flex', md: 'flex', lg: 'flex', xl: 'flex' },
                textDecoration: 'none',
                fontSize: '1.25rem',
                color: '#544f5a',
                fontWeight: '600'
              }}
            >
              <img src='/images/pages/bsltechLogoCima.png' alt="Logo" style={{ width: "100px" }} />
              {/* IMAGO */}
        </Typography>
      </Toolbar>
    
      <List style={{ flex: 1 }}>

        <Divider
          textAlign='left'
          sx={{
            m: 0,
            width: '100%',
            lineHeight: 'normal',
            textTransform: 'uppercase',
            '&:before, &:after': { top: 7, transform: 'none' },
            '& .MuiDivider-wrapper': { px: 2.5, fontSize: '0.75rem', letterSpacing: '0.21px' },
            marginBottom: '15px',
            marginTop: '15px'
          }}
        >
          <TypographyHeaderText noWrap>Gerenciamento</TypographyHeaderText>
        </Divider>

        {filteredMenuItemsOne.map(item => (
          <CustomNavLink key={item.to} to={item.to} icon={item.icon} text={item.text} handleDrawerToggle={handleDrawerToggle} />
        ))}

        {
          user?.tipo === "1" || user?.tipo === "2" || user?.tipo === "3" || user?.tipo === "4" || user?.tipo === "5" || user?.tipo === "6"
          ? 
            <>
              <ListItemButton onClick={handleClickNav}>
                <ListItemIcon><ArrowUpwardIcon /></ListItemIcon>
                <ListItemText primary='Encaminhamento' />
                {openNav ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={openNav} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {filteredMenuItemsTwo.map(item => (
                    <CustomNavLink key={item.to} to={item.to} icon={item.icon} text={item.text} handleDrawerToggle={handleDrawerToggle} collapse={true}/>
                  ))}
                </List>
              </Collapse>
            </>
          :
            null
        }

        <Divider
          textAlign='left'
          sx={{
            m: 0,
            width: '100%',
            lineHeight: 'normal',
            textTransform: 'uppercase',
            '&:before, &:after': { top: 7, transform: 'none' },
            '& .MuiDivider-wrapper': { px: 2.5, fontSize: '0.75rem', letterSpacing: '0.21px' },
            marginBottom: '15px',
            marginTop: '15px'
          }}
        >
          <TypographyHeaderText noWrap>Sistema</TypographyHeaderText>
        </Divider>
        
        <CustomNavLink handleDrawerToggle={handleDrawerToggle} to="/Configuracoes" icon={<SettingsIcon />} text="Configurações" />

        <ListItemButton onClick={handleLogout}>
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText primary="Sair" />
        </ListItemButton>
      </List>

      <Footer></Footer>
    </div>
  
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar className='toolbar-responsible'>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' }, color: '#544f5a' }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{
              mr: 2,
              display: { xs: 'flex', sm: 'none', md: 'none', lg: 'none', xl: 'none' },
              textDecoration: 'none',
            }}
          >
            <img src={logo} alt="Logo" style={{ width: "100px", height: "60px" }} />
          </Typography>
          <Typography sx={{display: {xs: 'none', sm: 'flex', md: 'flex', lg: 'flex', xl: 'flex'}}}></Typography>
          <div className="user">
            <UserDropdown></UserDropdown>
          </div>
        </Toolbar>
      </AppBar>
      
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 }}}
        aria-label="mailbox folders"
      >
        {/* Mobile */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onTransitionEnd={handleDrawerTransitionEnd}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true, 
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>  

        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, border: 'none' },
          }}
          className='drawer-sidebar'
          open
        >
          {drawer}
        </Drawer>
      </Box>

      <Box
        component="main"
        sx={{ flexGrow: 1, p: 4, width: { sm: `calc(100% - ${drawerWidth}px)` }, height: '100vh', background: '#f4f5fb', overflowY: 'auto' }}
      >
        <Toolbar></Toolbar>
        <Routes>
            <Route path="/" element={<Navigate to="/Dashboard" />} />
            <Route path="/Dashboard" element={<Dashboard/>} />
            <Route path="/Parceiros" element={<Partners/>} />
            <Route path="/Unidade/:id" element={<Scheduling />} />
            <Route path="/Usuarios/:id" element={<UsersUnidades />} />
            <Route path="/Servico/:id" element={<Service />} />
            <Route path="/Prestador/Servico/:id" element={<PrestadorServiceComponent />} />
            <Route path="/Categoria" element={<CategoryComponent />} />
            <Route path="/Cliente" element={<ClienteComponent />} />
            <Route path="/Usuarios" element={<Users />} />
            <Route path="/Encaminhamento/Enviar" element={<ForwardingSendComponent />} />
            <Route path="/Encaminhamentos/:id" element={<ForwardingAdminComponent />} />
            <Route path="/Encaminhamento/Enviar/:id" element={<ForwardingPaymentComponent />} />
            <Route path="/Encaminhamento/Receber" element={<ForwardingReceivedComponent />} />
            <Route path="/Encaminhamento/Finalizados" element={<ForwardingFinalizadosComponent />} />
            <Route path="/Encaminhamento/Receber/:id" element={<ForwardingScheduleComponent />} />
            <Route path="/Configuracoes" element={<AccountSettings />} />
            <Route path="/PrestadorServico" element={<ServiceProciderComponent />} />
            <Route path="/PrestadorServico/Alimenticio" element={<ServiceProviderFoods />} />
            <Route path="/PrestadorServico/Transport" element={<ServiceProviderTransport />} />
            <Route path="/pagamento/realizado" element={<PaymentSuccess />} />
            <Route path="/Encaminhadores" element={<Encaminhadores />} />
            <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Box>
    </Box>
  );
}
