// ** React Imports
import { useState } from 'react'

// ** MUI Components
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import CardContent from '@mui/material/CardContent'
import { styled } from '@mui/material/styles'
import MuiCard from '@mui/material/Card'
import MuiFormControlLabel from '@mui/material/FormControlLabel'
import Api from '../../../service/apiBase'
import { Link } from "react-router-dom";
import BlankLayout from '../../../components/BlankLayout'
import FooterIllustrationsV1 from '../../../components/FooterIllustration'
import { toast } from 'react-toastify';
const Card = styled(MuiCard)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: { width: '28rem', zIndex: 20 }
}))

const LinkStyled = styled('a')(({ theme }) => ({
  fontSize: '0.875rem',
  textDecoration: 'none',
  color: theme.palette.primary.main
}))

const FormControlLabel = styled(MuiFormControlLabel)(({ theme }) => ({
  marginTop: theme.spacing(1.5),
  marginBottom: theme.spacing(4),
  '& .MuiFormControlLabel-label': {
    fontSize: '0.875rem',
    color: theme.palette.text.secondary
  }
}))

const ResetPassword = () => {
  const [values, setValues] = useState({
    email: '',
  })

  const [loading, setLoading] = useState(false)

  const handleChange = prop => event => {
    setValues({ ...values, [prop]: event.target.value })
  }

  const handleSubmitEmail = () => {
    if(!values.email) {
      toast.success(`Por favor, preencha todos os campos obrigatórios.`);
    }
    setLoading(true);
    Api.resetPasswordEmail({email: values.email})
    .then((res) => {
      if(res.error) {
        toast.error(res.error)
      }else {
        
        toast.success(res.message);
        setValues({
          email: '',
        });
      }
    })
    .catch((e) => {
      toast.error(`Erro: ${e.request.status}, Não foi possível, tente novamente mais tarde!`);
    })
    .finally(() => {
      setLoading(false);
    });
  }
  return (
    <BlankLayout>
      <Box className='content-center'>
        <Card sx={{ zIndex: 1 }}>
          <CardContent sx={{ padding: theme => `${theme.spacing(12, 9, 7)} !important` }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

              <Typography
                  variant="h6"
                  noWrap
                  component="div"
                  sx={{
                    display: { xs: 'flex', sm: 'flex', md: 'flex', lg: 'flex', xl: 'flex' },
                  }}
              >
                  <img src='/images/pages/bsltechLogoCima.png' alt="Logo" style={{ width: "150px" }} />
              </Typography>
            </Box>
            <Box sx={{ mb: 6 }}>
              <Typography variant='h5' sx={{ fontWeight: 600, marginBottom: 1.5 }}>
                Esqueceu a senha?
              </Typography>
              <Typography variant='body2'>Degite abaixo o e-mail utilizado no cadastro da sua conta</Typography>
            </Box>
            <form noValidate autoComplete='off'>
              
              <TextField fullWidth type='email' label='Email*' sx={{ marginBottom: 4 }} value={values.email} onChange={handleChange('email')} />

              <Button fullWidth size='large' variant='contained' sx={{ marginBottom: 7 }} onClick={() => handleSubmitEmail()}>
                Enviar
              </Button>
              <Box
                  sx={{ mb: 2, display: 'flex', alignItems: 'center', flexWrap: 'wrap', justifyContent: 'end' }}
                  >
                  <Link to={'/login'} className='style-text-color'><LinkStyled>Realizar login?</LinkStyled></Link>
              </Box>
            </form>
          </CardContent>
        </Card>
        <FooterIllustrationsV1 />
      </Box>
    </BlankLayout>
  )
}

export default ResetPassword
