import React, { useState, useEffect } from "react";
import { Card, CardContent, Typography, CardActions, Tooltip, Button, styled } from "@mui/material";
import { Link } from "react-router-dom";

import FormCreatedPartners from "../../../components/forms/Partners/form-created-partners";
import { toast } from 'react-toastify';
import Api from "../../../service/apiBase";
import { Partner } from "../../../model/partner";
import { Container } from "react-bootstrap";
import "./Partners.css";
import FormUpdatePartners from "../../../components/forms/Partners/form-update-partners";
import WarningComponent from "../../../components/warnig/warnig"
import LoadingComponent from "../../../components/loadingCircular/loadingComponent";

const TriangleImg = styled('img')({
  right: 0,
  bottom: 0,
  height: 170,
  position: 'absolute'
})

function Partners() {
  const [partnersData, setPartners] = useState<Partner[]>([]);
  const [loading, setLoading] = useState(true);
  const [reload, setReload] = useState(false);
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const [selectedPartner, setSelectedPartner] = useState<Partner | null>(null);

  useEffect(() => {
    Api.getAllPartners()
      .then(data => {
        setPartners(data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Erro ao buscar parceiros:', error);
        setLoading(false);
      });
  }, [reload]);

  const handleReload = () => {
    setReload(!reload);
  };

  const handleUpdatePartner = (partner: Partner) => {
    setSelectedPartner(partner);
    setUpdateModalOpen(true);
  };

  const handleActivateDeactivate = (partner: Partner) => {
    const newStatus = partner.is_ativo === '0'  || partner.is_ativo === 0 ? '0' : '1';
    Api.updatePartnerActivateDesactivate(partner.id)
      .then((response) => {
        if (response.message === 'Parceiro Liberado/Bloqueado com Sucesso') {
          toast.success(`Parceiro ${newStatus === '1' ? 'ativado' : 'desativado'} com sucesso`);
          setPartners(prevPartners => prevPartners.map(p => p.id === partner.id ? { ...p, is_ativo: newStatus } : p));
          handleReload();
        } else {
          toast.error(`Não foi possível, tente novamente mais tarde!`);
        }
      })
      .catch((e) => {
        toast.error(`Erro: ${e.request.status}, Não foi possível, tente novamente mais tarde!`);
      });
  };

  return (
    <>
      <Container className="p-0 mb-6">
        <div className="row container-units mb-4">
          <div className="col-12">
            <span>Home</span>
          </div>
          <div className="col-12">
            <div className="row">
              <div className="col-md-6">
                <h1>Clínicas</h1>
              </div>
            </div>
          </div>
        </div>

        {loading ?
          <LoadingComponent/>
          :
          partnersData.length > 0 ? (
            partnersData.map(partner => (
              <Card className="partner-card mb-3" key={partner.id}>
                <CardContent>
                  <Typography variant="h6" component="h2" className="partner-name">
                    {partner.nome}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" component="p">
                    {partner.endereco} - {partner.telefone}
                  </Typography>

                  <Tooltip title={partner.is_ativo === '0'  || partner.is_ativo === 0 ? 'Ativo' : 'Inativo'} placement="top">
                    <div className={`partner-status-${partner.is_ativo === '0'  || partner.is_ativo === 0 ? 'active' : 'inactive'}`}></div>
                  </Tooltip>

                  <TriangleImg alt='triangle background' src={`/images/misc/triangle-light.png`} />

                </CardContent>
                <CardActions className="card-cartion-buttons">

                  <Button
                    size="small"
                    color="primary"
                    className="button-card-action"
                    onClick={() => handleActivateDeactivate(partner)}
                  >
                    {partner.is_ativo === '1' || partner.is_ativo === 1 ? 'Ativar' : 'Desativar'}
                  </Button>
                  {
                    partner.is_ativo === '1' || partner.is_ativo === 1 ? null : 
                    <>
                      
                      <Button
                        size="small"
                        className="button-card-action-update"
                        onClick={() => handleUpdatePartner(partner)}
                      >
                        Atualizar
                      </Button>
                      <Button
                        size="small"
                        className="button-card-action-update"
                        component={Link}
                        to={`/unidade/${partner.id}`}
                      >
                        Unidades
                      </Button>
                    </>
                  }

                  
                </CardActions>
              </Card>
            ))
          ) : (
            <WarningComponent title="Nenhum parceiro cadastrado."/>
          )}
      </Container>

      <FormCreatedPartners
        onPartnerCreated={handleReload}
        initialPartner={selectedPartner}
      />

      <FormUpdatePartners 
        onPartnerCreated={handleReload}
        partnerToUpdate={selectedPartner}
        id={selectedPartner?.id}
        isOpen={updateModalOpen}
        onClose={() => setUpdateModalOpen(false)}
      />
    </>
  );
}

export default Partners;
