import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, Button, DialogTitle } from '@mui/material';
import Api from '../../service/apiBase';
import CancelIcon from '@mui/icons-material/Cancel';
import QRCode from 'qrcode.react';
import './pix-component.css'
import LoadingComponent from '../loadingCircular/loadingComponent';
import { toast } from 'react-toastify';

interface PixComponentProps {
    taxId: string;
    isOpen: boolean;
    onClose: () => void;
}

interface DataPix {
    status: string,
    taxId: string,
    copiaECola: string,
}

const PixComponent: React.FC<PixComponentProps> = ({ taxId, isOpen, onClose }) =>  {
    const [dataPix, setDataPix] = useState<DataPix[]>([]);
    const [loading, setLoading] = useState(true);
    const [copied, setCopied] = useState(false);

    const handleCopy = () => {
        navigator.clipboard.writeText(dataPix[0].copiaECola);
        setCopied(true);
        toast.success("Chave PIX copiada para área de transferência");
        setTimeout(() => {
            setCopied(false);
        }, 2000);
    };

    useEffect(() => {
        if(isOpen) {
            Api.getPixTxId(taxId)
                .then(data => {
                    if(data.length === undefined ) {
                        setDataPix([data])
                    }else {
                        setDataPix(data)
                    }
                    
                    setLoading(false);
                })
                .catch(error => {
                    console.error('Erro ao buscar:', error);
                });
        }
    }, [isOpen]);

    return (
        <>
            <Dialog open={isOpen} onClose={onClose} className='modal-stripe-container'>
                <DialogActions>
                    <CancelIcon onClick={onClose} className='icon-close-stripe'></CancelIcon>
                </DialogActions>
                <DialogTitle sx={{ fontSize: '20px', fontWeight: '700' }} className='d-flex flex-column align-items-center pt-0'>
                    Detalhes do Pagamento
                </DialogTitle> 
                <DialogContent className='pt-0'>
                    {loading ? (
                        <LoadingComponent/>
                    ) : (
                        <div className='d-flex flex-column align-items-center'>
                            <div className='mb-3 w-50'>
                            {dataPix.map((data, index) => (
                                <QRCode key={index} value={data.copiaECola} size={256} />
                            ))}

                            </div>
                            <div className='container-pix-info'>
                                {dataPix[0].copiaECola}
                            </div>
                            <Button
                                size="large"
                                className="button-card-action-update mt-3 w-50"
                                type="submit"
                                disabled={copied}
                                onClick={handleCopy}
                            >
                                Copiar Chave PIX
                            </Button>
                        </div>
                    )}
                </DialogContent>
            </Dialog>
        </>
    );
};

export default PixComponent;
