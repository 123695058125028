import React, { useState, useEffect, FormEvent, ChangeEvent } from "react";

// ** MUI Imports
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Chip from '@mui/material/Chip'
import Table from '@mui/material/Table'
import TableRow from '@mui/material/TableRow'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import Typography from '@mui/material/Typography'
import TableContainer from '@mui/material/TableContainer'
import { Button} from "@mui/material";

import Api from '../../../service/apiBase'
import LoadingComponent from "../../../components/loadingCircular/loadingComponent";
import { Container } from "react-bootstrap";
import useUser from "../../../useUser";
import WarningComponent from "../../../components/warnig/warnig";
import EditIcon from '@mui/icons-material/Edit';
import { Link } from "react-router-dom";

const rows = [
  {
    age: 27,
    status: 'current',
    date: '09/27/2018',
    name: 'Sally Quinn',
    salary: '$19586.23',
    email: 'eebsworth2m@sbwire.com',
    designation: 'Human Resources Assistant'
  },
  {
    age: 61,
    date: '09/23/2016',
    salary: '$23896.35',
    status: 'professional',
    name: 'Margaret Bowers',
    email: 'kocrevy0@thetimes.co.uk',
    designation: 'Nuclear Power Engineer'
  },
  {
    age: 59,
    date: '10/15/2017',
    name: 'Minnie Roy',
    status: 'rejected',
    salary: '$18991.67',
    email: 'ediehn6@163.com',
    designation: 'Environmental Specialist'
  },
  {
    age: 30,
    date: '06/12/2018',
    status: 'resigned',
    salary: '$19252.12',
    name: 'Ralph Leonard',
    email: 'dfalloona@ifeng.com',
    designation: 'Sales Representative'
  },
  {
    age: 66,
    status: 'applied',
    date: '03/24/2018',
    salary: '$13076.28',
    name: 'Annie Martin',
    designation: 'Operator',
    email: 'sganderton2@tuttocitta.it'
  },
  {
    age: 33,
    date: '08/25/2017',
    salary: '$10909.52',
    name: 'Adeline Day',
    status: 'professional',
    email: 'hnisius4@gnu.org',
    designation: 'Senior Cost Accountant'
  },
  {
    age: 61,
    status: 'current',
    date: '06/01/2017',
    salary: '$17803.80',
    name: 'Lora Jackson',
    designation: 'Geologist',
    email: 'ghoneywood5@narod.ru'
  },
  {
    age: 22,
    date: '12/03/2017',
    salary: '$12336.17',
    name: 'Rodney Sharp',
    status: 'professional',
    designation: 'Cost Accountant',
    email: 'dcrossman3@google.co.jp'
  }
]

const statusObj = [
  { color: '#dc3545' }, // Aguardando Início (cinza claro)
  { color: '#6c757d' }, // Encaminhamento Iniciado (vermelho claro)
  { color: '#007bff' }, // Encaminhamento Confirmado (azul)
  { color: '#ffc107' },  // Encaminhamento Realizado (amarelo)
  { color: '#26EF81' }
];

const statusObjLabel = [
  { label: 'Encaminhamento Aberto' },
  { label: 'Aguardando Agendamento' },
  { label: 'Encaminhamento Agendado' },
  { label: 'Encaminhado Confirmado' },
  { label: 'Encaminhamento Finalizado' },
]

const statusObjPayment = [
  { color: '#dc3545' }, // Aguardando Início (cinza claro)
  { color: '#6c757d' }, // Encaminhamento Iniciado (vermelho claro)
  { color: '#007bff' }, // Encaminhamento Confirmado (azul)
  { color: '#ffc107' },  // Encaminhamento Realizado (amarelo)
  { color: '#26EF81' }
];

const statusObjLabelPayment = [
  { label: 'A PAGAR PARCEIRO' },
  { label: 'A PAGAR CLIENTE' },
]

const TableAtendente = () => {
  const [formData, setFormData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { user } = useUser();

  useEffect(() => {
    Api.getAllReceivedForwarding(user?.id)
      .then(data => {
        if(data.length === undefined ) {
          setFormData([data])
        }else {
          setFormData(data)
        }
        
        setLoading(false);
      })
      .catch(error => {
        console.error('Erro ao buscar:', error);
      });
  }, []);

  const formatarCPF = (str) => {
    // Remove todos os caracteres não numéricos
    const digitsOnly = str.replace(/\D/g, '');

    // Verifica se o CPF tem a quantidade correta de dígitos
    if (digitsOnly.length !== 11) {
        throw new Error('CPF deve conter 11 dígitos.');
    }

    // Formata o CPF no formato com pontos e traço
    return `${digitsOnly.slice(0, 3)}.${digitsOnly.slice(3, 6)}.${digitsOnly.slice(6, 9)}-${digitsOnly.slice(9)}`;
  }

  const formatPhoneNumber = (phoneNumber) => {
    // Remove todos os caracteres não numéricos
    const digitsOnly = phoneNumber.replace(/\D/g, '');
  
    // Verifica se o número de telefone tem a quantidade correta de dígitos
    if (digitsOnly.length < 10 || digitsOnly.length > 11) {
      throw new Error('Número de telefone inválido.');
    }
  
    // Formata o número de telefone no formato com código de área
    if (digitsOnly.length === 10) {
      return `(${digitsOnly.slice(0, 2)}) ${digitsOnly.slice(2, 6)}-${digitsOnly.slice(6)}`;
    } else {
      return `(${digitsOnly.slice(0, 2)}) ${digitsOnly.slice(2, 3)} ${digitsOnly.slice(3, 7)}-${digitsOnly.slice(7)}`;
    }
  };

  return (
    <Card>
      <TableContainer>
        <Table sx={{ minWidth: 800 }} aria-label='table in dashboard'>
          <TableHead>
            <TableRow>
              <TableCell>Cliente</TableCell>
              <TableCell>Encaminhador</TableCell>
              <TableCell>Valor</TableCell>
              <TableCell>Tipo de pagamento</TableCell>
              <TableCell>Status de pagamento</TableCell>
              <TableCell>Status de agendamento</TableCell> 
              <TableCell>Ações</TableCell> 
            </TableRow>
          </TableHead>
          <TableBody>
            { formData.length > 0 ? formData.map(row => (
              <TableRow hover key={row.id} sx={{ '&:last-of-type td, &:last-of-type th': { border: 0 } }}>
              <TableCell sx={{ py: theme => `${theme.spacing(0.5)} !important` }}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography sx={{ fontWeight: 500, fontSize: '0.875rem !important' }}>{row?.dados_cliente?.nome}</Typography>
                  <Typography variant='caption'>{row?.dados_cliente?.email}</Typography>
                  <Typography variant='caption'>{formatPhoneNumber(row?.dados_cliente?.telefone)}</Typography>
                  <Typography variant='caption'>{formatarCPF(row?.dados_cliente?.cpf)}</Typography>
                </Box>
              </TableCell>
              <TableCell sx={{ py: theme => `${theme.spacing(0.5)} !important` }}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography sx={{ fontWeight: 500, fontSize: '0.875rem !important' }}>{row?.info_encaminhador?.nome}</Typography>
                  <Typography variant='caption'>{row?.info_encaminhador?.email}</Typography>
                  <Typography variant='caption'>{row?.info_encaminhador?.cpf ? formatarCPF(row?.info_encaminhador?.cpf) : null}</Typography>
                </Box>
              </TableCell>
              <TableCell sx={{ py: theme => `${theme.spacing(0.5)} !important` }}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography sx={{ fontWeight: 500, fontSize: '0.875rem !important' }}>R$ {row?.valorTotal.toFixed(2)}</Typography>
                </Box>
              </TableCell>
              <TableCell>
                <Chip
                  label={row?.tipo_pagamento}
                  sx={{
                    background: "#343434",
                    color: "#fff",
                    height: 24,
                    fontSize: '0.75rem',
                    textTransform: 'capitalize',
                    '& .MuiChip-label': { fontWeight: 600 }
                  }}
                />
              </TableCell>
              <TableCell>
                <Chip
                  label={row?.status_pagamento}
                  sx={{
                    background: "#343434",
                    color: "#fff",
                    height: 24,
                    fontSize: '0.75rem',
                    textTransform: 'capitalize',
                    '& .MuiChip-label': { fontWeight: 600 }
                  }}
                />
              </TableCell>
              <TableCell>
                <Chip
                  label={statusObjLabel[row.status_encaminhamento-1].label}
                  sx={{
                    background: `${statusObj[row.status_encaminhamento - 1].color}`,
                    color: "#fff",
                    height: 24,
                    fontSize: '0.75rem',
                    textTransform: 'capitalize',
                    '& .MuiChip-label': { fontWeight: 600 }
                  }}
                />
              </TableCell>
              <TableCell>
              <Button
                size="small"
                component={Link}
                to={`/Encaminhamento/Receber/${row.id}`}
              >
                <EditIcon />
              </Button>
              </TableCell>
            </TableRow>
            )) : null}
          </TableBody>
        </Table>

        {
          loading ? 
          <Container className="p-3">
            <LoadingComponent/> 
          </Container>
          : 
          formData.length > 0 ? null : <Container className="p-3"> <WarningComponent title="Nenhuma encaminhamento recebido."/> </Container>
          
        }
      </TableContainer>
    </Card>
  )
}

export default TableAtendente
