import React, { useState, useEffect, FormEvent, ChangeEvent } from "react";
import { Card, CardContent, Typography, CardActions, Tooltip, Button, styled } from "@mui/material";
import { toast } from 'react-toastify';
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import WarningComponent from "../../../components/warnig/warnig"
import LoadingComponent from "../../../components/loadingCircular/loadingComponent";
import Api from "../../../service/apiBase";
import { Container } from "react-bootstrap";
import { User } from "../../../model/partner";
import FormCreatedUser from "../../../components/forms/Users/form-created-user";
import FormUpdateUser from "../../../components/forms/Users/form-update-user";

const TriangleImg = styled('img')({
  right: 0,
  bottom: 0,
  height: 170,
  position: 'absolute'
})

function UsersUnidades() {
    const [unitsData, setUnits] = useState<User[]>([]);
    const [loading, setLoading] = useState(true);
    const [selectedValues, setSelectedValues] = useState<User | null>(null);
    const [updateModalOpen, setUpdateModalOpen] = useState(false);
    const { id } = useParams(); 
    const [reload, setReload] = useState(false);
  
    const handleReload = () => {
      setReload(!reload);
    };
  
    const handleUpdate = (values: User) => {
      setSelectedValues(values);
      setUpdateModalOpen(true);
    };
  
    useEffect(() => {
      if(id !== null) {
        Api.getUnitByUsers(id)
        .then(data => {
          if(data.length === undefined ) {
            setUnits([...data])
          }else {
            setUnits(data)
          }
          setLoading(false);
        })
        .catch(error => {
          console.error('Erro ao buscar:', error);
        });
      }
    }, [id, reload]);
  
  
    const handleActivateDeactivate = (data: User) => {
      const newStatus = data.is_liberado === '1' ? '0' : '1';
      Api.updateUsersActivateDesactivate(data.id)
        .then((response) => {
          if (response.message === 'Usuario Liberado/Bloqueado com Sucesso') {
            toast.success(`${newStatus !== '0' ? 'Liberado' : 'Bloqueado'} com sucesso`);
            setUnits(prev => prev.map(p => p.id === data.id ? { ...p, is_liberado: newStatus } : p));
          } else {
            toast.error(`Não foi possível, tente novamente mais tarde!`);
          }
        })
        .catch((e) => {
          toast.error(`Erro: ${e.request.status}, Não foi possível, tente novamente mais tarde!`);
        });
    };

    const formatarCPF = (str: string) => {
        // Remove todos os caracteres não numéricos
        const digitsOnly = str.replace(/\D/g, '');
    
        // Verifica se o CPF tem a quantidade correta de dígitos
        if (digitsOnly.length !== 11) {
            throw new Error('CPF deve conter 11 dígitos.');
        }
    
        // Formata o CPF no formato com pontos e traço
        return `${digitsOnly.slice(0, 3)}.${digitsOnly.slice(3, 6)}.${digitsOnly.slice(6, 9)}-${digitsOnly.slice(9)}`;
    }
  
    const getTypeUser = (type: string) => {
      switch(type) {
        case '1':
          return 'Administrador';
        case '2':
          return 'Parceiro';
        case '3':
          return 'Atendente';
        case '4':
          return 'Prestador de Serviço Alimentício';
        case '5':
            return 'Prestador de Serviço de Transporte';
        default:
          return 'Desconhecido';
      }
    }
    return (
      <>
        <Container className="p-0 mb-6">
            <div className="row container-units mb-4">
              <div className="col-12">
                <span>Home</span>
              </div>
              <div className="col-12">
                <div className="row">
                  <div className="col-md-12">
                    <h1>Usuários</h1>
                  </div>
                </div>
              </div>
            </div> 
            {
              loading ?
                <LoadingComponent/>
              :
              unitsData !== undefined && unitsData.length > 0 ? (
                  unitsData.map(data => (
                    <Card className="partner-card mb-3" key={data.id}>
                      <CardContent>
                        <Typography variant="h6" component="h2" className="partner-name">
                          {data.nome}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p">
                          {data.email} - {formatarCPF(data.cpf)}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p">
                          Função: {getTypeUser(data.tipo)}
                        </Typography>
      
                        <Tooltip title={data.is_liberado !== '0' ? 'Liberado' : 'Bloqueado'} placement="top">
                          <div className={`partner-status-${data.is_liberado !== '0' ? 'active' : 'inactive'}`}></div>
                        </Tooltip>

                        <TriangleImg alt='triangle background' src={`/images/misc/triangle-light.png`} />
                        
                      </CardContent>
                      <CardActions className="card-cartion-buttons">
                        <Button
                          size="small"
                          color="primary"
                          className="button-card-action"
                          onClick={() => handleActivateDeactivate(data)}
                        >
                          {data.is_liberado !== '0' ? 'Desativar' : 'Ativar'}
                        </Button>
                        {
                          data.is_liberado !== '1' ? null :
                          <>
                            <Button
                              size="small"
                              className="button-card-action-update"
                              onClick={() => handleUpdate(data)}
                            >
                              Atualizar
                            </Button>
  
                            {/* <Button
                              size="small"
                              className="button-card-action-update"
                              component={Link}
                              to={`/Usuarios/${data.id}`}
                            >
                              Usuários
                            </Button> */}
                          </>
                          
                        }
                      </CardActions>
                    </Card>
                  ))
                )
              : 
                (
                  <>
                    <WarningComponent title="Nenhum cadastro"/>
                  </>
                ) 
            }
            
        </Container>
        <FormCreatedUser 
          reloadComponent={handleReload}
        />
        <FormUpdateUser
          reloadComponent={handleReload}
          toUpdate={selectedValues}
          id={selectedValues?.id}
          isOpen={updateModalOpen}
          onClose={() => setUpdateModalOpen(false)}
        />
      </>
    );
}

export default UsersUnidades;
