import React, { useState, useEffect, ChangeEvent, FormEvent } from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import AddIcon from '@mui/icons-material/Add';
import DialogContent from '@mui/material/DialogContent';
import Form from 'react-bootstrap/Form';
import InputMask from 'react-input-mask';
import Api from "../../../service/apiBase";
import { toast } from 'react-toastify';
import { Category } from "../../../model/partner";
import { useParams } from "react-router-dom";

// import '../forms.css'

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface FormCreatedUserProps {
  reloadComponent: () => void;
  id?: number
}

const FormCreated: React.FC<FormCreatedUserProps> = ({ reloadComponent }) => {
  const [open, setOpen] = React.useState(false);
  const [formData, setFormData] = useState({
    nome: '',
    telefone: '',
    email: '',
    cpf: '',
  });
  const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState<Category[]>([]);

  const { id } = useParams(); 

  useEffect(() => {
    Api.getAllCategory()
      .then(data => {
        setCategory(data);
      })
      .catch(error => {
        console.error('Erro ao buscar parceiros:', error);
      });
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleInputChangeForm = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const removeFormatarCPF = (str: string) => {
    const digitsOnly = str.replace(/\D/g, '');

    return digitsOnly;
  }

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  
    if (!formData.nome || !formData.telefone || !formData.email || !formData.cpf) {
      toast.error("Por favor, preencha todos os campos obrigatórios.");
      return;
    }
  
    setLoading(true);
    
    Api.createClient({nome: formData.nome, telefone: formData.telefone, email: formData.email, cpf: removeFormatarCPF(formData.cpf)})
      .then((res) => {
        if(res.error) {
          toast.error(res.error)
        }else {
          toast.success("cadastrado realizado com sucesso");
          setFormData({
            nome: '',
            telefone: '',
            email: '',
            cpf: ''
          });
          handleClose();
          reloadComponent();
        }
      })
      .catch((e) => {
        toast.error(`Erro: ${e.request.status}, Não foi possível, tente novamente mais tarde!`);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <React.Fragment>
      <Button className='button-open' id='buttonOpen' onClick={handleClickOpen}>
        <AddIcon className="icon-add" />
      </Button>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar className='bar-modal-dialog'>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1, color: '#ffffff' }} variant="h6" component="div">
              Cadastrar Cliente
            </Typography>
          </Toolbar>
        </AppBar>

        <DialogContent className="container-dialog">
          <div className="containerForm">
            <Form onSubmit={handleSubmit}>

              <Form.Group className="mb-3">
                <Form.Label htmlFor="inputNome">Nome*</Form.Label>
                <Form.Control 
                  type="text" 
                  placeholder="Digite o nome" 
                  name="nome" 
                  id="inputNome"
                  value={formData.nome} 
                  onChange={handleInputChangeForm} 
                  className="form-control-value"
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label htmlFor="inputEndereco">Email*</Form.Label>
                <Form.Control 
                  type="email" 
                  placeholder="Digite o email" 
                  name="email" 
                  id="inputEmail"
                  value={formData.email} 
                  onChange={handleInputChangeForm} 
                  className="form-control-value"
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label htmlFor="inputCpf">CPF*</Form.Label>
                <Form.Control
                  type="text"
                  as={InputMask}
                  mask="999.999.999-99"
                  placeholder="___.___.___-__"
                  id="inputCpf"
                  name="cpf"
                  value={formData.cpf}
                  onChange={handleInputChangeForm}
                  className="form-control-value"
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label htmlFor="inputTelefone">Telefone*</Form.Label>
                <Form.Control
                  type="text"
                  as={InputMask}
                  mask="(99) 99999-9999"
                  placeholder="(__) _____-____"
                  id="inputTelefone"
                  name="telefone"
                  value={formData.telefone}
                  onChange={handleInputChangeForm}
                  className="form-control-value"
                />
              </Form.Group>
              
              <Button type="submit" className="button-submit-form" disabled={loading} disableElevation>
                {loading ? 'Cadastrando...' : 'Cadastrar'}
              </Button>
            </Form>
          </div>
        </DialogContent>

      </Dialog>
    </React.Fragment>
  );
}

export default FormCreated;
