import React, { useState, useEffect, ChangeEvent, FormEvent } from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import AddIcon from '@mui/icons-material/Add';
import DialogContent from '@mui/material/DialogContent';
import Form from 'react-bootstrap/Form';
import InputMask from 'react-input-mask';
import Api from "../../../service/apiBase";
import { toast } from 'react-toastify';
import { Partner } from "../../../model/partner";
import { useParams } from "react-router-dom";

import './form-created-unit.css'

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface FormCreatedUnitProps {
  reloadComponent: () => void;
}


const FormCreatedUnit: React.FC<FormCreatedUnitProps> = ({ reloadComponent }) => {
  const [open, setOpen] = React.useState(false);
  const [formData, setFormData] = useState({
    nome: '',
    endereco: '',
    telefone: ''
  });
  const [loading, setLoading] = useState(false);
  const [partners, setPartners] = useState<Partner[]>([]);
  const { id } = useParams(); 
  // useEffect(() => {
  //   Api.getAllPartners()
  //     .then(data => {
  //       setPartners(data);
  //     })
  //     .catch(error => {
  //       console.error('Erro ao buscar parceiros:', error);
  //     });
  // }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleInputChangeForm = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    setFormData({
      ...formData
    });
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  
    if (!formData.nome || !formData.endereco || !formData.telefone) {
      toast.error("Por favor, preencha todos os campos obrigatórios.");
      return;
    }
  
    setLoading(true);
  
    Api.crietedUnit({id_parceiro: id, ...formData})
      .then(() => {
        toast.success("Unidade cadastrada");
        setFormData({
          nome: '',
          endereco: '',
          telefone: ''
        });
        handleClose();
        reloadComponent();
      })
      .catch((e) => {
        toast.error(`Erro: ${e.request.status}, Não foi possível, tente novamente mais tarde!`);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <React.Fragment>
      <Button className='button-open' id='buttonOpen' onClick={handleClickOpen}>
        <AddIcon className="icon-add" />
      </Button>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar className='bar-modal-dialog'>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1, color: '#ffffff' }} variant="h6" component="div">
              Cadastrar Unidade
            </Typography>
          </Toolbar>
        </AppBar>

        <DialogContent className="container-dialog">
          <div className="containerForm">
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3">
                <Form.Label htmlFor="inputNome">Nome</Form.Label>
                <Form.Control 
                  type="text" 
                  placeholder="Digite o nome" 
                  name="nome" 
                  id="inputNome"
                  value={formData.nome} 
                  onChange={handleInputChangeForm} 
                  className="form-control-value"
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label htmlFor="inputEndereco">Endereço</Form.Label>
                <Form.Control 
                  type="text" 
                  placeholder="Digite o endereço" 
                  name="endereco" 
                  id="inputEndereco"
                  value={formData.endereco} 
                  onChange={handleInputChangeForm} 
                  className="form-control-value"
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label htmlFor="inputTelefone">Telefone</Form.Label>
                <Form.Control
                  type="text"
                  as={InputMask}
                  mask="(99) 99999-9999"
                  id="inputTelefone"
                  name="telefone"
                  value={formData.telefone}
                  onChange={handleInputChangeForm}
                  className="form-control-value"
                />
              </Form.Group>

              {/* <Form.Group className="mb-3 d-flex flex-column">
                <Form.Label htmlFor="selectParceiro">Parceiro</Form.Label>
                <Form.Select 
                id="selectParceiro" 
                name="id_parceiro" 
                value={formData.id_parceiro} 
                onChange={handleSelectChange}
                className="form-control-value form-control-value-select"
                >
                  <option value="">Selecione um parceiro</option>
                  {partners.map(partner => (
                    <option key={partner.id} value={partner.id}>{partner.nome}</option>
                  ))}
                </Form.Select>
              </Form.Group> */}

              <Button type="submit" className="button-submit-form" disabled={loading} disableElevation>
                {loading ? 'Cadastrando...' : 'Cadastrar'}
              </Button>
            </Form>
          </div>
        </DialogContent>

      </Dialog>
    </React.Fragment>
  );
}

export default FormCreatedUnit;