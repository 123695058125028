import React, { useState, useEffect, ChangeEvent, FormEvent } from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputLabel from '@mui/material/InputLabel'

import InputMask from 'react-input-mask';
import Api from "../../../service/apiBase";
import { toast } from 'react-toastify';
import TextField from '@mui/material/TextField'
import FormHelperText from '@mui/material/FormHelperText'
import InputAdornment from '@mui/material/InputAdornment'

// ** Icons Imports
import EyeOutline from 'mdi-material-ui/EyeOutline'
import EyeOffOutline from 'mdi-material-ui/EyeOffOutline'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const FormUpdate = ({ reloadComponent, isOpen, onClose, idUnidade, tipo, title }) => {
  const [formData, setFormData] = useState({
    nome: '',
    email: '',
    cpf: '',
    password: '',
    password_confirm: '',
  });
  const [loading, setLoading] = useState(false);
  
  const [showPassword, setShowPassword] = useState(false)
  const [showPasswordConfirmPassword, setShowPasswordConfirmPassword] = useState(false)
  const [passwordError, setPasswordError] = useState(false);

  const removeFormatarCPF = (str) => {
    const digitsOnly = str.replace(/\D/g, '');

    return digitsOnly;
  }

  const handleSubmit = () => {
  
    if (!formData.nome || !formData.email || !formData.cpf || !formData.password || !formData.password_confirm) {
        toast.warning("Por favor, preencha todos os campos obrigatórios.");
        return;
    }

    if (formData.password !== formData.password_confirm) {
      toast.warning("As senhas não coincidem!");
      return;
    }
    console.log(formData, idUnidade, tipo)
    setLoading(true);
    
    Api.registerUser({nome: formData.nome, cpf: removeFormatarCPF(formData.cpf), email: formData.email, password: formData.password, password_confirm: formData.password_confirm, tipo: tipo, id_unidade: idUnidade})
      .then((res) => {
        if(res.error) {
          toast.error(res.error)
        }else {
          toast.success("Cadastro realizado com sucesso!");
          setFormData({
            nome: '',
            email: '',
            cpf: '', 
            password: '',
            password_confirm: '',
          });
          onClose();
        }
      })
      .catch((e) => {
        toast.error(`Erro: ${e.request.status}, Não foi possível, tente novamente mais tarde!`);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChange = (prop) => (event) => {
    setFormData({ ...formData, [prop]: event.target.value })
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  }

  const handleClickConfirmPassShow = () => {
    setShowPasswordConfirmPassword(!showPasswordConfirmPassword);
  }

  const handleMouseDownPassword = event => {
    event.preventDefault()
  }

  useEffect(() => {
    if (formData.password && formData.password_confirm && formData.password !== formData.password_confirm) {
      setPasswordError(true);
    } else {
      setPasswordError(false);
    }
  }, [formData.password, formData.password_confirm]);

  return (
    <React.Fragment>
      <Dialog
        fullScreen
        open={isOpen}
        onClose={onClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar className='bar-modal-dialog'>
            <IconButton
              edge="start"
              color="inherit"
              onClick={onClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1, color: '#ffffff' }} variant="h6" component="div">
              {title}
            </Typography>
          </Toolbar>
        </AppBar>

        <DialogContent className="container-dialog pt-4">
          <div className="containerForm">
            <form noValidate autoComplete='off' onSubmit={e => e.preventDefault()}>

            <FormControl fullWidth sx={{ marginBottom: 6 }}>
                <InputLabel htmlFor='cpf'>Nome*</InputLabel>
              <OutlinedInput
                value={formData.nome} 
                onChange={handleChange('nome')}
                label='nome'
                id='nome'
                type={'text'}
              />
            </FormControl>

            <FormControl fullWidth sx={{ marginBottom: 6 }}>
              {/* <InputLabel htmlFor='email'>Email</InputLabel> */}
              <TextField
                value={formData.email} 
                onChange={handleChange('email')}
                label='E-mail*'
                id='email'
                placeholder='contato@gmail.com'
                helperText='Você pode usar letras, números e pontos'
                type={'text'}
              />
            </FormControl>

            <FormControl fullWidth sx={{ marginBottom: 6 }}>
                <InputLabel htmlFor='cpf'>CPF*</InputLabel>
                <InputMask mask="999.999.999-99" value={formData.cpf} onChange={handleChange('cpf')}>
                    {(inputProps) => (
                    <OutlinedInput
                        {...inputProps}
                        label='CPF'
                        id='cpf'
                        type={'text'}
                    />
                    )}
              </InputMask>
            </FormControl>

            

            <FormControl fullWidth sx={{ marginBottom: 6 }}>
                <InputLabel htmlFor='form-layouts-basic-password'>Senha*</InputLabel>
                <OutlinedInput
                  label='Password'
                  value={formData.password}
                  id='form-layouts-basic-password'
                  onChange={handleChange('password')}
                  type={showPassword ? 'text' : 'password'}
                  aria-describedby='form-layouts-basic-password-helper'
                  endAdornment={
                    <InputAdornment position='end'>
                      <IconButton
                        edge='end'
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        aria-label='toggle password visibility'
                      >
                        {showPassword ? <EyeOutline /> : <EyeOffOutline />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <FormHelperText id='form-layouts-basic-password-helper'>
                Use 8 ou mais caracteres com uma mistura de letras, números e símbolos                </FormHelperText>
            </FormControl>

            <FormControl fullWidth>
                <InputLabel htmlFor='form-layouts-confirm-password'>Confirmar senha*</InputLabel>
                <OutlinedInput
                  label='Confirm Password'
                  value={formData.password_confirm}
                  id='form-layouts-confirm-password'
                  onChange={handleChange('password_confirm')}
                  aria-describedby='form-layouts-confirm-password-helper'
                  type={showPasswordConfirmPassword ? 'text' : 'password'}
                  endAdornment={
                    <InputAdornment position='end'>
                      <IconButton
                        edge='end'
                        onClick={handleClickConfirmPassShow}
                        onMouseDown={handleMouseDownPassword}
                        aria-label='toggle password visibility'
                      >
                        {showPasswordConfirmPassword ? <EyeOutline /> : <EyeOffOutline />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {
                  passwordError && (
                    <FormHelperText id='form-layouts-confirm-password-helper' error>
                      As senhas não coincidem.
                    </FormHelperText>
                  )
                }
                
            </FormControl>

            <Button
                fullWidth
                size='large'
                variant='contained'
                sx={{ marginBottom: 4 }}
                className="button-submit-form-material"
                onClick={() => handleSubmit()}
                disabled={loading}
            >
              {loading ? 'Cadastrando...' : 'Cadastrar'}
            </Button>
            </form>
          </div>
        </DialogContent>

      </Dialog>
    </React.Fragment>
  );
}

export default FormUpdate;
