import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import QRCode from 'qrcode.react';
import { Container } from 'react-bootstrap';
import Divider from '@mui/material/Divider';

const BoxWrapper = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    width: '90vw',
  },
}));

const Img = styled('img')(({ theme }) => ({
  marginBottom: theme.spacing(10),
  [theme.breakpoints.down('lg')]: {
    height: 100,
    marginTop: theme.spacing(10),
  },
  [theme.breakpoints.down('md')]: {
    height: 100,
  },
  [theme.breakpoints.up('lg')]: {
    marginTop: theme.spacing(13),
    height: 100,
  },
}));

const ImgPropaganda = styled('img')(({ theme }) => ({
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('lg')]: {
      height: 120,
      marginTop: theme.spacing(1),
    },
    [theme.breakpoints.down('md')]: {
      height: 120,
    },
    [theme.breakpoints.up('lg')]: {
      marginTop: theme.spacing(1),
      height: 200,
    //   width: '35%'
    },
  }));

const TypographyHeaderText = styled(Typography)(({ theme }) => ({
    fontSize: '1rem',
    lineHeight: 'normal',
    // letterSpacing: '0.21px',
    color: 'rgba(58, 53, 65, 0.68)',
    fontWeight: theme.typography.fontWeightMedium
  }))

const PixComponent = () => {
  const { url, ...params } = useParams();
  const [qrcode, setQrcode] = useState('');

    useEffect(() => {
    const baseUrl = 'https://exames.bsltech.com.br/';
    const queryParams = Object.keys(params)
      .map(key => `${params[key]}`)
      .join('&');
    const fullUrl = baseUrl + url + '/' + queryParams;

    setQrcode(fullUrl);
  }, [url, params]);

  return (
    <Box className="content-center">
      <Box sx={{ p: 5, display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
        <BoxWrapper>
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{
                display: { xs: 'flex', sm: 'flex', md: 'flex', lg: 'flex', xl: 'flex' },
                justifyContent: 'center',
              }}
            >
              <img src='/images/pages/bsltechLogoCima.png' alt="Logo" style={{ width: "160px" }} />
            </Typography>
            <Typography variant="h5" sx={{ mb: 1, fontSize: '1.5rem !important' }}>
            Parabéns, cliente! Seu exame foi agendada com sucesso
            </Typography>
            <Typography variant="body2">Agora é só comparecer ao local do exame e apresentar este QR Code no momento do atendimento</Typography>
        </BoxWrapper>

        <Container className='pt-5'>
            {qrcode && <QRCode value={qrcode} size={256} />}
        </Container>

        <Divider
          textAlign='center'
          sx={{
            m: 0,
            width: '80%',
            lineHeight: 'normal',
            '&:before, &:after': { top: 7, transform: 'none' },
            '& .MuiDivider-wrapper': { px: 2.5, fontSize: '0.75rem', letterSpacing: '0.21px' },
            marginBottom: '15px',
            marginTop: '15px'
          }}
        >
          <TypographyHeaderText noWrap>Publicidade</TypographyHeaderText>
        </Divider>
        <ImgPropaganda height='487' alt='error-illustration' src='/images/pages/image.png' />

        <Divider
          textAlign='center'
          sx={{
            m: 0,
            width: '80%',
            lineHeight: 'normal',
            '&:before, &:after': { top: 7, transform: 'none' },
            '& .MuiDivider-wrapper': { px: 2.5, fontSize: '0.75rem', letterSpacing: '0.21px' },
            marginBottom: '15px',
            marginTop: '15px'
          }}
        ></Divider>

        <Img height='487' alt='error-illustration' src='/images/pages/bsltechLogoCima.png' />
      </Box>
    </Box>
  );
};

export default PixComponent;
