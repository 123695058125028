import React, { useState, useEffect, ChangeEvent, FormEvent } from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import AddIcon from '@mui/icons-material/Add';
import DialogContent from '@mui/material/DialogContent';
import Form from 'react-bootstrap/Form';
import InputMask from 'react-input-mask';
import Api from "../../../service/apiBase";
import { toast } from 'react-toastify';
import { Partner } from "../../../model/partner";
import { useParams } from "react-router-dom";


import './form-created-user.css'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const FormCreatedUser = ({ reloadComponent }) => {
  const [open, setOpen] = React.useState(false);
  const [formData, setFormData] = useState({
    nome: '',
    email: '',
    cpf: '',
    id_unidade: '' || null,
    tipo: '',
    valor_credito: '',
  });
  const [loading, setLoading] = useState(false);
  const { id } = useParams(); 

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleInputChangeForm = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const removeFormatarCPF = (str) => {
    // Remove todos os caracteres não numéricos
    const digitsOnly = str.replace(/\D/g, '');
    return digitsOnly;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
  
    if (!formData.nome || !formData.email || !formData.cpf || !formData.tipo ) {
      toast.error("Por favor, preencha todos os campos obrigatórios.");
      return;
    }

    setLoading(true);

    const requestData = {
      cpf: removeFormatarCPF(formData.cpf),
      id_unidade: formData.id_unidade,
      email: formData.email,
      nome: formData.nome,
      tipo: formData.tipo
    };

    // Adiciona valor_credito ao requestData apenas se o tipo for 2
    if (formData.tipo === '2') {
      requestData.valor_credito = formData.valor_credito;
    }

    if(id !== null || id !== undefined) {
      requestData.id_unidade = id;
    }

    Api.registerUser(requestData)
      .then((res) => {
        if(res.error) {
          toast.error(res.error)
        } else {
          toast.success("Cadastro realizado com sucesso");
          setFormData({
            nome: '',
            email: '',
            cpf: '',
            tipo: '',
            id_unidade: '' || null,
            valor_credito: '',
          });
          reloadComponent();
          handleClose();
        }
      })
      .catch((e) => {
        toast.error(`Erro: ${e.request.status}, Não foi possível, tente novamente mais tarde!`);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSelectChange = (e) => {
    const { value } = e.target;
    switch(value) {
      case '1':
        setFormData({
          ...formData,
          id_unidade: null,
          tipo: value
        });
        break;
      case '2':
        setFormData({
          ...formData,
          id_unidade: null,
          tipo: value,
          valor_credito: '',
        });
        break;
      case '3':
        setFormData({
          ...formData,
          id_unidade: '1',
          tipo: value
        });
        break;
      case '4':
      case '5':
        setFormData({
          ...formData,
          id_unidade: null,
          tipo: value
        });
        break;
      default:
        setFormData({
          ...formData,
          id_unidade: null,
          tipo: value
        });
        break;
    }
  };

  return (
    <React.Fragment>
      <Button className='button-open' id='buttonOpen' onClick={handleClickOpen}>
        <AddIcon className="icon-add" />
      </Button>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar className='bar-modal-dialog'>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1, color: '#ffffff' }} variant="h6" component="div">
              Cadastrar Usuário
            </Typography>
          </Toolbar>
        </AppBar>

        <DialogContent className="container-dialog">
          <div className="containerForm">
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3">
                <Form.Label htmlFor="inputNome">Nome*</Form.Label>
                <Form.Control 
                  type="text" 
                  placeholder="Digite o nome" 
                  name="nome" 
                  id="inputNome"
                  value={formData.nome} 
                  onChange={handleInputChangeForm} 
                  className="form-control-value"
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label htmlFor="inputEmail">Email*</Form.Label>
                <Form.Control 
                  type="email" 
                  placeholder="Digite o email" 
                  name="email" 
                  id="inputEmail"
                  value={formData.email} 
                  onChange={handleInputChangeForm} 
                  className="form-control-value"
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label htmlFor="inputCpf">CPF*</Form.Label>
                <Form.Control
                  type="text"
                  as={InputMask}
                  mask="999.999.999-99"
                  id="inputCpf"
                  name="cpf"
                  value={formData.cpf}
                  onChange={handleInputChangeForm}
                  className="form-control-value"
                />
              </Form.Group>

              <Form.Group className="mb-3 d-flex flex-column">
                <Form.Label htmlFor="selectParceiro">Selecione um tipo de usuário*</Form.Label>
                <Form.Select 
                  id="selectParceiro" 
                  name="id_parceiro" 
                  value={formData.tipo} 
                  onChange={handleSelectChange}
                  className="form-control-value form-control-value-select"
                >
                  <option value="">Selecione um tipo</option>
                  <option value="1">Administrador</option>
                  <option value="2">Encaminhador</option>
                  <option value="3">Atendente</option>
                  <option value="4">Prestador de Serviço Alimentício</option>
                  <option value="5">Prestador de Serviço de Transporte</option>
                </Form.Select>
              </Form.Group> 

              {formData.tipo === '2' && (
                <Form.Group className="mb-3">
                  <Form.Label htmlFor="inputValorCredito">Valor de Crédito*</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Digite o valor de crédito"
                    name="valor_credito"
                    id="inputValorCredito"
                    value={formData.valor_credito}
                    onChange={handleInputChangeForm}
                    className="form-control-value"
                  />
                </Form.Group>
              )}

              <Button type="submit" className="button-submit-form" disabled={loading} disableElevation>
                {loading ? 'Cadastrando...' : 'Cadastrar'}
              </Button>
            </Form>
          </div>
        </DialogContent>

      </Dialog>
    </React.Fragment>
  );
}

export default FormCreatedUser;