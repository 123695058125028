import React, { useState, useEffect } from "react";
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import AddIcon from '@mui/icons-material/Add';
import DialogContent from '@mui/material/DialogContent';
import Form from 'react-bootstrap/Form';
import InputMask from 'react-input-mask';
import Api from "../../../service/apiBase";
import { toast } from 'react-toastify';
import { Category, Client, Unit } from "../../../model/partner";
import FormNewService from "../NewService/form-created";
import { Card, CardContent, Typography, CardActions, Tooltip, Button, Grid } from "@mui/material";
import CancelIcon from '@mui/icons-material/Cancel';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { Link, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import '../form.css'
import WarningComponent from "../../warnig/warnig";
import useUser from "../../../useUser";
import FormNewServicePrestador from "../NewService/form-created-prestador";
import FormCreatedEncaminhador from "../Client/form-created-encaminhador";

registerLocale('pt-BR', ptBR);
setDefaultLocale('pt-BR');

const Transition = React.forwardRef(function Transition(
  props,
  ref,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const FormCreatedUserProps = ({ reloadComponent, id }) => {
  const [open, setOpen] = React.useState(false);
  const [idEncaminhamento, setIdEncaminhamento] = React.useState();
  const [encaminhamento, setEncaminhamento] = React.useState([]);
  const [formData, setFormData] = useState({
    id_unidade_recebe: '',
    id_partner: '',
    id_cliente: '',
    tipo_pagamento: '',
    is_agendado: '',
    adicionais: ''
  });
  const [loading, setLoading] = useState(false);
  const [loadingContinue, setLoadingContinue] = useState(false);
  const [firstPart, setFirstPart] = useState(false);
  const [arrayDataClient, setArrayDataClient] = useState([]);
  const [unitsData, setUnits] = useState([]);
  const [partnerData, setPartnerData ] = useState([]);
  const [service, setService] = useState([]);
  const [servicePrestador, setServicePrestador] = useState([]);
  const [updateModalOpenService, setUpdateModalOpenService] = useState(false);
  const [updateModalOpenServicePrestador, setUpdateModalOpenServicePrestador] = useState(false);
  const [displayService, setDisplayService] = useState(false);
  const [displayServicePrestador, setDisplayServicePrestador] = useState(false);
  const [reload, setReload] = useState(false);

  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [options, setOptions] = useState([]);
  const { user } = useUser();
  const navigate = useNavigate();


  const handleDateChange = (date) => {
      setSelectedDate(date);
  };

  const handleTimeChange = (date) => {
    setSelectedTime(date);
  };

  const handleReload = () => {
    setReload(!reload);
  };

  useEffect(() => {
    Promise.all([
      Api.getAllClientActived(),
      Api.getAllUnits(),
      Api.getAllPartners()

    ])
      .then(([clientData, unitsData, partnerData]) => {
        if (!Array.isArray(clientData)) {
          clientData = [clientData];
        }

        if (!Array.isArray(partnerData)) {
          partnerData = [partnerData];
        }

        setArrayDataClient(clientData);
        
        setUnits(unitsData);
        
        setPartnerData(partnerData);

        setLoading(false);
      })
      .catch(error => {
        console.error('Erro ao buscar dados:', error);
        setLoading(false);
      });
  }, [reload]);

  const handleSearch = async (event, value) => {
    setSearchValue(value);
    if(value !== null) {
      setFormData({
        ...formData,
        id_cliente: value.id
      });
    }else {
      setFormData({
        ...formData,
        id_cliente: ''
      });
    }
    // const searchResults = await Api.getSearchClient(value);
    // console.log(searchResults)
    // setOptions(searchResults);
  };

  useEffect(() => {
    
  }, [searchValue]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const openModalService = () => {
    setUpdateModalOpenService(true);
  };

  const openModalServicePrestador = () => {
    setUpdateModalOpenServicePrestador(true);
  };

  const handleInputChangeForm = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  
    let dataHora 

    if(selectedDate !== null && selectedTime !== null) {
      let dataFormatada = format(selectedDate, 'yyyy-MM-dd');
      let horaFormatada = format(selectedTime, 'HH:mm:ss');

      dataHora = `${dataFormatada} ${horaFormatada}`;
    }
    const hasHighComplexity = service.some(item => item.infoServico.complexidade  === 'Alta');
    let status = null;
    let isAgendado = null;
    if (hasHighComplexity) {
      status = 2;
      isAgendado = 1;
    }else {
      status = 3;
      isAgendado = 0;
    }

    Api.updateCreateEncaminhamento(idEncaminhamento, {...encaminhamento, adicionais: formData.adicionais, tipo_pagamento: formData.tipo_pagamento, status_encaminhamento: status, is_agendado: isAgendado})
      .then((res) => {
        if(res.error) {
          toast.error(res.error)
        }else {
          toast.success("Encaminhamento enviado com sucesso!");

          if(formData.tipo_pagamento === "PAGO") {
            navigate(`/Encaminhamento/Enviar/${idEncaminhamento}`)
          }
          setFirstPart(false);
          setDisplayService(false);
          setDisplayServicePrestador(false);
          setService([])
          setSelectedDate(null);
          setSelectedTime(null);
          setFormData({
            id_unidade_recebe: '',
            id_partner: '',
            id_cliente: '',
            tipo_pagamento: '',
            is_agendado: '',
            adicionais: ''
          });
          setSearchValue('')
          handleClose();
          reloadComponent();
        }
      })
      .catch((e) => {
        toast.error(`Erro: ${e.request.status}, Não foi possível, tente novamente mais tarde!`);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleContinue = () => {
  
    if (!formData.id_cliente || !formData.id_unidade_recebe) {
      toast.error("Por favor, preencha todos os campos obrigatórios.");
      return;
    }
  
    setLoadingContinue(true);
    setFirstPart(true);
    
    Api.createEncaminhamento({id_cliente: formData.id_cliente, id_encaminhador: user?.id, id_unidade_recebe: formData.id_unidade_recebe})
      .then((res) => {
        if(res.error) {
          toast.error(res.error)
        }else {
          setIdEncaminhamento(res.encaminhamento.id)
          setEncaminhamento({...res.encaminhamento})
        }
        setLoadingContinue(false);
        setDisplayService(true);
        setDisplayServicePrestador(true)
      })
      .catch((e) => {
        toast.error(`Erro: ${e.request.status}, Não foi possível, tente novamente mais tarde!`);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSelectChangeClient = (e) => {
    const { value } = e.target;
    setFormData({
      ...formData,
      id_cliente: value
    });
  };

  const handleSelectChangeUnitRemetente = (e) => {
    const { value } = e.target;
    setFormData({
      ...formData,
      id_partner: value
    });
  };

  const handleSelectChangePagamento = (e) => {
    const { value } = e.target;
    setFormData({
      ...formData,
      tipo_pagamento: value
    });
  };

  const handleSelectChangeAgendamento = (e) => {
    const { value } = e.target;
    setFormData({
      ...formData,
      is_agendado: value
    });
  };

  const handleSelectChangeUnitRecebe = (e) => {
    const { value } = e.target;
    setFormData({
      ...formData,
      id_unidade_recebe: value
    });
  };

  const reloadComponentService = () => {
    Api.getServicosByEncaminhamento(idEncaminhamento)
    .then((res) => {
      if(res.error) {
        toast.error(res.error)
      }else {
        setService(res.servicos)
      }
    })
    .catch((e) => {
      toast.error(`Erro: ${e.request.status}, Não foi possível, tente novamente mais tarde!`);
    })
    .finally(() => {
      setLoading(false);
    });
  }

  const reloadComponentServicePrestador = () => {
    
    Api.getServicosPrestadorByEncaminhamento(idEncaminhamento)
    .then((res) => {
      if(res.error) {
        toast.error(res.error)
      }else {
        setServicePrestador(res)
        
      }
    })
    .catch((e) => {
      toast.error(`Erro: Não foi possível, tente novamente mais tarde!`);
    })
    .finally(() => {
      setLoading(false);
    });
  }

  const handleRemoveService = (idService) => {
    Api.deleteServicosByEncaminhamento(idEncaminhamento, idService)
    .then((res) => {
      if(res.error) {
        toast.error(res.error)
      }else {
        toast.success("serviço removido")
        reloadComponentService();
      }
    })
    .catch((e) => {
      toast.error(`Erro: ${e.request.status}, Não foi possível, tente novamente mais tarde!`);
    })
    .finally(() => {
      setLoading(false);
    });
  }

  const handleRemoveServicePrestador = (idService) => {
    Api.deleteServicosByEncaminhamento(idEncaminhamento, idService)
    .then((res) => {
      if(res.error) {
        toast.error(res.error)
      }else {
        toast.success("serviço removido")
        reloadComponentService();
      }
    })
    .catch((e) => {
      toast.error(`Erro: ${e.request.status}, Não foi possível, tente novamente mais tarde!`);
    })
    .finally(() => {
      setLoading(false);
    });
  }

  const formatarCPF = (str) => {
    // Remove todos os caracteres não numéricos
    const digitsOnly = str.replace(/\D/g, '');

    // Verifica se o CPF tem a quantidade correta de dígitos
    if (digitsOnly.length !== 11) {
        throw new Error('CPF deve conter 11 dígitos.');
    }

    // Formata o CPF no formato com pontos e traço
    return `${digitsOnly.slice(0, 3)}.${digitsOnly.slice(3, 6)}.${digitsOnly.slice(6, 9)}-${digitsOnly.slice(9)}`;
}

  return (
    <React.Fragment>
      <Button className='button-open' id='buttonOpen' onClick={handleClickOpen}>
        <AddIcon className="icon-add" />
      </Button>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar className='bar-modal-dialog'>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1, color: '#ffffff' }} variant="h6" component="div">
              Novo Encaminhamento
            </Typography>
          </Toolbar>
        </AppBar>

        <DialogContent className="container-dialog">
          <div className="containerForm pt-0 pb-5">
            <form onSubmit={handleSubmit} className="pb-3">
             
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={arrayDataClient}
              value={searchValue}
              onChange={handleSearch}
              disabled={firstPart}
              getOptionLabel={(option) => (option.nome && option.cpf) ? `${option.nome} - ${formatarCPF(option.cpf)}` : ''}
              renderInput={(params) => <TextField {...params} label="Cliente" />}
              noOptionsText="Cliente não encontrado, realize o cadastro primeiro!"
            />
            {
              searchValue ? null : 
              
              <FormCreatedEncaminhador
              reloadComponent={handleReload}
              ></FormCreatedEncaminhador>
            }
            


            {
              formData.id_cliente !== '' 
              ? 
                <Form.Group className="mt-3 mb-3 d-flex flex-column">
                  <Form.Label htmlFor="selectParceiro">Para qual parceiro será encaminhado?*</Form.Label>
                  <Form.Select 
                    id="selectParceiro" 
                    name="id_parceiro" 
                    value={formData.id_partner} 
                    onChange={handleSelectChangeUnitRemetente}
                    disabled={firstPart}
                    className="form-control-value form-control-value-select"
                  >
                    <option value="">Selecione uma Unidade</option>
                    {partnerData.map(data => {
                      return data.is_ativo === "0" || data.is_ativo === 0 ? (
                        <option key={data.id} value={data.id}>{data.nome} - {data?.telefone}</option>
                      ) : null;
                    })}
                  </Form.Select>
                </Form.Group> 
              : 
                null
            } 

            {
              formData.id_partner !== '' 
              ? 
                <Form.Group className="mb-3 d-flex flex-column">
                  <Form.Label htmlFor="selectParceiro">Informe a unidade*</Form.Label>
                  <Form.Select 
                    id="selectParceiro" 
                    name="id_parceiro" 
                    value={formData.id_unidade_recebe} 
                    onChange={handleSelectChangeUnitRecebe}
                    disabled={firstPart}
                    className="form-control-value form-control-value-select"
                  >
                    <option value="">Selecione uma Unidade</option>
                    {unitsData.map(data => {
                      return data.is_ativo !== "0" ? (
                        <option key={data.id} value={data.id}>{data.nome}</option>
                      ) : null;
                    })}
                  </Form.Select>
                </Form.Group> 
              : 
                null
            } 

            {
              (formData.id_cliente !== '' && formData.id_unidade_envia !== '' && formData.id_unidade_recebe !== '') && displayService === false
              ? 
                <Button onClick={handleContinue} disabled={loadingContinue || firstPart} disableElevation variant="outlined" color="success">
                  {loadingContinue ? 'Aguarde...' : 'Continuar'}
                </Button>
              : 
                null
            } 

            {
              displayService 
              ? 
                <>
                  <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                      <Typography variant='body1'>Selecione pelo menos um procedimento para continuar</Typography>
                  </Box>
                  <Typography variant="h6" color="textSecondary" component="p" className="mt-3 d-flex align-items-center">
                    Escolha o(s) procedimento(s) 
                    <Button className='button-service ml-2' id='buttonService' onClick={openModalService}>
                      <AddIcon className="icon-add-service" />
                    </Button>
                  </Typography>

                  {
                    service.length > 0 
                      ? 
                      service.map(data => (
                        <Card className="partner-card mt-3" key={data.id}>
                          <CardContent>
                            <Typography variant="body1" color="text.secondary" className="mb-1">
                              <strong>{data?.infoServico?.nome}</strong> 
                            </Typography>
                            <Typography variant="body1" color="text.secondary">
                              {data?.infoServico?.infoCategoria?.nome}
                            </Typography>
                            <Typography variant="body1" color="text.secondary">
                              R$ {Number(data?.infoServico?.valor).toFixed(2).replace('.', ',')}
                            </Typography>
                          </CardContent>
                          <CardActions className="card-cartion-buttons">
                            <Button
                              size="small"
                              color="error"
                              className="button-card-action"
                              onClick={() => handleRemoveService(data.id)}
                            >
                              Remover
                            </Button>
                          </CardActions>
                        </Card>
                      ))
                      : 
                      <WarningComponent title="Nenhuma procedimento adicionado."/>
                  }
                
                </>
              : 
                null
            }

            {
              displayServicePrestador 
              ? 
                <>
                  <Typography variant="h6" color="textSecondary" component="p" className="mt-3 d-flex align-items-center">
                    Escolha o(s) serviço(s)  
                    <Button className='button-service ml-2' id='buttonService' onClick={openModalServicePrestador}>
                      <AddIcon className="icon-add-service" />
                    </Button>
                  </Typography>

                  {
                    servicePrestador.length > 0 
                      ? 
                      servicePrestador.map(data => (
                        <Card className="partner-card mt-3" key={data.id}>
                          <CardContent>
                            <Typography variant="body1" color="text.secondary" className="mb-1">
                              <strong>{data?.infoServicoPrestador?.infoPrestador?.nome}</strong>
                            </Typography>
                            <Typography variant="body1" color="text.secondary">
                              {data?.infoServicoPrestador?.nome}
                            </Typography>
                            <Typography variant="body1" color="text.secondary">
                              R$ {Number(data?.infoServicoPrestador?.valor).toFixed(2).replace('.', ',')}
                            </Typography>
                          </CardContent>
                          {/* <CardActions className="card-cartion-buttons">
                            <Button
                              size="small"
                              color="primary"
                              className="button-card-action"
                              onClick={() => handleRemoveService(data.id)}
                            >
                              Remover
                            </Button>
                          </CardActions> */}
                        </Card>
                      )) 
                      : 
                      <WarningComponent title="Nenhuma serviço adicionado."/>
                  }
                
                </>
              : 
                null
            }

            {
              service.length > 0 
              ?
                <>
                  <Typography variant="h6" color="textSecondary" component="p" className="mt-3 d-flex align-items-center">
                    Pagamento
                  </Typography>
                  
                  <Form.Group className="mb-3 d-flex flex-column">
                    <Form.Label htmlFor="selectParceiro">Selecione um tipo de pagamento*</Form.Label>
                    <Form.Select 
                      id="selectParceiro" 
                      name="id_parceiro" 
                      value={formData.tipo_pagamento} 
                      onChange={handleSelectChangePagamento}
                      className="form-control-value form-control-value-select"
                    >
                      <option value="">Selecione um tipo</option>
                      {/* <option value="PAGO">Pago</option> */}
                      <option value="A PAGAR CLIENTE">A Pagar (Cliente)</option>
                      <option value="A PAGAR PARCEIRO">A Pagar (Parceiro)</option>
                    </Form.Select>
                  </Form.Group> 

                  {
                    formData.tipo_pagamento ? 
                      <Typography variant="h6" color="textSecondary" component="p" className="mt-3 d-flex align-items-center">
                        Obs:
                      </Typography>
                    
                    : null
                  }

                  {
                    formData.tipo_pagamento === 'A PAGAR CLIENTE' 
                      ?
                        <>
                          <Typography variant="body1" color="text.secondary">
                            O Paciente será o responsável pelo pagamento diretamente ao parceiro que vai realizar o procedimento                          
                          </Typography> 
                          {/* <Typography variant="body1" color="text.secondary">
                            Valor: R$ 0,00
                          </Typography>  */}
                        </>
                      :
                      null
                  }

              {
                    formData.tipo_pagamento === 'A PAGAR PARCEIRO' 
                      ?
                        <>
                          <Typography variant="body1" color="text.secondary">
                            O Encaminhador fica responsavel pelo pagamento, utilizando seu "Crédito Virtual".
                          </Typography> 
                          {/* <Typography variant="body1" color="text.secondary">
                            Valor: R$ 0,00
                          </Typography>  */}
                        </>
                      :
                      null
                  }
                </>
              : 

                null
 
              }

            {

              formData.tipo_pagamento ?
                <>
                  <Form.Group className=" mt-3 mb-3">
                    <Form.Label htmlFor="inputAdicionais">Informação adicional:</Form.Label>
                    <Form.Control 
                        as="textarea" 
                        name="adicionais" 
                        id="inputAdicionais"
                        value={formData.adicionais} 
                        onChange={handleInputChangeForm} 
                        className="form-control-value"
                    />
                  </Form.Group>
                </>
              : null
            }
              <div className="mt-5"></div>

              {
                formData.tipo_pagamento
                ? 
                  <Button type="submit" className="button-submit-form-save mb-2" disabled={loading} disableElevation>
                    {loading ? 'Salvado...' : 'Salvar'}
                  </Button> 
                : null
               }
              
            </form>
          </div>

          <FormNewService
            isOpen={updateModalOpenService}
            onClose={() => setUpdateModalOpenService(false)}
            id={idEncaminhamento}
            idUnidade={formData.id_unidade_recebe}
            reloadComponent={reloadComponentService}
          />

          <FormNewServicePrestador
            isOpen={updateModalOpenServicePrestador}
            onClose={() => setUpdateModalOpenServicePrestador(false)}
            id={idEncaminhamento}
            idUnidade={formData.id_unidade_recebe}
            reloadComponent={reloadComponentServicePrestador}
          ></FormNewServicePrestador>
        </DialogContent>

      </Dialog>
    </React.Fragment>
  );
}

export default FormCreatedUserProps;
