import React, { useState } from "react";
import { Card, CardContent, Typography, Button, styled, Box, useTheme } from "@mui/material";
import { Container } from "react-bootstrap";
// import "./Partners.css";
import CarConnected from 'mdi-material-ui/CarConnected'
import FoodCroissant from 'mdi-material-ui/FoodCroissant'
import Avatar from '@mui/material/Avatar'
import Grid from '@mui/material/Grid'
import FormCreatedUser from "../../../components/forms/UsersProviders/form-created";
import { Link } from 'react-router-dom';

function ServiceProciderComponent() {
  const [reload, setReload] = useState(false);
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const [info, setInfo] = useState({idUnidade: 0, tipo: 0, title: ''});
  const theme = useTheme();

  const handleReload = () => {
    setReload(!reload);
  };

  const openModalOne = () => {
    setUpdateModalOpen(true);
    setInfo({idUnidade: 1, tipo: 4, title: 'Cadastrar Prestador de Serviço Alimentício'});
  };

  const openModalTwo = () => {
    setUpdateModalOpen(true);
    setInfo({idUnidade: 1, tipo: 5, title: 'Cadastrar Prestador de Serviço de Transporte'});
  };

  return (
    <>
      <Container className="p-0 mb-6">
        <div className="row container-units mb-4">
          <div className="col-12">
            <span>Home</span>
          </div>
          <div className="col-12">
            <div className="row">
              <div className="col-md-6">
                <h1>Prestador de serviço</h1>
              </div>
            </div>
          </div>
        </div>

        <Card>
          <CardContent
            sx={{
              display: 'flex',
              textAlign: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              padding: theme => `${theme.spacing(2.75, 5, 2.25)} !important`
            }}
          >
            <Avatar
              sx={{ width: 50, height: 50, marginBottom: 2.25, color: 'common.white', backgroundColor: 'primary.main' }}
            >
              <FoodCroissant sx={{ fontSize: '2rem' }} />
            </Avatar>
            <Typography variant='h6' sx={{ marginBottom: 2.75 }}>
              Prestador de Serviço Alimentício
            </Typography>
            <Typography variant='body2' sx={{ marginBottom: 6 }}>
              Um prestador de serviços alimentícios é alguém ou uma empresa que oferece serviços relacionados à comida, como preparação de refeições, catering e entrega de alimentos. Eles atendem a uma variedade de clientes, fornecendo uma variedade de opções alimentares.
            </Typography>
            <Grid container className="d-flex justify-content-center">
              <Grid item xs={12} sm={6} className={theme.breakpoints.down('xs') ? "mb-3" : ""}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <Button onClick={() => openModalOne()} variant='contained' sx={{ padding: theme => theme.spacing(1.75, 1.8), fontWeight: 600 }}>
                    Cadastrar Prestador de Serviço
                  </Button>
                </Box>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <Button 
                    component={Link}
                    to="/PrestadorServico/Alimenticio"
                    variant='contained' 
                    sx={{ 
                      padding: theme => theme.spacing(1.75, 1.8), 
                      textDecoration: 'none', 
                      color: '#ffffff', 
                      fontWeight: 600,
                      '&:hover': {
                        color: '#ffffff',
                      },
                    }}
                  >
                    Visualizar todos os Prestadores
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <Card className="mt-3">
          <CardContent
            sx={{
              display: 'flex',
              textAlign: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              padding: theme => `${theme.spacing(2.75, 5, 2.25)} !important`
            }}
          >
            <Avatar
              sx={{ width: 50, height: 50, marginBottom: 2.25, color: 'common.white', backgroundColor: 'primary.main' }}
            >
              <CarConnected sx={{ fontSize: '2rem' }} />
            </Avatar>
            <Typography variant='h6' sx={{ marginBottom: 2.75 }}>
              Prestador de Serviço de Transporte
            </Typography>
            <Typography variant='body2' sx={{ marginBottom: 6 }}>
              Um prestador de serviço de transporte oferece serviços de deslocamento de pessoas ou mercadorias, como táxis, transporte por aplicativo e transporte escolar. Eles garantem a segurança e eficiência no transporte, adaptando-se às necessidades individuais de cada cliente.            </Typography>
            <Grid container className="d-flex justify-content-center">
              <Grid item xs={12} sm={6} className={theme.breakpoints.down('xs') ? "mb-3" : ""}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <Button onClick={() => openModalTwo()} variant='contained' sx={{ padding: theme => theme.spacing(1.75, 1.8), fontWeight: 600 }}>
                    Cadastrar Prestador de Serviço
                  </Button>
                </Box>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <Button component={Link}
                    to="/PrestadorServico/Transport"
                    variant='contained' 
                    sx={{ 
                      padding: theme => theme.spacing(1.75, 1.8), 
                      textDecoration: 'none', 
                      color: '#ffffff', 
                      fontWeight: 600,
                      '&:hover': {
                        color: '#ffffff',
                      },
                    }}
                  >
                    Visualizar todos os Prestadores
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Container>

      <FormCreatedUser 
          reloadComponent={handleReload}
          idUnidade={info.idUnidade}
          tipo={info.tipo}
          isOpen={updateModalOpen}
          onClose={() => setUpdateModalOpen(false)}
          title="Cadastrar Prestador de Serviço"
      />

      {/* <FormCreatedPartners
        onPartnerCreated={handleReload}
        initialPartner={selectedPartner}
      />

      <FormUpdatePartners 
        onPartnerCreated={handleReload}
        partnerToUpdate={selectedPartner}
        id={selectedPartner?.id}
        isOpen={updateModalOpen}
        onClose={() => setUpdateModalOpen(false)}
      /> */}
    </>
  );
}

export default ServiceProciderComponent;
