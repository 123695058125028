import { useState } from "react";
import { Token } from "./model/useToken"
 
export default function useToken() {
  const getToken = (): string | null => {
    const tokenString = localStorage.getItem("token");
    const userToken: Token = JSON.parse(tokenString || '{}');
    return userToken?.token;
  };

  const [token, setToken] = useState<string | null>(getToken());

  const saveToken = (userToken: Token) => {
    localStorage.setItem("token", JSON.stringify(userToken));
    setToken(userToken.token);
  };

  return {
    setToken: saveToken,
    token,
  };
}
