import React, { useState, useEffect, ChangeEvent, FormEvent } from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import DialogContent from '@mui/material/DialogContent';
import Form from 'react-bootstrap/Form';
import InputMask from 'react-input-mask';
import { toast } from 'react-toastify';

import './form-created-user.css'
import { Encaminhador, User } from "../../../model/partner";
import Api from "../../../service/apiBase";

const Transition = React.forwardRef(function Transition(
  props: React.ComponentProps<typeof Slide>,
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface FormCreatedProps {
  reloadComponent: () => void;
  toUpdate: Encaminhador | null;
  isOpen: boolean;
  onClose: () => void;
  id?: string;
}

const FormUpdateEncaminhador: React.FC<FormCreatedProps> = ({ reloadComponent, toUpdate, isOpen, onClose, id }) => {
  const [formData, setFormData] = useState<any>({
    nome: '',
    email: '',
    cpf: '',
    info_credito: ''
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (toUpdate) {
        setFormData(toUpdate);
    }
  }, [toUpdate]);

  const handleInputChangeForm = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({
        ...formData,
        [name]: value
    });
  };

  const removeFormatarCPF = (str: string) => {
    const digitsOnly = str.replace(/\D/g, '');

    return digitsOnly;
  }

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!formData.nome || !formData.email || !formData.cpf || !formData.info_credito) {
        toast.error("Por favor, preencha todos os campos obrigatórios.");
        return;
    }

    // if (formData.password !== formData.password_confirm) {
    //     toast.error("As senhas não coincidem.");
    //     return;
    //   }
    
      setLoading(true);
      
      Api.updateUser(id, {cpf: removeFormatarCPF(formData.cpf), email: formData.email, nome: formData.nome, valor_credito: formData.info_credito})
        .then((res) => {
          if(res.error) {
            toast.error(res.error)
          }else {
            toast.success("cadastrado realizado com sucesso");
            setFormData({
              nome: '',
              email: '',
              cpf: '',
              info_credito: '',
            });
            onClose();
            reloadComponent();
          }
        })
        .catch((e) => {
          toast.error(`Erro: ${e.request.status}, Não foi possível, tente novamente mais tarde!`);
        })
        .finally(() => {
          setLoading(false);
        });
  };

  return (
    <Dialog
      fullScreen
      open={isOpen}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar className='bar-modal-dialog'>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1, color: '#ffffff' }} variant="h6" component="div">
            Atualizar Encaminhador
          </Typography>
        </Toolbar>
      </AppBar>

      <DialogContent className="container-dialog">
        <div className="containerForm">
          <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
                <Form.Label htmlFor="inputNome">Nome*</Form.Label>
                <Form.Control 
                  type="text" 
                  placeholder="Digite o nome" 
                  name="nome" 
                  id="inputNome"
                  value={formData.nome} 
                  onChange={handleInputChangeForm} 
                  className="form-control-value"
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label htmlFor="inputEndereco">Email*</Form.Label>
                <Form.Control 
                  type="email" 
                  placeholder="Digite o email" 
                  name="email" 
                  id="inputEmail"
                  value={formData.email} 
                  onChange={handleInputChangeForm} 
                  className="form-control-value"
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label htmlFor="inputCpf">CPF*</Form.Label>
                <Form.Control
                  type="text"
                  as={InputMask}
                  mask="999.999.999-99"
                  id="inputCpf"
                  name="cpf"
                  value={formData.cpf}
                  onChange={handleInputChangeForm}
                  className="form-control-value"
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label htmlFor="info_credito">Valor Total de Crédito</Form.Label>
                <Form.Control
                  type="text"
                  id="info_credito"
                  name="info_credito"
                  value={formData.info_credito}
                  onChange={handleInputChangeForm}
                  className="form-control-value"
                />
              </Form.Group>

              {/* <Form.Group className="mb-3">
                <Form.Label htmlFor="inputPassword">Senha*</Form.Label>
                <Form.Control 
                  type="password" 
                  placeholder="Digite a senha" 
                  name="password" 
                  id="inputPassword"
                  value={formData.password} 
                  onChange={handleInputChangeForm} 
                  className="form-control-value"
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label htmlFor="inputPasswordConfirm">Confirmar Senha*</Form.Label>
                <Form.Control 
                  type="password" 
                  placeholder="Confirme a senha" 
                  name="password_confirm" 
                  id="inputPasswordConfirm"
                  value={formData.password_confirm} 
                  onChange={handleInputChangeForm} 
                  className="form-control-value"
                />
              </Form.Group> */}

            <Button type="submit" className="button-submit-form" disabled={loading} disableElevation>
              {loading ? (toUpdate ? 'Atualizando...' : 'Cadastrando...') : (toUpdate ? 'Atualizar' : 'Cadastrar')}
            </Button>
          </Form>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default FormUpdateEncaminhador;



