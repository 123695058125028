import React, { useState, useEffect, FormEvent, ChangeEvent } from "react";
import { Card, CardContent, Typography, CardActions, Tooltip, Button, Grid, styled } from "@mui/material";
import { toast } from 'react-toastify';
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import WarningComponent from "../../../components/warnig/warnig"
import LoadingComponent from "../../../components/loadingCircular/loadingComponent";
import Api from "../../../service/apiBase";
import { Container } from "react-bootstrap";
import { Client } from "../../../model/partner";
import FormCreatedForwarding from "../../../components/forms/Forwarding/form-created";
import FormUpdateClient from "../../../components/forms/Client/form-update";
import useUser from "../../../useUser";
import StripeModal from "../../../components/stripe/stripe-modal";
import PixComponent from "../../../components/pix/pix-component";

const TriangleImg = styled('img')({
  right: 0,
  bottom: 0,
  height: 170,
  position: 'absolute'
})


function ForwardingFinalizadosComponent() {
    const [arrayData, setArrayData] = useState<any[]>([]);
    const [loading, setLoading] = useState(true);
    const [selectedValues, setSelectedValues] = useState<any | null>(null);
    const [updateModalOpen, setUpdateModalOpen] = useState(false);
    const [updateModalOpenPix, setUpdateModalOpenPix] = useState(false);
    const [taxId, setTaxId] = useState('');
    const [reload, setReload] = useState(false);
    const { user } = useUser();
  
    const handleReload = () => {
      setReload(!reload);
    };
  
    const handleUpdate = (values: any) => {
        setSelectedValues(values);
        setUpdateModalOpen(true);
    };

    const openModalPix = (taxId: string) => {
      setUpdateModalOpenPix(true);
      setTaxId(taxId)
    };
  
    useEffect(() => {
        // if(user?.tipo === '2' ) {

        // }
        
        Api.getAllEncaminhamentosFinalizados(user?.id)
        .then(data => {
            
          if(data.length === undefined ) {
            setArrayData([data])
          }else {
            setArrayData(data)
          }
          
          setLoading(false);
        })
        .catch(error => {
          console.error('Erro ao buscar:', error);
        });
    }, [reload]);

    const handleRemoveEncaminhamento = (data: any) => {
      setLoading(true);
      Api.deleteEncaminhamento(data.id)
        .then((response) => {
          if (response.message === 'Encaminhamento removido com sucesso') {
            toast.success(`Encaminhamento removido com sucesso`);
            handleReload();
            setLoading(false);

          } else {
            toast.error(`Não foi possível, tente novamente mais tarde!`);
            setLoading(false);
          }
        })
        .catch((e) => {
          toast.error(`Erro: ${e.request.status}, Não foi possível, tente novamente mais tarde!`);
          setLoading(false);
        });
    };

    const handleReloadStripe = () => {
      // setReload(!reload);
    };
    
    const statusObjLabel = [
      { label: 'Encaminhamento Aberto' },
      { label: 'Aguardando Agendamento' },
      { label: 'Encaminhamento Agendado' },
      { label: 'Encaminhado Confirmado' },
      { label: 'Encaminhamento Finalizado' },
    ]
  
    return (
      <>
        <Container className="p-0 mb-6">
            <div className="row container-units mb-4">
              <div className="col-12">
                <span>Home</span>
              </div>
              <div className="col-12">
                <div className="row">
                  <div className="col-md-12">
                    <h1>Encaminhamento Concluídos</h1>
                  </div>
                </div>
              </div>
            </div> 
            {
              loading ?
                <LoadingComponent/>
              :
                arrayData.length > 0 ? (
                  arrayData.map(data => (
                    <Card className="partner-card mb-3" key={data.id}>
                      <CardContent>
                          <Typography variant="h6" component="div" className="mb-2 d-flex justify-content-end align-items-center">
                            Código: {data.id}
                          </Typography>
                        <Grid container spacing={2}>
                          <Grid item sm={6} xs={12}>
                            <Typography variant="h6" component="div" className="mb-2">
                              Detalhes do Encaminhamento:
                            </Typography>
                            <Typography variant="body1" color="text.secondary">
                              <strong>Status:</strong> {statusObjLabel[data.status_encaminhamento-1].label}
                            </Typography> 
                            <Typography variant="body1" color="text.secondary">
                              <strong>Unidade:</strong> {data?.infoUnidade_recebe?.nome}
                            </Typography>
                            <Typography variant="body1" color="text.secondary">
                              <strong>Cliente:</strong> {data?.dados_cliente?.nome}
                            </Typography>
                          </Grid>
                          <Grid item sm={6} xs={12}>
                            <Typography variant="h6" component="div" className="mb-2">
                              Dados do Pagamento:
                            </Typography>
                            
                            {
                              data.tipo_pagamento === 'A PAGAR CLIENTE' ?
                              <Typography variant="body1" color="text.secondary">
                                <strong>Pagamento:</strong> Cobrar do cliente
                              </Typography> : data.tipo_pagamento === 'PAGO' ?
                               <Typography variant="body1" color="text.secondary">
                                <strong>Pagamento:</strong> Cobrar do Parceiro
                              </Typography> : <Typography variant="body1" color="text.secondary">
                                <strong>Pagamento:</strong> {data?.status_pagamento}
                              </Typography>
                            }
                            <Typography variant="body1" color="text.secondary">
                              <strong>Valor:</strong> R$ {data?.valorTotal.toFixed(2)}
                            </Typography>
                          </Grid>
                        </Grid>
                        
                        <TriangleImg alt='triangle background' src={`/images/misc/triangle-light.png`} />
                        
                      </CardContent>
                      <CardActions className="card-cartion-buttons">

                        {/* <Button
                          size="small"
                          color="primary"
                          className="button-card-action"
                          onClick={() => handleRemoveEncaminhamento(data)}
                        >
                          Remover
                        </Button> */}

                        <Button
                          size="small"
                          className="button-card-action-update"
                          component={Link}
                          to={`/Encaminhamento/Receber/${data.id}`}
                        >
                          Visualizar
                        </Button>
                      </CardActions>
                    </Card>
                  ))
                )
              : 
                (
                  <>
                    <WarningComponent title="Nenhum cadastro"/>
                  </>
                )
            }
            
        </Container>

        <PixComponent
          isOpen={updateModalOpenPix}
          onClose={() => setUpdateModalOpenPix(false)}
          taxId={taxId}
        />

        <FormUpdateClient
          reloadComponent={handleReload}
          toUpdate={selectedValues}
          id={selectedValues?.id}
          isOpen={updateModalOpen}
          onClose={() => setUpdateModalOpen(false)}
        />
      </>
    );
}

export default ForwardingFinalizadosComponent;

