import React, { useState, ChangeEvent, FormEvent } from "react";
import './Dashboard.css';

// ** MUI Imports
import Grid from '@mui/material/Grid'

// ** Icons Imports
import Poll from 'mdi-material-ui/Poll'
import CurrencyUsd from 'mdi-material-ui/CurrencyUsd'
import HelpCircleOutline from 'mdi-material-ui/HelpCircleOutline'
import BriefcaseVariantOutline from 'mdi-material-ui/BriefcaseVariantOutline'

import CardStatisticsVerticalComponent from "./CardStatisticsVerticalComponent";
import ApexChartWrapper from "./ApexChartWrapper";

import Trophy from "./Trophy";

import { Link } from "react-router-dom";
import WarningComponent from "../../../components/warnig/warnig";
import StatisticsCard from "./StatisticsCard";
import WeeklyOverview from "./WeeklyOverview";
import Table from "./Table";
import useUser from "../../../useUser";
import TrophyEncaminhador from "./TrophyEncaminhador";
import WeeklyOverviewEncaminhador from "./WeeklyOverviewEncaminhador";
import TableEncaminhador from "./TableEncaminhador";
import TableAtendente from "./TableAtendente";
import TableAlimento from "./TableAlimento";

function DashboardAdmin() {

    return (
        <Grid container spacing={6} sx={{ px: 2 }}>
            <Grid item xs={12} md={7}>
                <Trophy />
            </Grid>
            <Grid item xs={12} md={5}>
                <WeeklyOverview />
            </Grid>
            {/* <Grid item xs={12} md={12} lg={12}>
                <StatisticsCard />
            </Grid> */}
            <Grid item xs={12} md={12} lg={12}>
                <Table />
            </Grid>
            <Grid item xs={12} md={12} lg={12}></Grid>
        </Grid>
)
}

function DashboarEncaminhador() {

    return (
        <Grid container spacing={6} sx={{ px: 2 }}>
            <Grid item xs={12} md={7}>
                <TrophyEncaminhador />
            </Grid>
            <Grid item xs={12} md={5}>
                <WeeklyOverviewEncaminhador />
            </Grid>
            {/* <Grid item xs={12} md={12} lg={12}>
                <StatisticsCard />
            </Grid> */}
            <Grid item xs={12} md={12} lg={12}>
                <TableEncaminhador />
            </Grid>
            <Grid item xs={12} md={12} lg={12}></Grid>
        </Grid>
        )
}

function DashboardAtendente() {

    return (
        <Grid container spacing={6} sx={{ px: 2 }}>
            <Grid item xs={12} md={12} lg={12}>
                <TableAtendente/>
            </Grid>
        </Grid>
    )
}

function DashboardAlimento() {

    return (
        <Grid container spacing={6} sx={{ px: 2 }}>
            <Grid item xs={12} md={12} lg={12}>
                <TableAlimento/>
            </Grid>
        </Grid>
    )
}

function DashboardServico() {

    return (
        <Grid container spacing={6} sx={{ px: 2 }}>
            <Grid item xs={12} md={12} lg={12}>
                <TableAlimento/>
            </Grid>
        </Grid>
    )
}

function Dashboard() {

    const { user } = useUser();

    switch (user?.tipo) {

        case '1':
            return <DashboardAdmin />
        case '2':
            return (
                <div className="root">
                    <DashboarEncaminhador/>
                </div>
            )
        case '3':
            return (
                <div className="root">
                    <DashboardAtendente />
                </div>
            )
        case '4':
            return (
                <div className="root">
                    <DashboardAlimento />
                </div>
            )
        case '5':
            return (
                <div className="root">
                    <DashboardServico />
                </div>
            )
        default:
            return (
                <div className="root">
                    <WarningComponent title="Entre em contato com o suporte"/>
                </div>
            )
    }

    return (
    //    <div className="root">
    //     <WarningComponent title="Página em desenvolvimento..."/>
    //    </div>
    <ApexChartWrapper>
        <Grid container spacing={6}>
            
            <Grid item xs={12} md={8}>
                <WeeklyOverview />
            </Grid>
            <Grid item xs={12} md={4}>
                <Trophy />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
                <StatisticsCard />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
                <Table />
            </Grid>
            <Grid item xs={12} md={12} lg={12}></Grid>
        </Grid>
    </ApexChartWrapper>
    )
}

export default Dashboard;