import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

const BoxWrapper = styled(Box)(({ theme }) => ({
    [theme.breakpoints.down('md')]: {
      width: '90vw'
    }
}))

const Img = styled('img')(({ theme }) => ({
    marginBottom: theme.spacing(10),
    [theme.breakpoints.down('lg')]: {
      height: 450,
      marginTop: theme.spacing(10)
    },
    [theme.breakpoints.down('md')]: {
      height: 400
    },
    [theme.breakpoints.up('lg')]: {
      marginTop: theme.spacing(13)
    }
  }))

const Error404 = () => {
    return (
      <Box className='content-center'>
        <Box sx={{ p: 5, display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
          <BoxWrapper>
            <Typography variant='h1'>404</Typography>
            <Typography variant='h5' sx={{ mb: 1, fontSize: '1.5rem !important' }}>
            Página não encontrada ⚠️
            </Typography>
            <Typography variant='body2'>Não conseguimos encontrar a página que você está procurando</Typography>
          </BoxWrapper>
          <Img height='487' alt='error-illustration' src='/images/pages/404.svg' />
          {/* <Link passHref href='/'>
            <Button component='a' variant='contained' sx={{ px: 5.5 }}>
            Voltar para a página inicial
            </Button>
          </Link> */}
        </Box>
        {/* <FooterIllustrations image={<TreeIllustration alt='tree' src='/images/pages/tree.png' />} /> */}
      </Box>
    )
}

export default Error404